package com.mshopsas.enterprise.aiw.scan.screens.historic.detail

import cafe.adriel.voyager.core.model.screenModelScope
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.basket.HistoryOrder
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class HistoryOrderDetailScreenModel(
    private val repository: Repository
) : BaseScreenModel<HistoryOrderDetailContract.Event, HistoryOrderDetailContract.State, HistoryOrderDetailContract.Effect>() {

    override fun setInitialState() = HistoryOrderDetailContract.State(
        items = listOf(),
        isAnonymous = false
    )

    override fun handleEvents(event: HistoryOrderDetailContract.Event) {
        when (event) {
            is HistoryOrderDetailContract.Event.OnInit -> onInit(event.order)
            HistoryOrderDetailContract.Event.OnBack -> onBack()
        }
    }

    private fun onInit(order: HistoryOrder) {
        screenModelScope.launch {
            screenModelScope.launch {
                repository.getBasket().collect { basket ->
                    setState {
                        copy(
                            isAnonymous = basket.isAnonymous()
                        )
                    }
                }
            }
            repository.callGetHistoricDetail(
                basketId = order.id
            ).collect { result ->
                if (result.status == Resource.Status.SUCCESS) {
                    val items = result.data?.historic?.itemList
                    setState {
                        copy(
                            items = items ?: listOf()
                        )
                    }
                }
            }
        }
    }

    private fun onBack() {
        setEffect { HistoryOrderDetailContract.Effect.Navigation.ToBack }
    }

}
