package com.mshopsas.enterprise.aiw.scan.screens.commons

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.empty_star
import aiwscan.composeapp.generated.resources.fill_star
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import org.jetbrains.compose.resources.painterResource

@Composable
fun RatingBar() {
    var rating by remember { mutableStateOf(0) }
    val outlinedStar = painterResource(resource = Res.drawable.empty_star)
    val filledStar = painterResource(resource = Res.drawable.fill_star)
    Row (
        modifier = Modifier
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center,
    ) {
        repeat(5) { index ->
            Icon (
                //imageVector = if (index < rating) Icons.Filled.Star else Icons.Outlined.Star,
                painter = if (index < rating) filledStar else outlinedStar,
                contentDescription = null,
                tint = RedDark,
                modifier = Modifier
                    .size(50.dp)
                    .clickable {
                        rating = index + 1
                    }
                    .padding(4.dp)
            )
        }
    }
}