package com.mshopsas.enterprise.aiw.scan.screens.home.composables

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.promo_product_in_promo
import aiwscan.composeapp.generated.resources.promo_rem_01
import aiwscan.composeapp.generated.resources.promo_rem_01_short
import aiwscan.composeapp.generated.resources.promo_rem_10
import aiwscan.composeapp.generated.resources.promo_rem_10_short
import aiwscan.composeapp.generated.resources.promo_rem_20
import aiwscan.composeapp.generated.resources.promo_rem_ra
import aiwscan.composeapp.generated.resources.promo_tel_04
import aiwscan.composeapp.generated.resources.promo_tel_05
import aiwscan.composeapp.generated.resources.promo_tel_09
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.LocalOffer
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetailsPromo
import org.jetbrains.compose.resources.stringResource

@Composable
fun PromoInfo(detailPromo: ProductDetailsPromo?) {

    val tempPromo: String? = when (detailPromo?.type) {
        "TEL" -> {
            when (detailPromo.codeAvg) {
                "04" -> stringResource(Res.string.promo_tel_04, detailPromo.amount)

                "05" -> stringResource(Res.string.promo_tel_05, detailPromo.amount)

                "09" -> stringResource(
                    Res.string.promo_tel_09,
                    detailPromo.frequency,
                    detailPromo.amount
                )

                else -> null
            }
        }

        "REM" -> {
            when (detailPromo.codeAvg) {
                "01" -> {
                    if (detailPromo.amount != "0") {
                        stringResource(
                            Res.string.promo_rem_01,
                            detailPromo.frequency,
                            detailPromo.amount
                        )
                    } else {
                        stringResource(Res.string.promo_rem_01_short, detailPromo.frequency)
                    }
                }

                "10" -> {
                    if (detailPromo.amount != "0") {
                        stringResource(
                            Res.string.promo_rem_10,
                            detailPromo.frequency,
                            detailPromo.amount
                        )
                    } else {
                        stringResource(Res.string.promo_rem_10_short, detailPromo.frequency)
                    }
                }

                "18", "20" -> stringResource(
                    Res.string.promo_rem_20,
                    detailPromo.amount2 ?: "?",
                    detailPromo.amount3 ?: "?"
                )

                "RA", "RP" -> stringResource(Res.string.promo_rem_ra, detailPromo.frequency)
                else -> null
            }
        }

        else -> null
    }

    Row(horizontalArrangement = Arrangement.spacedBy(8.dp)) {
        Image(Icons.Default.LocalOffer, null)
        Text(tempPromo ?: stringResource(Res.string.promo_product_in_promo), fontSize = 16.sp)
    }

}
