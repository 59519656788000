package com.mshopsas.enterprise.aiw.scan

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import cafe.adriel.voyager.navigator.Navigator
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.intro.IntroScreen
import com.mshopsas.enterprise.aiw.scan.utils.generateImageLoader
import com.seiko.imageloader.LocalImageLoader

@Composable
fun App() {
    MaterialTheme {

        CompositionLocalProvider(
            LocalImageLoader provides remember { generateImageLoader() },
        ) {
            Surface(
                modifier = Modifier.fillMaxSize(),
                color = Color.White
            ) {
                Navigator(IntroScreen)
            }
        }
    }
}
