package com.mshopsas.enterprise.aiw.scan.data.basket

import kotlinx.serialization.Serializable

@Serializable
data class CompareItem(
    val eanCode: String,
    val productLabel1: String,
    val productLabel2: String?,
    val image: String?,
    val aisle: String?,
    val aisleOrder: String?,
    val quantity: Int
)
