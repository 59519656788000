package com.mshopsas.enterprise.aiw.scan.data.basket

import com.mshopsas.enterprise.aiw.scan.data.basket.ticket.Discount
import com.mshopsas.enterprise.aiw.scan.data.basket.ticket.TicketItem
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.toMoney
import kotlinx.serialization.Serializable

@Serializable
data class Basket(
    val idStore: Long,
    val idBasket: Long,
    val idBasketEncoded: String,
    val amountTotal: Int,
    val cellList: List<Cell>,
    val nbItems: Int,
    val shopperName: String
) {
    fun isAnonymous() = shopperName.startsWith("AN.")

    fun generateTicketItems(): List<TicketItem> {
        val tickets = mutableListOf<TicketItem>()
        cellList.forEach { cell ->
            val last: Int? = if (cell.typePromo?.startsWith("REM") == true)
                cell.itemList.lastIndex else null
            if (last == null) {
                cell.itemList.forEach { item ->
                    tickets.add(
                        TicketItem(
                            item.productLabel ?: "",
                            item.unitScannedPrice?.toMoney() ?: "",
                            item.quantity,
                            item.totalScannedPrice?.toMoney() ?: "",
                            null
                        )
                    )
                }
            } else {
                cell.itemList.forEachIndexed { index, item ->
                    val discount = if (index == last) {
                        Discount(
                            cell.amountDesc ?: "",
                            "-${cell.discount.toMoney()}"
                        )
                    } else null
                    tickets.add(
                        TicketItem(
                            item.productLabel ?: "",
                            item.unitScannedPrice?.toMoney() ?: "",
                            item.quantity,
                            item.totalScannedPrice?.toMoney() ?: "",
                            discount
                        )
                    )
                }
            }
        }
        return tickets
    }

    companion object {
        const val STARTED = "STARTED"
        const val WAITING_TO_PAY = "WAITING_TO_PAY"
        const val WAITING_ASSISTANCE = "WAITING_ASSISTANCE"
        const val AUDIT_REQUIRED = "AUDIT_REQUIRED"
        const val PAID_AT_POS = "PAID_AT_POS"
        const val UNFOUND = "UNFOUND"
        const val CANCELED_STORE = "CANCELED_STORE"
        const val EXPIRED = "EXPIRED"
    }

}
