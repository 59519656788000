package com.mshopsas.enterprise.aiw.scan.screens.search

import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class SearchProductContract {
    sealed interface Event : ViewEvent {
        data object OnBack : Event
        data class OnSearch(val text: String) : Event
        data class OnClick(val product: ProductDetail) : Event
    }

    data class State(
        val isLoading: Boolean,
        val products: List<ProductDetail>,
        val isAnonymous: Boolean
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
            data class ToProductDetail(val product: ProductDetail) : Navigation
        }
    }
}