package com.mshopsas.enterprise.aiw.scan.data.basket

import kotlinx.serialization.Serializable

@Serializable
data class PromoInfo(
    val type: String,
    val codeAvg: String,
    val onlyLoyalty: Boolean,
    val frequency: Int,
    val amount: String,
    val amount2: String?,
    val amount3: String?
)
