package com.mshopsas.enterprise.aiw.scan.data.basket.ticket

import kotlinx.serialization.Serializable

@Serializable
data class TicketItem(
    val article: String,
    val price: String,
    val quantity: Int,
    val total: String,
    val discount: Discount?
)