package com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.camera

import com.mshopsas.enterprise.aiw.scan.data.Store
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class CameraContract {

    sealed interface Event : ViewEvent {
        data object OnBackClick : Event
        data object OnScanClick : Event

        data object OnCancelClick : Event
        data object ToLoyalty : Event
        data class OnOkClick(val store: Store?) : Event

        data class OnResult(val result: ScannerData?) : Event
    }

    data class State(
        val store: Store?,
        val isOkCancelOpen: Boolean,
        val navigateToLoyaltyScreen: Boolean,
        val message: String,
        val isLoading: Boolean
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
            data object ToLoyalty : Navigation
            data object ToScan : Navigation
        }
    }

}
