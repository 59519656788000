package com.mshopsas.enterprise.aiw.scan.screens.payment

import aiwscan.composeapp.generated.resources.Res
import cafe.adriel.voyager.core.model.screenModelScope
import com.mshopsas.enterprise.aiw.scan.data.MerchantInfo
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.ExperimentalResourceApi

class PaymentSelectionScreenModel(
    private val repository: Repository
) : BaseScreenModel<PaymentSelectionContract.Event, PaymentSelectionContract.State, PaymentSelectionContract.Effect>() {

    override fun setInitialState() = PaymentSelectionContract.State(
        showExplanation = false,
        message = "",
        isLoading = false,
        showAuditRequired = false,
        showAssitanceRequired = false
    )

    override fun handleEvents(event: PaymentSelectionContract.Event) {
        when (event) {
            PaymentSelectionContract.Event.OnApplePay -> {}
            PaymentSelectionContract.Event.OnCardPay -> onMPay()
            PaymentSelectionContract.Event.OnBizumPay -> {onBizum()}
            PaymentSelectionContract.Event.OnCashierPay -> onCashierPay()
            PaymentSelectionContract.Event.OnGooglePay -> {}
            is PaymentSelectionContract.Event.OnResult -> onResult(event.result)
            PaymentSelectionContract.Event.OnSheetClose -> onSheetClose()
            PaymentSelectionContract.Event.OnAuditSheetClose -> onAuditSheetClose()
        }
    }

    private fun onNextClick() {
//        setEffect { PaymentSelectionContract.Effect.Navigation.ToGuide }
    }

    private fun onCashierPay() {
        setState { copy(showExplanation = true) }
//        setEffect { PaymentSelectionContract.Effect.Navigation.ToCheckout }
    }

    private fun onMPay() {
        setEffect { PaymentSelectionContract.Effect.Navigation.ToMPay }
    }

    @OptIn(ExperimentalResourceApi::class)
    private fun onBizum() {
        screenModelScope.launch {
            // Here should call server to get the Bizum parameters
            val basket = repository.getBasket().value
            val merchantInfo = MerchantInfo(
                identifier = "REQUIRED",
                amount = basket.amountTotal.toString(),
                currency = "978",
                merchantCode = "999008881",
                merchantUrl = "https://mbase.app/mBase/api/v2.0/payment/notification/bizum",
                order = "907806705501",
                terminal = "049",
                payMethods = "z",
                transactionType = "0",
                urlKO = "https://catalog.mbase.app/#/registered/welcome",
                urlOK = "https://catalog.mbase.app/#/registered/layout/confirmation"
            )
//            val json = Json { encodeDefaults = true }
//            val merchantInfoString = json.encodeToString(merchantInfo)
//            Logger.d("Merchant $merchantInfoString")
//            val encodedMerchantInfo = merchantInfoString.encodeToByteArray()
//            val encodedMerchantInfo64 = encodedMerchantInfo.encodeBase64()
//            Logger.d("Encoded $encodedMerchantInfo64")
            val html = Res.readBytes("files/payment.html").decodeToString()
//            var finalHtml = html.replace("{{merchantInfo}}", encodedMerchantInfo64)
//            val key = "sq7HjrUOBfKmC576ILgskD5srU870gJ7"
//
//            val mac = HmacSHA256(key.encodeToByteArray())
//            val hash = mac.doFinal(encodedMerchantInfo)
//            val encodedSignature = hash.encodeBase64()
//            Logger.d("Signature $encodedSignature")
//            finalHtml = finalHtml.replace("{{signature}}", encodedSignature)
//            Logger.d("HTML $finalHtml")
//            setEffect { PaymentSelectionContract.Effect.Navigation.ToBizum(finalHtml) }
            setEffect { PaymentSelectionContract.Effect.Navigation.ToBizum(html) }
        }
    }

    private fun onSheetClose() {
        setState { copy(showExplanation = false) }
        setEffect { PaymentSelectionContract.Effect.Navigation.ToCheckout }
    }

    private fun onAuditSheetClose() {
        setState { copy(showAuditRequired = false) }
    }

    private fun onResult(data: ScannerData?) {
        data?.data?.let {
            setEffect { PaymentSelectionContract.Effect.Navigation.ToTicket }
//            screenModelScope.launch {
//                repository.callEndBasket(it).collect { result ->
//                    when(result.status) {
//                        Resource.Status.SUCCESS -> {
//                            result.data?.let {
//                                Logger.d("Response $it")
//                                when(it.result) {
//                                    EndBasketRequest.WAITING_TO_PAY -> {
//                                        //navigateToGenerateBarcode
//                                        setState { copy(message = EndBasketRequest.WAITING_TO_PAY) }
//                                    }
//                                    EndBasketRequest.AUDIT_REQUIRED -> {
//                                        setState { copy(showAuditRequired = true) }
//                                    }
//                                    EndBasketRequest.WAITING_ASSISTANCE -> {
//                                        setState { copy(showAssitanceRequired = true) }
//                                    }
//                                    else -> {
//                                        setState { copy(message = it.result ?: "Unknown status") }
//                                    }
//                                }
//                                setState { copy(isLoading = false) }
//                            } ?: run {
//                                // show error sheet
//                                setState { copy(isLoading = false, message = "CheckIn error") }
//                            }
//                        }
//                        Resource.Status.ERROR -> {
//                            val errorMsg = result.error?.message ?: "unknown error"
//                            setState { copy(isLoading = false, message = errorMsg) }
//                        }
//                        Resource.Status.LOADING -> {
//                            setState { copy(isLoading = true) }
//                        }
//                    }
//                }
//            }
        }
//        setEffect { LoyaltyContract.Effect.Navigation.ToHome }
    }

}
