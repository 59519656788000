package com.mshopsas.enterprise.aiw.scan.data.basket

import kotlinx.serialization.Serializable

@Serializable
data class Item(
    val idBasketItem: Long,
    val eanCode: String,
    val quantity: Int,
    val finalPrice: Int?,
    val productLabel: String?,
    val image: String?,
    val unitScannedPrice: Int?,
    val totalScannedPrice: Int?,
    val aisle: String?,
    val aisleOrder: String?,
    val candidatePromo: String?,
    val inList: Boolean = false
) {
    fun codeToCompare(): String {
        return if ("""^2([468])\d+""".toRegex().matches(eanCode)) {
            eanCode.take(7).padEnd(12, '0').toEan13()
        } else {
            eanCode
        }
    }

    private fun String.toEan13(): String {
        var odd = 0
        var even = 0
        for (i in indices) {
            val index = i + 1
            if (index % 2 != 0)
                odd += get(i).toString().toInt()
            else
                even += get(i).toString().toInt()
        }
        return this + ((10 - ((odd + even * 3) % 10)) % 10).toString()
    }
}
