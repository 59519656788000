package com.mshopsas.enterprise.aiw.scan.screens.search.detail

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.home_aisle
import aiwscan.composeapp.generated.resources.home_aisle_order
import aiwscan.composeapp.generated.resources.search_related_products
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.LocationOn
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.OffInfo
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.Price
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.PromoInfo
import com.mshopsas.enterprise.aiw.scan.screens.search.composables.ProductSearchCellView
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun SearchProductDetailView(
    state: SearchProductDetailContract.State,
    onEventSent: (SearchProductDetailContract.Event) -> Unit,
    product: ProductDetail
) {

    LaunchedEffect("Init") {
        onEventSent(SearchProductDetailContract.Event.OnInit(product))
    }

    MScanScaffold(
        navigationIcon = {
            BackButton( onClick= {onEventSent(SearchProductDetailContract.Event.OnBack)})
        }
    ) { padding ->
        Column(
            modifier = Modifier.fillMaxSize().padding(padding).verticalScroll(rememberScrollState()),
        ) {
            Row(
                modifier = Modifier.fillMaxWidth().padding(8.dp),
            ) {
                ImageLoader(
                    product.imageMainName ?: "",
                    modifier = Modifier.weight(1f / 4f).aspectRatio(4 / 4f)
                        .clip(RoundedCornerShape(4.dp))
                )
            }
            Row(
                modifier = Modifier.fillMaxWidth().padding(8.dp)
            ) {
                Text(
                    text = "${product.nameLine1} ${product.nameLine2 ?: ""}",
                    style = MaterialTheme.typography.titleMedium,
                    fontSize = 18.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.weight(1f),
                    maxLines = 2
                )
                Price(product.finalUnitPrice ?: 0, false)
            }
            if (product.aisle != null) {
                Row(
                    modifier = Modifier.fillMaxWidth().padding(8.dp)
                ) {
                    Image(Icons.Default.LocationOn, null)
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(stringResource(Res.string.home_aisle, product.aisle), fontSize = 22.sp)
                    if(product.aisleOrder != null) {
                        Text(stringResource(Res.string.home_aisle_order, product.aisleOrder), fontSize = 22.sp)
                    }
                }
            }
            if(state.promo != null) {
                PromoInfo(state.promo)
            }
            if(state.off != null) {
                OffInfo(state.off)
            }
            if(state.related.isNotEmpty()) {
                Row(
                    modifier = Modifier.fillMaxWidth().padding(8.dp)
                ) {
                    Text(
                        text = stringResource(Res.string.search_related_products),
                        style = MaterialTheme.typography.titleMedium,
                        fontSize = 18.sp,
                        fontWeight = FontWeight.Normal,
                        modifier = Modifier.weight(1f),
                        maxLines = 2
                    )
                }
                state.related.forEach { product ->
                    ProductSearchCellView(
                        product = product,
                        isAnonymous = true,
                        onItemClick = {}
                    )
                }
            }
        }
    }

}
