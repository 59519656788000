package com.mshopsas.enterprise.aiw.scan.screens.scan

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import co.touchlab.kermit.Logger
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerType

external fun openScanner(callback: (ScanDataJs) -> Unit)
external fun stopScanner()

external class ScanDataJs(data: String, type: String) {
    val data: String
    val type: String
}

@Composable
actual fun ScannerView(
    types: List<ScannerType>,
    width: Float,
    modifier: Modifier,
    onBackPressed: Boolean,
    callback: (ScannerData?) -> Unit,
) {

    LaunchedEffect("Scan") {
        openScanner {
            val type = from(it.type)

            Logger.d{"types: $types, type: $type"}

            if (types.isEmpty() ||
                (types.contains(ScannerType.DATA_MATRIX) && type == ScannerType.DATA_MATRIX) ||
                (types.contains(ScannerType.EAN13) && type == ScannerType.EAN13) ||
                (types.contains(ScannerType.EAN8) && type == ScannerType.EAN8) ||
                (types.contains(ScannerType.QR) && type == ScannerType.QR)
            ) {
                stopScanner()
                callback(ScannerData(it.data, type))
            }
        }
    }

    if (onBackPressed) {
        stopScanner()
        callback(null)
    }
}

private fun from(type: String): ScannerType {
    return when (type) {
        "DATA_MATRIX" -> ScannerType.DATA_MATRIX
        "EAN_13" -> ScannerType.EAN13
        "EAN_8" -> ScannerType.EAN8
        "QR_CODE" -> ScannerType.QR
        else -> ScannerType.UNKNOWN
    }
}