package com.mshopsas.enterprise.aiw.scan.network.request

import kotlinx.serialization.Serializable

@Serializable
data class LinkLoyaltyRequest(
    val encodedShopperId: String,
    val storeIdentifier: String,
    val shopperLoyaltyCard: String
)
