package com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.camera

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerType
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.scan.ScanScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty.LoyaltyScreen
import com.mshopsas.enterprise.aiw.scan.utils.getResult
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data object CameraScreen : Screen {

    @Composable
    override fun Content() {

        val navigator = LocalNavigator.currentOrThrow
//        val screenModel = navigator.rememberNavigatorScreenModel { CameraScreenModel() }
        val screenModel = getScreenModel<CameraScreenModel>()

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    CameraContract.Effect.Navigation.ToBack -> {
                        navigator.pop()
                    }

                    CameraContract.Effect.Navigation.ToLoyalty -> {
                        navigator.push(LoyaltyScreen)
                    }

                    CameraContract.Effect.Navigation.ToScan -> {
                        navigator.push(ScanScreen(listOf(ScannerType.DATA_MATRIX, ScannerType.QR)))
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (CameraContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        CameraView(state, onEventSent)

        navigator.getResult<ScannerData>(ScanScreen.SCAN_RESULT)?.let {
            onEventSent(CameraContract.Event.OnResult(it.value))
        }
    }
}
