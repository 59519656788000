package com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.location

import com.mshopsas.enterprise.aiw.scan.data.Location
import com.mshopsas.enterprise.aiw.scan.data.Store
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class LocationContract {

    sealed interface Event : ViewEvent {
        data object OnRequestLocationClick : Event

        data class OnLocationResult(val result: Location?) : Event

        data class OnStoreSelected(val store: Store?) : Event

        data object OnBackClick : Event

        data class OnOkClick(val store: Store?) : Event
        data object OnCancelClick : Event
        data class OnCameraPermissionIsGranted(val isGranted: Boolean) : Event
    }

    data class State(
        val askPermission: Boolean,
        val location: Location?,
        val store: Store?,
        val storeList: List<Store>,
        val isOkCancelOpen: Boolean,
        val checkCameraPermission: Boolean,
        val navigateToScanScreen: Boolean,
        val message: String,
        val isLoading: Boolean
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
            data object ToHome : Navigation
            data object ToLoyalty : Navigation
            data object ToCamera : Navigation
            data class ToCameraPermission(val navigateToScanScreen: Boolean) : Navigation
        }
    }

}
