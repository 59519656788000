package com.mshopsas.enterprise.aiw.scan.network.response

import kotlinx.serialization.Serializable

@Serializable
data class LinkLoyaltyResponse(
    val response: LoyaltyData
)

@Serializable
data class LoyaltyData(
    val encodedId: String,
    val lang: String,
    val name: String
)
