package com.mshopsas.enterprise.aiw.scan.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MerchantInfo(
    @SerialName("DS_MERCHANT_IDENTIFIER")
    val identifier: String,
    @SerialName("DS_MERCHANT_AMOUNT")
    val amount: String,
    @SerialName("DS_MERCHANT_CURRENCY")
    val currency: String,
    @SerialName("DS_MERCHANT_MERCHANTCODE")
    val merchantCode: String,
    @SerialName("DS_MERCHANT_MERCHANTURL")
    val merchantUrl: String,
    @SerialName("DS_MERCHANT_ORDER")
    val order: String,
    @SerialName("DS_MERCHANT_TERMINAL")
    val terminal: String,
    @SerialName("DS_MERCHANT_PAYMETHODS")
    val payMethods: String,
    @SerialName("DS_MERCHANT_TRANSACTIONTYPE")
    val transactionType: String,
    @SerialName("DS_MERCHANT_URLKO")
    val urlKO: String,
    @SerialName("DS_MERCHANT_URLOK")
    val urlOK: String
)
