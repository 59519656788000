@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package aiwscan.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val alcampo_logo: DrawableResource by 
      lazy { init_alcampo_logo() }

  public val apple_pay: DrawableResource by 
      lazy { init_apple_pay() }

  public val barcode_scanner: DrawableResource by 
      lazy { init_barcode_scanner() }

  public val bizum: DrawableResource by 
      lazy { init_bizum() }

  public val camera_animation: DrawableResource by 
      lazy { init_camera_animation() }

  public val card: DrawableResource by 
      lazy { init_card() }

  public val card_animation: DrawableResource by 
      lazy { init_card_animation() }

  public val cashier: DrawableResource by 
      lazy { init_cashier() }

  public val empty_basket_animation: DrawableResource by 
      lazy { init_empty_basket_animation() }

  public val empty_list_animation: DrawableResource by 
      lazy { init_empty_list_animation() }

  public val empty_star: DrawableResource by 
      lazy { init_empty_star() }

  public val en_circle: DrawableResource by 
      lazy { init_en_circle() }

  public val es_circle: DrawableResource by 
      lazy { init_es_circle() }

  public val eu_circle: DrawableResource by 
      lazy { init_eu_circle() }

  public val fill_star: DrawableResource by 
      lazy { init_fill_star() }

  public val fr_circle: DrawableResource by 
      lazy { init_fr_circle() }

  public val google_pay: DrawableResource by 
      lazy { init_google_pay() }

  public val it_circle: DrawableResource by 
      lazy { init_it_circle() }

  public val location_animation: DrawableResource by 
      lazy { init_location_animation() }

  public val mscan_logo: DrawableResource by 
      lazy { init_mscan_logo() }

  public val nova1: DrawableResource by 
      lazy { init_nova1() }

  public val nova2: DrawableResource by 
      lazy { init_nova2() }

  public val nova3: DrawableResource by 
      lazy { init_nova3() }

  public val nova4: DrawableResource by 
      lazy { init_nova4() }

  public val nutriscore_a: DrawableResource by 
      lazy { init_nutriscore_a() }

  public val nutriscore_b: DrawableResource by 
      lazy { init_nutriscore_b() }

  public val nutriscore_c: DrawableResource by 
      lazy { init_nutriscore_c() }

  public val nutriscore_d: DrawableResource by 
      lazy { init_nutriscore_d() }

  public val nutriscore_e: DrawableResource by 
      lazy { init_nutriscore_e() }

  public val product_in_list: DrawableResource by 
      lazy { init_product_in_list() }

  public val shopping_animation: DrawableResource by 
      lazy { init_shopping_animation() }

  public val store_scan_qr: DrawableResource by 
      lazy { init_store_scan_qr() }

  public val unfound_image: DrawableResource by 
      lazy { init_unfound_image() }

  public val visa_mastercard: DrawableResource by 
      lazy { init_visa_mastercard() }

  public val welcome: DrawableResource by 
      lazy { init_welcome() }
}

internal val Res.drawable.alcampo_logo: DrawableResource
  get() = CommonMainDrawable0.alcampo_logo

private fun init_alcampo_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:alcampo_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/alcampo_logo.png", -1, -1),
    )
)

internal val Res.drawable.apple_pay: DrawableResource
  get() = CommonMainDrawable0.apple_pay

private fun init_apple_pay(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:apple_pay",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/apple_pay.png", -1, -1),
    )
)

internal val Res.drawable.barcode_scanner: DrawableResource
  get() = CommonMainDrawable0.barcode_scanner

private fun init_barcode_scanner(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:barcode_scanner",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/barcode_scanner.xml", -1, -1),
    )
)

internal val Res.drawable.bizum: DrawableResource
  get() = CommonMainDrawable0.bizum

private fun init_bizum(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:bizum",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/bizum.png", -1, -1),
    )
)

internal val Res.drawable.camera_animation: DrawableResource
  get() = CommonMainDrawable0.camera_animation

private fun init_camera_animation(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:camera_animation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/camera_animation.gif", -1, -1),
    )
)

internal val Res.drawable.card: DrawableResource
  get() = CommonMainDrawable0.card

private fun init_card(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:card",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/card.png", -1, -1),
    )
)

internal val Res.drawable.card_animation: DrawableResource
  get() = CommonMainDrawable0.card_animation

private fun init_card_animation(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_animation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/card_animation.gif", -1, -1),
    )
)

internal val Res.drawable.cashier: DrawableResource
  get() = CommonMainDrawable0.cashier

private fun init_cashier(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:cashier",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/cashier.png", -1, -1),
    )
)

internal val Res.drawable.empty_basket_animation: DrawableResource
  get() = CommonMainDrawable0.empty_basket_animation

private fun init_empty_basket_animation(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:empty_basket_animation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/empty_basket_animation.gif", -1, -1),
    )
)

internal val Res.drawable.empty_list_animation: DrawableResource
  get() = CommonMainDrawable0.empty_list_animation

private fun init_empty_list_animation(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:empty_list_animation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/empty_list_animation.gif", -1, -1),
    )
)

internal val Res.drawable.empty_star: DrawableResource
  get() = CommonMainDrawable0.empty_star

private fun init_empty_star(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:empty_star",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/empty_star.png", -1, -1),
    )
)

internal val Res.drawable.en_circle: DrawableResource
  get() = CommonMainDrawable0.en_circle

private fun init_en_circle(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:en_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/en_circle.xml", -1, -1),
    )
)

internal val Res.drawable.es_circle: DrawableResource
  get() = CommonMainDrawable0.es_circle

private fun init_es_circle(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:es_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/es_circle.xml", -1, -1),
    )
)

internal val Res.drawable.eu_circle: DrawableResource
  get() = CommonMainDrawable0.eu_circle

private fun init_eu_circle(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:eu_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/eu_circle.xml", -1, -1),
    )
)

internal val Res.drawable.fill_star: DrawableResource
  get() = CommonMainDrawable0.fill_star

private fun init_fill_star(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:fill_star",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/fill_star.png", -1, -1),
    )
)

internal val Res.drawable.fr_circle: DrawableResource
  get() = CommonMainDrawable0.fr_circle

private fun init_fr_circle(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:fr_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/fr_circle.xml", -1, -1),
    )
)

internal val Res.drawable.google_pay: DrawableResource
  get() = CommonMainDrawable0.google_pay

private fun init_google_pay(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:google_pay",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/google_pay.png", -1, -1),
    )
)

internal val Res.drawable.it_circle: DrawableResource
  get() = CommonMainDrawable0.it_circle

private fun init_it_circle(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:it_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/it_circle.xml", -1, -1),
    )
)

internal val Res.drawable.location_animation: DrawableResource
  get() = CommonMainDrawable0.location_animation

private fun init_location_animation(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:location_animation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/location_animation.gif", -1, -1),
    )
)

internal val Res.drawable.mscan_logo: DrawableResource
  get() = CommonMainDrawable0.mscan_logo

private fun init_mscan_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:mscan_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/mscan_logo.png", -1, -1),
    )
)

internal val Res.drawable.nova1: DrawableResource
  get() = CommonMainDrawable0.nova1

private fun init_nova1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:nova1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/nova1.png", -1, -1),
    )
)

internal val Res.drawable.nova2: DrawableResource
  get() = CommonMainDrawable0.nova2

private fun init_nova2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:nova2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/nova2.png", -1, -1),
    )
)

internal val Res.drawable.nova3: DrawableResource
  get() = CommonMainDrawable0.nova3

private fun init_nova3(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:nova3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/nova3.png", -1, -1),
    )
)

internal val Res.drawable.nova4: DrawableResource
  get() = CommonMainDrawable0.nova4

private fun init_nova4(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:nova4",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/nova4.png", -1, -1),
    )
)

internal val Res.drawable.nutriscore_a: DrawableResource
  get() = CommonMainDrawable0.nutriscore_a

private fun init_nutriscore_a(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:nutriscore_a",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/nutriscore_a.png", -1, -1),
    )
)

internal val Res.drawable.nutriscore_b: DrawableResource
  get() = CommonMainDrawable0.nutriscore_b

private fun init_nutriscore_b(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:nutriscore_b",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/nutriscore_b.png", -1, -1),
    )
)

internal val Res.drawable.nutriscore_c: DrawableResource
  get() = CommonMainDrawable0.nutriscore_c

private fun init_nutriscore_c(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:nutriscore_c",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/nutriscore_c.png", -1, -1),
    )
)

internal val Res.drawable.nutriscore_d: DrawableResource
  get() = CommonMainDrawable0.nutriscore_d

private fun init_nutriscore_d(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:nutriscore_d",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/nutriscore_d.png", -1, -1),
    )
)

internal val Res.drawable.nutriscore_e: DrawableResource
  get() = CommonMainDrawable0.nutriscore_e

private fun init_nutriscore_e(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:nutriscore_e",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/nutriscore_e.png", -1, -1),
    )
)

internal val Res.drawable.product_in_list: DrawableResource
  get() = CommonMainDrawable0.product_in_list

private fun init_product_in_list(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:product_in_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/product_in_list.xml", -1, -1),
    )
)

internal val Res.drawable.shopping_animation: DrawableResource
  get() = CommonMainDrawable0.shopping_animation

private fun init_shopping_animation(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:shopping_animation",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/shopping_animation.gif", -1, -1),
    )
)

internal val Res.drawable.store_scan_qr: DrawableResource
  get() = CommonMainDrawable0.store_scan_qr

private fun init_store_scan_qr(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:store_scan_qr",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/store_scan_qr.gif", -1, -1),
    )
)

internal val Res.drawable.unfound_image: DrawableResource
  get() = CommonMainDrawable0.unfound_image

private fun init_unfound_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:unfound_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/unfound_image.xml", -1, -1),
    )
)

internal val Res.drawable.visa_mastercard: DrawableResource
  get() = CommonMainDrawable0.visa_mastercard

private fun init_visa_mastercard(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:visa_mastercard",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/visa_mastercard.png", -1, -1),
    )
)

internal val Res.drawable.welcome: DrawableResource
  get() = CommonMainDrawable0.welcome

private fun init_welcome(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:welcome",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/drawable/welcome.xml", -1, -1),
    )
)
