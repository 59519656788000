package com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty

import cafe.adriel.voyager.core.model.screenModelScope
import co.touchlab.kermit.Logger
import com.mshopsas.enterprise.aiw.scan.data.CheckIn
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.CheckInRequest
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class LoyaltyScreenModel(
    private val repository: Repository
) : BaseScreenModel<LoyaltyContract.Event, LoyaltyContract.State, LoyaltyContract.Effect>() {

    override fun setInitialState() = LoyaltyContract.State(
        message = "",
        isLoading = false
    )

    override fun handleEvents(event: LoyaltyContract.Event) {
        when (event) {
            LoyaltyContract.Event.OnBackClick -> onBackClick()
            LoyaltyContract.Event.ToScan -> onScanClick()
            LoyaltyContract.Event.OnAnonymousClick -> onAnonymousClick()
            is LoyaltyContract.Event.OnResult -> onResult(event.result)
        }
    }

    private fun onBackClick() {
        setEffect { LoyaltyContract.Effect.Navigation.ToBack }
    }

    private fun toHome() {
        setEffect { LoyaltyContract.Effect.Navigation.ToHome }
    }

    private fun onScanClick() {
        setEffect { LoyaltyContract.Effect.Navigation.ToScan }
    }

    private fun onAnonymousClick() {
        screenModelScope.launch {
            repository.callCheckIn(null, CheckInRequest.ANONYMOUS).collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let {
                            Logger.d("Response $it")
                            storeCheckInDetails(it, null)
                            setState { copy(isLoading = false) }
                            toHome()
                        } ?: run {
                            //ToDo: show error sheet
                            setState { copy(isLoading = false, message = "CheckIn error") }
                        }
                    }

                    Resource.Status.ERROR -> {
                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false, message = errorMsg) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

    private fun onResult(data: ScannerData?) {
        data?.data?.let {
            onLoyaltyClick(it)
        }
//        setEffect { LoyaltyContract.Effect.Navigation.ToHome }
    }

    private fun onLoyaltyClick(identifier: String) {
        screenModelScope.launch {
            repository.callCheckIn(identifier, CheckInRequest.LOYALTY).collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let {
                            storeCheckInDetails(it, identifier)
                            setState { copy(isLoading = false) }
                            toHome()
                        } ?: run {
                            //ToDo: show error sheet
                            setState { copy(isLoading = false, message = "CheckIn error") }
                        }
                    }

                    Resource.Status.ERROR -> {
                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false, message = errorMsg) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

    private suspend fun storeCheckInDetails(checkIn: CheckIn, identifier: String?) {
        repository.setCheckIn(checkIn, identifier)
        repository.setBasket(checkIn.currentBasket)
    }
}
