package com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.location

import androidx.compose.runtime.Composable
import com.mshopsas.enterprise.aiw.scan.data.Location

external fun getLocation(callback: (LocationJs?) -> Unit)

external class LocationJs(lat: Double, long: Double) {
    val lat: Double
    val long: Double
}

@Composable
actual fun LocationProvider(callback: (Location?) -> Unit) {
    getLocation {
        callback(it?.let { Location(it.lat, it.long) })
    }
}
