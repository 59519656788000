package com.mshopsas.enterprise.aiw.scan.screens.search.detail

import com.mshopsas.enterprise.aiw.scan.data.basket.details.OFFProduct
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetailsPromo
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class SearchProductDetailContract {
    sealed interface Event : ViewEvent {
        data class OnInit(val product: ProductDetail) : Event
        data object OnBack : Event
    }

    data class State(
        val promo: ProductDetailsPromo?,
        val off: OFFProduct?,
        val related: List<ProductDetail>
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
        }
    }
}