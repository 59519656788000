package com.mshopsas.enterprise.aiw.scan.network.request

import kotlinx.serialization.Serializable

@Serializable
data class ProductRelatedRequest(
    val uuidStore: String,
    val eanCode: String,
    val limit: Int
)
