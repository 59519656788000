package com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.camera

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.camera_message1
import aiwscan.composeapp.generated.resources.camera_next
import aiwscan.composeapp.generated.resources.camera_title1
import aiwscan.composeapp.generated.resources.store_scan_qr
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.QrCodeScanner
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import com.mshopsas.enterprise.aiw.scan.screens.commons.ProgressIndicator
import com.mshopsas.enterprise.aiw.scan.screens.commons.Tip
import com.mshopsas.enterprise.aiw.scan.screens.commons.okCancelBottomSheet.OkCancelBottomSheet
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CameraView(
    state: CameraContract.State,
    onEventSent: (CameraContract.Event) -> Unit
) {


    Column(modifier = Modifier.fillMaxSize()) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .background(RedDark)
                .weight(1f)
                .padding(8.dp),
            contentAlignment = Alignment.Center
        ) {
            ImageLoader(
                data = Res.drawable.store_scan_qr,
                modifier = Modifier.fillMaxSize(),
                contentScale = ContentScale.Fit
            )
            Box(
                modifier = Modifier.fillMaxSize()
            ) {
                BackButton(onClick = { onEventSent(CameraContract.Event.OnBackClick) })
            }
        }
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .background(Color.White)
                .weight(1f)
                .padding(24.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Column(modifier = Modifier.weight(1f), verticalArrangement = Arrangement.SpaceBetween) {
                Tip(
                    Res.string.camera_title1,
                    Res.string.camera_message1,
                    Icons.Default.QrCodeScanner
                )
            }

            Spacer(modifier = Modifier.height(16.dp))

            Text(text = state.message, color = RedDark)

            ButtonDef(
                onClick = {
                    onEventSent(CameraContract.Event.OnScanClick)
                },
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    text = stringResource(Res.string.camera_next),
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }

    if (state.isLoading) {
        ProgressIndicator()
    }

    if (state.isOkCancelOpen) {
        OkCancelBottomSheet(
            title = state.store?.storeLabel,
            message = "Esta es la tienda más cercana a tu ubicación\n¿Quieres comenzar la compra en ella?",
            onOK = { onEventSent(CameraContract.Event.OnOkClick(state.store)) },
            onCancel = { onEventSent(CameraContract.Event.OnCancelClick) },
            onDismiss = { onEventSent(CameraContract.Event.OnCancelClick) }
        )
    }

    if (state.navigateToLoyaltyScreen) {
        onEventSent(CameraContract.Event.ToLoyalty)
    }

}
