package com.mshopsas.enterprise.aiw.scan.data.basket

import kotlinx.serialization.Serializable

@Serializable
class CompareList (
    val name: String,
    val date: String,
    val totalItems: Int,
    val items: List<CompareItem>
)