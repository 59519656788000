package com.mshopsas.enterprise.aiw.scan.screens.historic.detail

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.data.basket.HistoryOrder
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data class HistoryOrderDetailScreen(val order: HistoryOrder) : Screen {

    @Composable
    override fun Content() {

        val screenModel: HistoryOrderDetailScreenModel = getScreenModel()
        val navigator = LocalNavigator.currentOrThrow

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    HistoryOrderDetailContract.Effect.Navigation.ToBack -> {
                        navigator.pop()
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (HistoryOrderDetailContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        HistoryOrderDetailView(state, onEventSent, order)

    }
}
