package com.mshopsas.enterprise.aiw.scan.screens.payment.mpay

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect

external fun showWebSite(url: String)
external fun submitform()
external fun loadHtml(html: String)
external fun hideWebSite()

@Composable
actual fun MPayWebView(
    url: String,
    onBackPressed: Boolean,
    callback: (String?) -> Unit
) {

    LaunchedEffect("MPayWebView") {
        if (url.startsWith("http")) {
            showWebSite(url)
        } else {
            loadHtml(url)
        }
        submitform()
    }

    if (onBackPressed) {
        hideWebSite()
        callback(null)
    }

}