package com.mshopsas.enterprise.aiw.scan.data.basket.details

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OFFProduct(
    @SerialName("nova_groups")
    val novaGroups: String? = null,
    @SerialName("nutrition_grades")
    val nutritionGrades: String? = null
)
