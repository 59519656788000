package com.mshopsas.enterprise.aiw.scan.di

import com.mshopsas.enterprise.aiw.scan.data.Persistence
import com.mshopsas.enterprise.aiw.scan.utils.player.MediaPlayerController
import com.mshopsas.enterprise.aiw.scan.utils.player.PlatformContext
import io.github.xxfast.kstore.KStore
import io.github.xxfast.kstore.storage.storeOf
import org.koin.dsl.module

actual val dataPersistenceModule = module {
    single<KStore<Persistence>> {
        storeOf(key = "persistence", default = Persistence())
    }
}

actual val mediaPlayerModule = module {
    single<MediaPlayerController> {
        MediaPlayerController(PlatformContext())
    }
}
