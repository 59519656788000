package com.mshopsas.enterprise.aiw.scan.screens.commons.okCancelBottomSheet

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.SheetState
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.theme.BlueGrey
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun OkCancelBottomSheet(
    title: String? = null,
    message: String,
    okText: String? = null,
    onOK: () -> Unit,
    onCancel: (() -> Unit)? = null,
    onDismiss: () -> Unit,
    bottomSheetState: SheetState = rememberModalBottomSheetState(skipPartiallyExpanded = false),
    scope: CoroutineScope = rememberCoroutineScope()
) {

    ModalBottomSheet(
        onDismissRequest = { onDismiss() },
        containerColor = Color.White,
        sheetState = bottomSheetState,
    ) {

        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(start = 32.dp, top = 16.dp, end = 32.dp, bottom = 32.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            if (title != null) {

                Text(text = title, style = MaterialTheme.typography.titleLarge)

                Spacer(modifier = Modifier.height(24.dp))
            }

            Text(
                text = message,
                style = MaterialTheme.typography.bodyLarge,
                textAlign = TextAlign.Center
            )

            Spacer(modifier = Modifier.height(32.dp))

            Row {

                CancelButton(onCancel, scope, bottomSheetState, Modifier.weight(1f))

                ButtonDef(
                    onClick = {
                        scope.launch { bottomSheetState.hide() }.invokeOnCompletion {
                            if (!bottomSheetState.isVisible) {
                                onOK()
                            }
                        }
                    },
                    modifier = Modifier.weight(1f),
                    colors = ButtonDefaults.buttonColors(containerColor = BlueGrey)
                ) {
                    Text(
                        text = okText ?: "Ok",
                        fontWeight = FontWeight.Bold
                    )
                }
            }
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
private fun CancelButton(
    onCancel: (() -> Unit)?,
    scope: CoroutineScope,
    bottomSheetState: SheetState,
    modifier: Modifier
) {
    if (onCancel != null) {
        OutlinedButton(
            onClick = {
                scope.launch { bottomSheetState.hide() }.invokeOnCompletion {
                    if (!bottomSheetState.isVisible) {
                        onCancel()
                    }
                }
            },
            shape = RoundedCornerShape(4.dp),
            border = BorderStroke(2.dp, BlueGrey),
            modifier = modifier
        ) {
            Text(
                text = "Cancel",
                color = BlueGrey,
                fontWeight = FontWeight.Bold
            )
        }

        Spacer(modifier = Modifier.width(16.dp))
    }
}