package com.mshopsas.enterprise.aiw.scan.screens.payment.mpay

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.utils.popWithResult

data class MPayScreen(val url: String): Screen {

    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow

        var onBack by mutableStateOf(false)

        MScanScaffold(
            navigationIcon = {
                BackButton( onClick= { onBack = true })
            }
        ) { _ ->
            MPayWebView(
                url = url,
                onBackPressed = onBack,
                callback = {
                    onBack = false
                    navigator.popWithResult(
                        key = MPAY_RESULT,
                        result = it
                    )
                }
            )
        }
    }

    companion object {
        const val MPAY_RESULT = "MPAY_RESULT"
    }

}