package com.mshopsas.enterprise.aiw.scan.screens.commons

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.mshopsas.enterprise.aiw.scan.theme.Translucent2

@Composable
fun ProgressIndicator(modifier: Modifier = Modifier) {
    Box(modifier = modifier.fillMaxSize().background(Translucent2).clickable {
        // do nothing
    }) {
        CircularProgressIndicator(modifier = Modifier.align(Alignment.Center), color = Color.White)
    }
}
