package com.mshopsas.enterprise.aiw.scan.data

import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import com.mshopsas.enterprise.aiw.scan.data.basket.CompareList
import com.mshopsas.enterprise.aiw.scan.data.basket.EndBasket
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.SetQuantityItemRequest
import com.mshopsas.enterprise.aiw.scan.network.response.BasketStatusResponse
import com.mshopsas.enterprise.aiw.scan.network.response.ConfirmPaymentResponse
import com.mshopsas.enterprise.aiw.scan.network.response.HistoricDetailResponse
import com.mshopsas.enterprise.aiw.scan.network.response.HistoricOrderResponse
import com.mshopsas.enterprise.aiw.scan.network.response.LinkLoyaltyResponse
import com.mshopsas.enterprise.aiw.scan.network.response.LoyaltyData
import com.mshopsas.enterprise.aiw.scan.network.response.OFFProductResponse
import com.mshopsas.enterprise.aiw.scan.network.response.ProductDetailsResponse
import com.mshopsas.enterprise.aiw.scan.network.response.ProductRelatedResponse
import com.mshopsas.enterprise.aiw.scan.network.response.ProductSearchResponse
import com.mshopsas.enterprise.aiw.scan.network.response.SetQuantityResponse
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow

interface Repository {
    fun callStoreByLocation(longitude: Double, latitude: Double): Flow<Resource<Store?>>
    fun callStoreByQR(qrValue: String): Flow<Resource<Store?>>
    fun callCheckIn(identifier: String?, identifierType: String): Flow<Resource<CheckIn?>>
    fun callSetQuantity(item: SetQuantityItemRequest?): Flow<Resource<SetQuantityResponse?>>
    fun callProductDetails(eanCode: String): Flow<Resource<ProductDetailsResponse?>>
    fun callOff(eanCode: String): Flow<Resource<OFFProductResponse?>>
    fun callProductRelated(eanCode: String, quantity: Int = 8, mocked: Boolean = false): Flow<Resource<ProductRelatedResponse?>>
    fun callLinkLoyalty(shopperLoyaltyCard: String): Flow<Resource<LinkLoyaltyResponse?>>
    fun callEndBasket(): Flow<Resource<EndBasket?>>
    fun callConfirmPayment(): Flow<Resource<ConfirmPaymentResponse?>>
    fun callUpdateBasketStatus(): Flow<Resource<BasketStatusResponse?>>
    fun callProductSearch(mainSearch: String): Flow<Resource<ProductSearchResponse?>>
    fun callListOfHistoric(limit: Int = 10): Flow<Resource<HistoricOrderResponse?>>
    fun callGetHistoricDetail(basketId: String): Flow<Resource<HistoricDetailResponse?>>

    suspend fun setStore(store: Store?)
    fun getStore(): Store?

    suspend fun getPersistence(): Persistence
    suspend fun setPersistence(reducer: Persistence.() -> Persistence)

    fun setBasket(basket: Basket)
    fun getBasket(): StateFlow<Basket>

    fun setCompareList(compareList: CompareList?)
    fun getCompareList(): StateFlow<CompareList?>

    suspend fun setCheckIn(checkIn: CheckIn, identifier: String?)
    fun getCheckIn(): CheckIn?

    fun saveLoyaltyData(data: LoyaltyData)

    fun getCandidateList(): List<String>
    fun addCandidate(code: String)
    fun clearCandidateList()

    fun playAlert()
    fun playError()
}
