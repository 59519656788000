package com.mshopsas.enterprise.aiw.scan.screens.payment

import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class PaymentSelectionContract {

    sealed interface Event : ViewEvent {
        data object OnApplePay : Event
        data object OnGooglePay : Event
        data object OnCardPay : Event
        data object OnBizumPay : Event
        data object OnCashierPay : Event
        data class OnResult(val result: ScannerData?) : Event
        data object OnSheetClose : Event
        data object OnAuditSheetClose : Event

    }

    data class State(
        val showExplanation: Boolean = false,
        val message: String = "",
        val isLoading: Boolean = false,
        val showAuditRequired: Boolean = false,
        val showAssitanceRequired: Boolean = false,
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToCheckout : Navigation
            data object ToMPay : Navigation
            data object ToTicket : Navigation
            data class ToBizum(val html: String) : Navigation
        }
    }
}
