package com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.camera

import cafe.adriel.voyager.core.model.screenModelScope
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class CameraScreenModel(
    private val repository: Repository
): BaseScreenModel<CameraContract.Event, CameraContract.State, CameraContract.Effect>() {

    override fun setInitialState() = CameraContract.State(
        store = null,
        isOkCancelOpen = false,
        navigateToLoyaltyScreen = false,
        message = "",
        isLoading = false)

    override fun handleEvents(event: CameraContract.Event) {
        when (event) {
            CameraContract.Event.OnBackClick -> onBackClick()
            CameraContract.Event.OnScanClick -> onScanClick()
            CameraContract.Event.OnCancelClick -> {
                setState { copy(
                    isOkCancelOpen = false,
                    navigateToLoyaltyScreen = false
                ) }
            }
            CameraContract.Event.ToLoyalty -> {
                setEffect { CameraContract.Effect.Navigation.ToLoyalty }
            }

            is CameraContract.Event.OnResult -> onResult(event.result)
            is CameraContract.Event.OnOkClick -> {
                screenModelScope.launch {
                    repository.setStore(event.store)
                    setState {
                        copy(
                            isOkCancelOpen = false,
                            navigateToLoyaltyScreen = true
                        )
                    }
                }
            }
        }
    }

    private fun onBackClick() {
        setEffect { CameraContract.Effect.Navigation.ToBack }
    }

    private fun onScanClick() {
        setEffect { CameraContract.Effect.Navigation.ToScan }
    }

    private fun onResult(data: ScannerData?) {
        data?.data?.let {
            getStoreByQR(it.lowercase().removePrefix("s:"))
        }
    }

    private fun getStoreByQR(code: String) {
        screenModelScope.launch {
            repository.callStoreByQR(code).collect { result ->
                when(result.status) {
                    Resource.Status.SUCCESS -> {
                        setState { copy(isLoading = false, store = result.data, isOkCancelOpen = true, message = "") }
                        repository.setStore(result.data)
                    }
                    Resource.Status.ERROR -> {
                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false, message = errorMsg) }
                    }
                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

}
