package com.mshopsas.enterprise.aiw.scan.screens.payment

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import co.touchlab.kermit.Logger
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerType
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.payment.mpay.MPayScreen
import com.mshopsas.enterprise.aiw.scan.screens.payment.ticket.TicketScreen
import com.mshopsas.enterprise.aiw.scan.screens.scan.ScanScreen
import com.mshopsas.enterprise.aiw.scan.utils.getResult
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data object PaymentSelectionScreen: Screen {

    @Composable
    override fun Content() {

        val navigator = LocalNavigator.currentOrThrow
        val screenModel = getScreenModel<PaymentSelectionScreenModel>()

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    PaymentSelectionContract.Effect.Navigation.ToCheckout -> {
                        navigator.push(ScanScreen(listOf(ScannerType.QR, ScannerType.DATA_MATRIX)))
                    }
                    PaymentSelectionContract.Effect.Navigation.ToTicket -> {
                        navigator.push(TicketScreen)
                    }
                    PaymentSelectionContract.Effect.Navigation.ToMPay -> {
                        navigator.push(MPayScreen("https://pay.devarea.app/transaction/426417930633"))
                    }
                    is PaymentSelectionContract.Effect.Navigation.ToBizum -> {
                        Logger.d("HTML ${it.html}")
                        navigator.push(MPayScreen(it.html))
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (PaymentSelectionContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        PaymentSelectionView(state, onEventSent)

        navigator.getResult<ScannerData>(ScanScreen.SCAN_RESULT)?.let {
            onEventSent(PaymentSelectionContract.Event.OnResult(it.value))
        }

    }
}
