package com.mshopsas.enterprise.aiw.scan

import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import com.mshopsas.enterprise.aiw.scan.di.initKoin
import org.jetbrains.compose.resources.configureWebResources
import org.jetbrains.skiko.wasm.onWasmReady

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    configureWebResources {
        // Not necessary - It's the same as the default. We add it here just to present this feature.
        resourcePathMapping { path -> "./$path" }
    }
    onWasmReady {
        CanvasBasedWindow(canvasElementId = "ComposeTarget") {
            initKoin()

            App()
        }
    }
}
