package com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.denied

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.camera_animation
import aiwscan.composeapp.generated.resources.camera_permission_android_title1
import aiwscan.composeapp.generated.resources.camera_permission_chrome_message1
import aiwscan.composeapp.generated.resources.camera_permission_chrome_title1
import aiwscan.composeapp.generated.resources.camera_permission_denied_continue
import aiwscan.composeapp.generated.resources.camera_permission_edge_title1
import aiwscan.composeapp.generated.resources.camera_permission_firefox_title1
import aiwscan.composeapp.generated.resources.camera_permission_ios_title1
import aiwscan.composeapp.generated.resources.camera_permission_ms_ie_title1
import aiwscan.composeapp.generated.resources.camera_permission_opera_message1
import aiwscan.composeapp.generated.resources.camera_permission_opera_title1
import aiwscan.composeapp.generated.resources.camera_permission_safari_message1
import aiwscan.composeapp.generated.resources.camera_permission_safari_title1
import aiwscan.composeapp.generated.resources.camera_permission_sheet_message1
import aiwscan.composeapp.generated.resources.camera_permission_sheet_title1
import aiwscan.composeapp.generated.resources.camera_permission_unknown_message1
import aiwscan.composeapp.generated.resources.camera_permission_unknown_title1
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Settings
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import com.mshopsas.enterprise.aiw.scan.screens.commons.Tip
import com.mshopsas.enterprise.aiw.scan.screens.commons.okCancelBottomSheet.OkCancelBottomSheet
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.CameraPermissionIsGranted
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun CameraPermissionDeniedView(
    state: CameraPermissionDeniedContract.State,
    onEventSent: (CameraPermissionDeniedContract.Event) -> Unit,
    platform: String,
    navigateToScanScreen: Boolean
) {


    Column(modifier = Modifier.fillMaxSize()) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .background(RedDark)
                .weight(1f)
                .padding(8.dp),
            contentAlignment = Alignment.Center
        ) {
            ImageLoader(
                data = Res.drawable.camera_animation,
                modifier = Modifier.fillMaxSize(),
                contentScale = ContentScale.Fit
            )
        }
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .background(Color.White)
                .weight(1f)
                .padding(24.dp)
                .verticalScroll(rememberScrollState()),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Column(modifier = Modifier.weight(1f), verticalArrangement = Arrangement.SpaceBetween) {
                var title = Res.string.camera_permission_unknown_title1
                var msg = Res.string.camera_permission_unknown_message1
                when(platform){
                    "firefox" -> {
                        title = Res.string.camera_permission_firefox_title1
                    }
                    "chrome" -> {
                        title = Res.string.camera_permission_chrome_title1
                        msg = Res.string.camera_permission_chrome_message1
                    }
                    "safari" -> {
                        title = Res.string.camera_permission_safari_title1
                        msg = Res.string.camera_permission_safari_message1
                    }
                    "edge" -> {
                        title = Res.string.camera_permission_edge_title1
                    }
                    "opera" -> {
                        title = Res.string.camera_permission_opera_title1
                        msg = Res.string.camera_permission_opera_message1
                    }
                    "msie" -> {
                        title = Res.string.camera_permission_ms_ie_title1
                    }
                    "android" -> {
                        title = Res.string.camera_permission_android_title1
                    }
                    "ios" -> {
                        title = Res.string.camera_permission_ios_title1
                    }
                }
                Tip(title, msg, Icons.Default.Settings)
            }

            Spacer(modifier = Modifier.height(16.dp))

            ButtonDef(
                onClick = {
                    onEventSent(CameraPermissionDeniedContract.Event.OnContinueClick)
                },
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    text = stringResource(Res.string.camera_permission_denied_continue),
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }

    if (state.checkPermission) {
        CameraPermissionIsGranted { granted ->
            if(granted) {
                onEventSent(CameraPermissionDeniedContract.Event.OnPermissionGranted(navigateToScanScreen))
            } else {
                onEventSent(CameraPermissionDeniedContract.Event.OnPermissionDenied)
            }
        }
    }
    if (state.showPermissionExplanation) {
        OkCancelBottomSheet(
            title = stringResource(Res.string.camera_permission_sheet_title1),
            message = stringResource(Res.string.camera_permission_sheet_message1),
            onOK = { onEventSent(CameraPermissionDeniedContract.Event.OnSheetClose) },
            onDismiss = { onEventSent(CameraPermissionDeniedContract.Event.OnSheetClose) }
        )
    }
}
