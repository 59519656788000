package com.mshopsas.enterprise.aiw.scan.screens.payment.ticket

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data object TicketScreen: Screen {

    @Composable
    override fun Content() {

        val navigator = LocalNavigator.currentOrThrow
        val screenModel = getScreenModel<TicketScreenModel>()

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
//                when (it) {
//                    PaymentSelectionContract.Effect.Navigation.ToBack -> navigator.pop()
//                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (TicketContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        TicketView(state, onEventSent)

    }
}
