package com.mshopsas.enterprise.aiw.scan.screens.audit

import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class AuditContract {

    sealed interface Event : ViewEvent {
        data object UpdateBasketStatus : Event
        data object OnPendingAuditoryClose : Event
    }

    data class State(
        val isLoading: Boolean,
        val showPendingAuditory: Boolean
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToPayments : Navigation
        }
    }
}
