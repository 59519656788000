package com.mshopsas.enterprise.aiw.scan.data

import kotlinx.browser.window
import org.w3c.dom.url.URLSearchParams

actual fun getParameters(): Parameters {
    val queryString = window.location.search
    val urlParams = URLSearchParams(queryString)
    val storeUuid = urlParams.get("storeUuid")
    val identifier = urlParams.get("identifier")
    return Parameters(storeUuid, identifier)
}
