package com.mshopsas.enterprise.aiw.scan.theme

import androidx.compose.ui.graphics.Color

val RedDark = Color(0xffc22821)
val GreenDark = Color(0xff148835)
val YellowDark = Color(0xffffc400)
val BlueGrey = Color(0xff103040)
val VeryLightGray = Color(0xFFEEEEEE)
val Translucent2 = Color(0x90000000)
val Translucent = Color(0x40000020)
val TranslucentLight = Color(0x40d7d7ff)
val GreenMShop = Color(0xFF80CC28)
