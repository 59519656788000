package com.mshopsas.enterprise.aiw.scan.screens.historic

import cafe.adriel.voyager.core.model.screenModelScope
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.basket.HistoryOrder
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class HistoryOrderScreenModel(
    private val repository: Repository
) : BaseScreenModel<HistoryOrderContract.Event, HistoryOrderContract.State, HistoryOrderContract.Effect>() {

    override fun setInitialState() = HistoryOrderContract.State(
        isLoading = false,
        orders = listOf()
    )

    override fun handleEvents(event: HistoryOrderContract.Event) {
        when (event) {
            is HistoryOrderContract.Event.OnBack -> onBack()
            is HistoryOrderContract.Event.OnDetailClick -> onDetailClick(event.order)
            is HistoryOrderContract.Event.OnUseClick -> onUseClick(event.order)
        }
    }

    init {
        initialize()
    }

    private fun initialize() {
        screenModelScope.launch {
            repository.callListOfHistoric().collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        setState { copy(
                            isLoading = false,
                            orders = result.data?.historics ?: listOf()
                        ) }
                    }

                    Resource.Status.ERROR -> {
                        setState { copy(isLoading = false) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

    private fun onBack() {
        setEffect { HistoryOrderContract.Effect.Navigation.ToBack }
    }

    private fun onDetailClick(order: HistoryOrder) {
        setEffect { HistoryOrderContract.Effect.Navigation.ToHistoryOrderDetail(order) }
    }

    private fun onUseClick(order: HistoryOrder) {
        screenModelScope.launch {
            repository.callGetHistoricDetail(
                basketId = order.id
            ).collect { result ->
                if (result.status == Resource.Status.SUCCESS) {
                    repository.setCompareList(result.data?.historic?.toCompareList())
                    setEffect { HistoryOrderContract.Effect.Navigation.ToBack }
                }
            }
        }
    }

}
