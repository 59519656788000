package com.mshopsas.enterprise.aiw.scan.screens.payment.composables

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.theme.BlueGrey
import com.mshopsas.enterprise.aiw.scan.theme.VeryLightGray
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun PaymentCell(image: DrawableResource?, text: String, onItemClick: () -> Unit) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(6.dp)
            .clickable {
                onItemClick()
            },
        shape = RoundedCornerShape(8.dp),
        colors = CardDefaults.cardColors(
            containerColor = VeryLightGray
        )
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(16.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if(image != null) {
                Image(
                    painterResource(image),
                    null,
                    modifier = Modifier
                        .weight(1 / 4f)
                        .aspectRatio(1 / 1f)
                        .padding(12.dp),
                    contentScale = ContentScale.Fit
                )
            }
            Text(
                modifier = Modifier
                    .weight(4 / 4f)
                    .wrapContentHeight(align = Alignment.CenterVertically),
                text = text,
                fontSize = 28.sp,
                color = BlueGrey,
                textAlign = TextAlign.Center
            )
        }
    }
}