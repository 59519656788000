package com.mshopsas.enterprise.aiw.scan.screens.audit

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.audit_pending_message
import aiwscan.composeapp.generated.resources.audit_pending_title
import aiwscan.composeapp.generated.resources.audit_required
import aiwscan.composeapp.generated.resources.audit_update_state
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.screens.commons.ProgressIndicator
import com.mshopsas.enterprise.aiw.scan.screens.commons.okCancelBottomSheet.OkCancelBottomSheet
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import io.github.alexzhirkevich.qrose.options.QrBallShape
import io.github.alexzhirkevich.qrose.options.QrFrameShape
import io.github.alexzhirkevich.qrose.options.QrPixelShape
import io.github.alexzhirkevich.qrose.options.circle
import io.github.alexzhirkevich.qrose.options.roundCorners
import io.github.alexzhirkevich.qrose.rememberQrCodePainter
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun AuditView(
    state: AuditContract.State,
    onEventSent: (AuditContract.Event) -> Unit,
    auditCode: String
) {
    MScanScaffold {
        Column(modifier = Modifier
            .fillMaxSize()
            .padding(it)
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(RedDark)
                    .padding(6.dp),
                contentAlignment = Alignment.TopCenter
            ) {
                Text(
                    modifier = Modifier
                        .padding(12.dp)
                        .fillMaxWidth()
                        .wrapContentHeight(align = Alignment.CenterVertically),
                    text = stringResource(Res.string.audit_required),
                    fontSize = 24.sp,
                    color = Color.White,
                    textAlign = TextAlign.Center
                )
            }
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(Color.White)
                    .weight(1f)
                    .padding(24.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.SpaceBetween
            ) {
                Image(
                    painter = rememberQrCodePainter(auditCode) {
                        shapes {
                            ball = QrBallShape.circle()
                            darkPixel = QrPixelShape.roundCorners() //Every single dot
                            frame = QrFrameShape.roundCorners(.25f)
                        }
                    },
                    contentDescription = "",
                    modifier = Modifier.fillMaxWidth().aspectRatio(1f),
                    contentScale = ContentScale.Fit
                )
                ButtonDef(
                    onClick = {
                        onEventSent(AuditContract.Event.UpdateBasketStatus)
                    },
                    modifier = Modifier.fillMaxWidth().padding(top = 24.dp)
                ) {
                    Text(
                        text = stringResource(Res.string.audit_update_state),
                        fontWeight = FontWeight.Bold
                    )
                }
            }
        }
    }
    if (state.isLoading) {
        ProgressIndicator()
    }
    if (state.showPendingAuditory) {
        OkCancelBottomSheet(
            title = stringResource(Res.string.audit_pending_title),
            message = stringResource(Res.string.audit_pending_message),
            onOK = { onEventSent(AuditContract.Event.OnPendingAuditoryClose) },
            onDismiss = { onEventSent(AuditContract.Event.OnPendingAuditoryClose) }
        )
    }
}
