package com.mshopsas.enterprise.aiw.scan.screens.payment.ticket

import com.mshopsas.enterprise.aiw.scan.data.basket.ticket.TicketItem
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class TicketContract {

    sealed interface Event : ViewEvent {
//        data object OnBackClick : Event
    }

    data class State(
        val qrData: String,
        val totalItems: String,
        val totalAmount: String,
        val items: List<TicketItem>
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
//            data object ToBack : Navigation
        }
    }
}
