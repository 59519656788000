package com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.camera_animation
import aiwscan.composeapp.generated.resources.camera_permission_message1
import aiwscan.composeapp.generated.resources.camera_permission_next
import aiwscan.composeapp.generated.resources.camera_permission_title1
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Camera
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import com.mshopsas.enterprise.aiw.scan.screens.commons.Tip
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import org.jetbrains.compose.resources.stringResource

@Composable
fun CameraPermissionView(
    state: CameraPermissionContract.State,
    onEventSent: (CameraPermissionContract.Event) -> Unit,
    navigateToScanScreen: Boolean
) {


    Column(modifier = Modifier.fillMaxSize()) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .background(RedDark)
                .weight(1f)
                .padding(8.dp),
            contentAlignment = Alignment.Center
        ) {
            ImageLoader(
                data = Res.drawable.camera_animation,
                modifier = Modifier.fillMaxSize(),
                contentScale = ContentScale.FillHeight
            )

            Box(
                modifier = Modifier.fillMaxSize()
            ) {
                BackButton(onClick = { onEventSent(CameraPermissionContract.Event.OnBackClick) })
            }
        }
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .background(Color.White)
                .weight(1f)
                .padding(24.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Column(modifier = Modifier.weight(1f), verticalArrangement = Arrangement.SpaceBetween) {
                Tip(
                    Res.string.camera_permission_title1,
                    Res.string.camera_permission_message1,
                    Icons.Default.Camera
                )
            }

            Spacer(modifier = Modifier.height(16.dp))

            ButtonDef(
                onClick = {
                    onEventSent(CameraPermissionContract.Event.OnRequestPermissionClick)

                },
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    text = stringResource(Res.string.camera_permission_next),
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }

    if (state.askPermission) {
        CameraPermission { granted, platform ->
            onEventSent(
                CameraPermissionContract.Event.OnPermissionResult(
                    granted,
                    platform,
                    navigateToScanScreen
                )
            )
        }
    }
}
