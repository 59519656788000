package com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty.LoyaltyScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.denied.CameraPermissionDeniedScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.camera.CameraScreen
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data class CameraPermissionScreen(private val navigateToScanScreen: Boolean) : Screen {

    @Composable
    override fun Content() {

        val screenModel = getScreenModel<CameraPermissionScreenModel>()
        val navigator = LocalNavigator.currentOrThrow

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    CameraPermissionContract.Effect.Navigation.ToBack -> {
                        navigator.pop()
                    }

                    CameraPermissionContract.Effect.Navigation.ToLoyalty -> {
                        navigator.push(LoyaltyScreen)
                    }

                    CameraPermissionContract.Effect.Navigation.ToCamera -> {
                        navigator.push(CameraScreen)
                    }

                    is CameraPermissionContract.Effect.Navigation.ToPermissionDenied -> {
                        navigator.push(
                            CameraPermissionDeniedScreen(
                                it.platform,
                                it.navigateToScanScreen
                            )
                        )
                    }

                    CameraPermissionContract.Effect.Navigation.ToHome -> {
                        navigator.push(HomeScreen)
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (CameraPermissionContract.Event) -> Unit =
            { event -> screenModel.setEvent(event) }

        CameraPermissionView(state, onEventSent, navigateToScanScreen)

    }
}
