package com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.card_animation
import aiwscan.composeapp.generated.resources.loyalty_anonymous
import aiwscan.composeapp.generated.resources.loyalty_identify
import aiwscan.composeapp.generated.resources.loyalty_message1
import aiwscan.composeapp.generated.resources.loyalty_title1
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.CardMembership
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import com.mshopsas.enterprise.aiw.scan.screens.commons.Tip
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import org.jetbrains.compose.resources.stringResource

@Composable
fun LoyaltyView(
    state: LoyaltyContract.State,
    onEventSent: (LoyaltyContract.Event) -> Unit
) {

    Column(modifier = Modifier.fillMaxSize()) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .background(RedDark)
                .weight(1f)
                .padding(8.dp),
            contentAlignment = Alignment.TopCenter
        ) {
            ImageLoader(
                Res.drawable.card_animation,
                modifier = Modifier.fillMaxSize(),
                contentScale = ContentScale.FillHeight
            )
            Box(
                modifier = Modifier.fillMaxSize()
            ) {
                BackButton(onClick = { onEventSent(LoyaltyContract.Event.OnBackClick) })
            }
        }
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .background(Color.White)
                .weight(1f)
                .padding(24.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Column(modifier = Modifier.weight(1f), verticalArrangement = Arrangement.SpaceBetween) {
                Tip(
                    Res.string.loyalty_title1,
                    Res.string.loyalty_message1,
                    Icons.Default.CardMembership
                )
            }

            Spacer(modifier = Modifier.height(16.dp))

            ButtonDef(
                onClick = {
                    onEventSent(LoyaltyContract.Event.OnAnonymousClick)
                },
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    text = stringResource(Res.string.loyalty_anonymous),
                    fontWeight = FontWeight.Bold
                )
            }

            Spacer(modifier = Modifier.height(16.dp))

            ButtonDef(
                onClick = {
                    onEventSent(LoyaltyContract.Event.ToScan)
                },
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    text = stringResource(Res.string.loyalty_identify),
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }
}
