package com.mshopsas.enterprise.aiw.scan.screens.historic

import com.mshopsas.enterprise.aiw.scan.data.basket.HistoryOrder
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class HistoryOrderContract {
    sealed interface Event : ViewEvent {
        data object OnBack : Event
        data class OnDetailClick(val order: HistoryOrder) : Event
        data class OnUseClick(val order: HistoryOrder) : Event
    }

    data class State(
        val isLoading: Boolean,
        val orders: List<HistoryOrder>
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
            data class ToHistoryOrderDetail(val order: HistoryOrder) : Navigation
        }
    }
}