package com.mshopsas.enterprise.aiw.scan.screens.tutorial.guide

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.guide_message1
import aiwscan.composeapp.generated.resources.guide_message2
import aiwscan.composeapp.generated.resources.guide_message3
import aiwscan.composeapp.generated.resources.guide_next
import aiwscan.composeapp.generated.resources.guide_title1
import aiwscan.composeapp.generated.resources.guide_title2
import aiwscan.composeapp.generated.resources.guide_title3
import aiwscan.composeapp.generated.resources.shopping_animation
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ShoppingBag
import androidx.compose.material.icons.filled.ShoppingCart
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import com.mshopsas.enterprise.aiw.scan.screens.commons.ProgressIndicator
import com.mshopsas.enterprise.aiw.scan.screens.commons.Tip
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.CameraPermissionIsGranted
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import com.mshopsas.enterprise.aiw.scan.theme.drawables.Barcode
import org.jetbrains.compose.resources.stringResource

@Composable
fun GuideView(
    state: GuideContract.State,
    onEventSent: (GuideContract.Event) -> Unit
) {

    Column(modifier = Modifier.fillMaxSize()) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .background(RedDark)
                .weight(1f)
                .padding(8.dp),
            contentAlignment = Alignment.Center
        ) {
            ImageLoader(
                Res.drawable.shopping_animation,
                modifier = Modifier.fillMaxSize(),
                contentScale = ContentScale.FillHeight
            )

            Box(
                modifier = Modifier.fillMaxSize()
            ) {
                BackButton(onClick = { onEventSent(GuideContract.Event.OnBackClick) })
            }
        }
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .background(Color.White)
                .weight(1f)
                .padding(24.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Column(
                modifier = Modifier
                    .weight(1f)
                    .verticalScroll(rememberScrollState()),
                verticalArrangement = Arrangement.SpaceBetween
            ) {
                Tip(Res.string.guide_title1, Res.string.guide_message1, Icons.Default.Barcode)
                Tip(Res.string.guide_title2, Res.string.guide_message2, Icons.Default.ShoppingBag)
                Tip(Res.string.guide_title3, Res.string.guide_message3, Icons.Default.ShoppingCart)
            }
            ButtonDef(
                onClick = {
                    onEventSent(GuideContract.Event.OnNextClick)
                },
                modifier = Modifier.fillMaxWidth().padding(top = 24.dp)
            ) {
                Text(
                    text = stringResource(Res.string.guide_next),
                    fontWeight = FontWeight.Bold
                )
            }

        }
    }

    if (state.isLoading) {
        ProgressIndicator()
    }

    if (state.checkCameraPermission) {
        CameraPermissionIsGranted { isGranted ->
            onEventSent(GuideContract.Event.OnCameraPermissionIsGranted(isGranted))
        }
    }
}
