package com.mshopsas.enterprise.aiw.scan.screens.audit

import cafe.adriel.voyager.core.model.screenModelScope
import co.touchlab.kermit.Logger
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class AuditScreenModel(
    private val repository: Repository
) : BaseScreenModel<AuditContract.Event, AuditContract.State, AuditContract.Effect>() {

    override fun setInitialState() = AuditContract.State(isLoading = false, showPendingAuditory = false)

    override fun handleEvents(event: AuditContract.Event) {
        when (event) {
            AuditContract.Event.UpdateBasketStatus -> {
                updateStatus()
            }

            AuditContract.Event.OnPendingAuditoryClose -> {
                setState { copy(showPendingAuditory = false) }
            }
        }
    }

    private fun updateStatus() {
        setState { copy(isLoading = true) }
        screenModelScope.launch {
            repository.callUpdateBasketStatus().collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let {
                            Logger.d("Response $it")
                            when (it.result) {
                                Basket.AUDIT_REQUIRED -> {
                                    setState { copy(showPendingAuditory = true) }
                                }

                                else -> {
                                    setEffect { AuditContract.Effect.Navigation.ToPayments }
                                }
                            }
                            setState { copy(isLoading = false) }
                        }
                    }

                    Resource.Status.ERROR -> {
//                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }

//        setEffect { AuditContract.Effect.Navigation.ToBack }
    }

//    private fun onBackClick() {
//        setEffect { PaymentSelectionContract.Effect.Navigation.ToBack }
//    }

}
