package com.mshopsas.enterprise.aiw.scan.network.response

import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProductDetailsResponse(
    @SerialName("productBPLDetailDto")
    val productDetail: ProductDetail
)
