package com.mshopsas.enterprise.aiw.scan.utils

import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.format
import kotlinx.datetime.format.FormatStringsInDatetimeFormats
import kotlinx.datetime.format.byUnicodePattern
import kotlinx.datetime.toLocalDateTime

fun Basket.toQr(shopperIdentifier: String, store: String): String {
    val sg = "S&G"
    val version = "01"
    val now = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault())
    val date = now.toFormatString("yyyyMMdd")
    val time = now.toFormatString("HHmm")
    val client = shopperIdentifier.toFakeClientCode()
    val transaction = idBasket
    val products = mutableListOf<Pair<String, Int>>()

    cellList.forEach {
        it.itemList.forEach { item ->
            products.add(
                item.eanCode to item.quantity
            )
        }
    }

    if (!shopperIdentifier.startsWith("AN-")) {
        products.add(
            shopperIdentifier to 1
        )
    }

    return "$sg|$version|$store|$date|$time|$client|$transaction|${
        products.joinToString(separator = "|") {
            "${it.first}|${it.second.toString().padStart(2, '0')}"
        }
    }|"
}

private fun String.toFakeClientCode(): String {
    return when {
        startsWith("AN-") -> drop(6).dropLast(1)
        else -> drop(3).dropLast(1)
    }
}

@OptIn(FormatStringsInDatetimeFormats::class)
fun LocalDateTime.toFormatString(pattern: String): String {
    return format(LocalDateTime.Format {
        byUnicodePattern(pattern)
    })
}
