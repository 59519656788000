package com.mshopsas.enterprise.aiw.scan.screens.tutorial.intro

import cafe.adriel.voyager.core.model.screenModelScope
import co.touchlab.kermit.Logger
import com.benasher44.uuid.uuid4
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.CheckInRequest
import com.mshopsas.enterprise.aiw.scan.network.response.BasketStatusResponse
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class IntroScreenModel(
    private val repository: Repository
) : BaseScreenModel<IntroContract.Event, IntroContract.State, IntroContract.Effect>() {

    override fun setInitialState() = IntroContract.State(isLoading = false)

    override fun handleEvents(event: IntroContract.Event) {
        when (event) {
            IntroContract.Event.OnInit -> checkPersistence()
            IntroContract.Event.OnNextClick -> onNextClick()
        }
    }

    private fun onNextClick() {
        setEffect { IntroContract.Effect.Navigation.ToGuide }
    }

    private fun checkPersistence() {
        screenModelScope.launch {
            val persistence = repository.getPersistence()
            Logger.i { persistence.toString() }
            if (persistence.deviceId.isBlank()) {
                repository.setPersistence { copy(deviceId = uuid4().toString()) }
            }

            if (persistence.basketEncodedId.isNotBlank()) {
                updateStatus()
            }
        }
    }

    private fun updateStatus() {
        setState { copy(isLoading = true) }
        screenModelScope.launch {
            repository.callUpdateBasketStatus().collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let {
                            Logger.d("Response $it")
                            when (it.result) {
                                Basket.STARTED,
                                Basket.AUDIT_REQUIRED,
                                Basket.WAITING_TO_PAY -> onStarted(it)
                            }
                            setState { copy(isLoading = false) }
                        }
                    }

                    Resource.Status.ERROR -> {
                        setState { copy(isLoading = false) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

    private fun onStarted(basketResponse: BasketStatusResponse) {
        screenModelScope.launch {
            val persistence = repository.getPersistence()
            repository.setStore(persistence.store)
            callCheckIn(persistence.identifier, basketResponse)
        }
    }

    private suspend fun callCheckIn(identifier: String?, basketResponse: BasketStatusResponse) {
        repository.callCheckIn(
            identifier,
            if (identifier != null) CheckInRequest.LOYALTY else CheckInRequest.ANONYMOUS
        ).collect { result ->
            when (result.status) {
                Resource.Status.SUCCESS -> {
                    result.data?.let {
                        repository.setCheckIn(it, identifier)
                        repository.setBasket(it.currentBasket)
                        setState { copy(isLoading = false) }

                        when (basketResponse.result) {
                            Basket.STARTED -> setEffect { IntroContract.Effect.Navigation.ToHome }

                            Basket.WAITING_TO_PAY -> setEffect { IntroContract.Effect.Navigation.ToPayments }

                            Basket.AUDIT_REQUIRED -> setEffect {
                                IntroContract.Effect.Navigation.ToAudit(basketResponse.auditCode!!)
                            }
                        }
                    } ?: run {
                        setState { copy(isLoading = false) }
                    }
                }

                Resource.Status.ERROR -> {
                    setState { copy(isLoading = false) }
                }

                Resource.Status.LOADING -> {
                    setState { copy(isLoading = true) }
                }
            }
        }
    }

}
