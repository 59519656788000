package com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission

import cafe.adriel.voyager.core.model.screenModelScope
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.getParameters
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.CheckInRequest
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class CameraPermissionScreenModel(
    private val repository: Repository
) : BaseScreenModel<CameraPermissionContract.Event, CameraPermissionContract.State, CameraPermissionContract.Effect>() {

    override fun setInitialState() = CameraPermissionContract.State(
        askPermission = false,
        result = null,
        isLoading = false,
        message = ""
    )

    override fun handleEvents(event: CameraPermissionContract.Event) {
        when (event) {
            CameraPermissionContract.Event.OnBackClick -> onBackClick()
            CameraPermissionContract.Event.OnScanClick -> onScanClick()
            is CameraPermissionContract.Event.OnPermissionResult -> {
                if (event.result) {
                    setState { copy(askPermission = false) }
                    if (event.navigateToScanScreen) {
                        setEffect { CameraPermissionContract.Effect.Navigation.ToCamera }
                    } else {
                        val params = getParameters()
                        if (params.identifier != null) {
                            callCheckIn(params.identifier)
                        } else {
                            setEffect { CameraPermissionContract.Effect.Navigation.ToLoyalty }
                        }
                    }
                } else {
                    setEffect {
                        CameraPermissionContract.Effect.Navigation.ToPermissionDenied(
                            event.platform,
                            event.navigateToScanScreen
                        )
                    }
                }
            }

            CameraPermissionContract.Event.OnRequestPermissionClick,
            CameraPermissionContract.Event.OnSheetClose -> {
                setState { copy(askPermission = true) }
            }
        }
    }

    private fun onBackClick() {
        setEffect { CameraPermissionContract.Effect.Navigation.ToBack }
    }

    private fun onScanClick() {
        setEffect { CameraPermissionContract.Effect.Navigation.ToCamera }
    }

    private fun callCheckIn(identifier: String) {
        screenModelScope.launch {
            repository.callCheckIn(identifier, CheckInRequest.LOYALTY).collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let {
                            repository.setCheckIn(it, identifier)
                            repository.setBasket(it.currentBasket)
                            setState { copy(isLoading = false) }
                            setEffect { CameraPermissionContract.Effect.Navigation.ToHome }
                        } ?: run {
                            setState { copy(isLoading = false, message = "CheckIn error") }
                        }
                    }

                    Resource.Status.ERROR -> {
                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false, message = errorMsg) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

}
