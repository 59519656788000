package com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerType
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeScreen
import com.mshopsas.enterprise.aiw.scan.screens.scan.ScanScreen
import com.mshopsas.enterprise.aiw.scan.utils.getResult
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data object LoyaltyScreen : Screen {

    @Composable
    override fun Content() {
        val navigator = LocalNavigator.currentOrThrow
        val screenModel = getScreenModel<LoyaltyScreenModel>()

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    LoyaltyContract.Effect.Navigation.ToBack -> {
                        navigator.pop()
                    }
                    LoyaltyContract.Effect.Navigation.ToHome -> {
                        navigator.push(HomeScreen)
                    }
                    LoyaltyContract.Effect.Navigation.ToScan -> {
                        navigator.push(ScanScreen(listOf(ScannerType.EAN13)))
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (LoyaltyContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        LoyaltyView(state, onEventSent)

        navigator.getResult<ScannerData>(ScanScreen.SCAN_RESULT)?.let {
            onEventSent(LoyaltyContract.Event.OnResult(it.value))
        }

    }
}
