package com.mshopsas.enterprise.aiw.scan.screens.payment.ticket

import cafe.adriel.voyager.core.model.screenModelScope
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.home.composables.toMoney
import com.mshopsas.enterprise.aiw.scan.utils.toQr
import kotlinx.coroutines.launch

class TicketScreenModel(
    private val repository: Repository
) : BaseScreenModel<TicketContract.Event, TicketContract.State, TicketContract.Effect>() {

    override fun setInitialState() = TicketContract.State("", "" , "", listOf())

    override fun handleEvents(event: TicketContract.Event) {
//        when (event) {
//        }
    }

    init {
        initialize()
    }

    private fun initialize() {
        screenModelScope.launch {
            repository.getBasket().collect { basket ->
                setState {
                    copy(
                        qrData = basket.toQr(
                            repository.getCheckIn()?.shopperIdentifier ?: "",
                            repository.getStore()?.storeUuid ?: ""
                        ),
                        totalItems = basket.nbItems.toString(),
                        totalAmount = basket.amountTotal.toMoney(),
                        items = basket.generateTicketItems()
                    )
                }
            }
        }
        screenModelScope.launch {
            repository.callConfirmPayment().collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                    }

                    Resource.Status.ERROR -> {
                    }

                    Resource.Status.LOADING -> {
                    }
                }
            }
        }
    }

//    private fun onBackClick() {
//        setEffect { PaymentSelectionContract.Effect.Navigation.ToBack }
//    }

}
