package com.mshopsas.enterprise.aiw.scan.screens.tutorial.guide

import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class GuideContract {
    sealed interface Event : ViewEvent {
        data object OnNextClick : Event
        data object OnBackClick : Event
        data class OnCameraPermissionIsGranted(val isGranted: Boolean) : Event
    }

    data class State(
        val isLoading: Boolean,
        val message: String,
        val checkCameraPermission: Boolean,
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
            data object ToLocation : Navigation
            data object ToHome : Navigation
            data object ToLoyalty : Navigation
            data object ToCameraPermission : Navigation
        }
    }
}
