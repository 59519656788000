package com.mshopsas.enterprise.aiw.scan.screens.home.composables

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.home_aisle
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.LocationOn
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.SheetState
import androidx.compose.material3.Text
import androidx.compose.material3.rememberModalBottomSheetState
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.Item
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetailsPromo
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun PromoBottomSheet(
    item: Item,
    detailPromo: ProductDetailsPromo?,
    onDismiss: () -> Unit,
    bottomSheetState: SheetState = rememberModalBottomSheetState(skipPartiallyExpanded = false),
) {

    ModalBottomSheet(
        onDismissRequest = {
            onDismiss()
        },
        containerColor = Color.White,
        sheetState = bottomSheetState,
    ) {

        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(start = 32.dp, top = 0.dp, end = 32.dp, bottom = 32.dp),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(32.dp)
        ) {

            // Image and label
            Row {
                ImageLoader(
                    item.image ?: "",
                    modifier = Modifier.weight(1 / 4f).aspectRatio(4 / 4f)
                        .clip(RoundedCornerShape(4.dp))
                )

                Text(
                    text = item.productLabel ?: "",
                    style = MaterialTheme.typography.titleMedium,
                    fontSize = 17.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.weight(3 / 4f).aspectRatio(3 / 1f)
                        .padding(16.dp)
                )
            }

            if (item.aisle != null) {
                Row {
                    Image(Icons.Default.LocationOn, null)
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(stringResource(Res.string.home_aisle, item.aisle))
                }
            }

            PromoInfo(detailPromo)
        }
    }
}
