package com.mshopsas.enterprise.aiw.scan.data.basket

import kotlinx.serialization.Serializable

@Serializable
data class Cell(
    val typeCell: String,
    val typePromo: String?,
    val discount: Int,
    val groupPrice: Int,
    val groupAmount: Int,
    val amountDesc: String?,
    val promoInfo: PromoInfo?,
    val itemList: List<Item>
) {
    companion object {
        const val UNAVAILABLE = "UNAVAILABLE"
        const val NORMAL = "NORMAL"
    }
}
