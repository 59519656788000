package com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty

import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class LoyaltyContract {

    sealed interface Event : ViewEvent {
        data object OnBackClick : Event
        data object ToScan : Event
        data object OnAnonymousClick : Event
        data class OnResult(val result: ScannerData?) : Event

    }

    data class State(
        val message: String,
        val isLoading: Boolean
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
            data object ToHome : Navigation
            data object ToScan : Navigation
        }
    }
}
