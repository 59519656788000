package com.mshopsas.enterprise.aiw.scan.screens.home

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerType
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.audit.AuditScreen
import com.mshopsas.enterprise.aiw.scan.screens.brochure.BrochureScreen
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.historic.HistoryOrderScreen
import com.mshopsas.enterprise.aiw.scan.screens.payment.PaymentSelectionScreen
import com.mshopsas.enterprise.aiw.scan.screens.scan.ScanScreen
import com.mshopsas.enterprise.aiw.scan.screens.search.SearchProductScreen
import com.mshopsas.enterprise.aiw.scan.utils.getResult
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data object HomeScreen : Screen {

    @Composable
    override fun Content() {

        val navigator = LocalNavigator.currentOrThrow
        val screenModel: HomeScreenModel = getScreenModel()

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    HomeContract.Effect.Navigation.ToBack -> {
                        navigator.pop()
                    }
                    HomeContract.Effect.Navigation.ToScan -> {
                        navigator.push(ScanScreen(listOf(ScannerType.EAN13, ScannerType.EAN8)))
                    }
                    HomeContract.Effect.Navigation.ToPayments -> {
                        navigator.push(PaymentSelectionScreen)
                    }
                    is HomeContract.Effect.Navigation.ToAudit -> {
                        navigator.push(AuditScreen(it.auditCode))
                    }
                    HomeContract.Effect.Navigation.ToSearch -> {
                        navigator.push(SearchProductScreen)
                    }
                    is HomeContract.Effect.Navigation.ToBrochure -> {
                        navigator.push(BrochureScreen(it.url))
                    }
                    HomeContract.Effect.Navigation.ToHistoric -> {
                        navigator.push(HistoryOrderScreen)
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (HomeContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        HomeView(state, onEventSent)

        navigator.getResult<ScannerData>(ScanScreen.SCAN_RESULT)?.let {
            onEventSent(HomeContract.Event.OnResult(it.value))
        }
    }
}
