package com.mshopsas.enterprise.aiw.scan.screens.payment

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.apple_pay
import aiwscan.composeapp.generated.resources.bizum
import aiwscan.composeapp.generated.resources.cashier
import aiwscan.composeapp.generated.resources.google_pay
import aiwscan.composeapp.generated.resources.payment_selection_assistance_message
import aiwscan.composeapp.generated.resources.payment_selection_assistance_title
import aiwscan.composeapp.generated.resources.payment_selection_audit_message
import aiwscan.composeapp.generated.resources.payment_selection_audit_title
import aiwscan.composeapp.generated.resources.payment_selection_message1
import aiwscan.composeapp.generated.resources.payment_selection_update_status_text
import aiwscan.composeapp.generated.resources.visa_mastercard
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.screens.commons.okCancelBottomSheet.OkCancelBottomSheet
import com.mshopsas.enterprise.aiw.scan.screens.payment.composables.PaymentCell
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import com.mshopsas.enterprise.aiw.scan.utils.platform
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun PaymentSelectionView(
    state: PaymentSelectionContract.State,
    onEventSent: (PaymentSelectionContract.Event) -> Unit
) {
    MScanScaffold {
        Column(modifier = Modifier
            .fillMaxSize()
            .padding(it)
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(RedDark)
                    .padding(6.dp),
                contentAlignment = Alignment.TopCenter
            ) {
                Text(
                    modifier = Modifier
                        .padding(12.dp)
                        .fillMaxWidth()
                        .wrapContentHeight(align = Alignment.CenterVertically),
                    text = "Selecciona una forma de pago",
                    fontSize = 24.sp,
                    color = Color.White,
                    textAlign = TextAlign.Center
                )
            }
            Column(modifier = Modifier
                .fillMaxSize()
                .padding(6.dp)
                .verticalScroll(rememberScrollState())
            ) {
                if(platform == "IPHONE") {
                    PaymentCell(Res.drawable.apple_pay, "Apple Pay") {
                        onEventSent(PaymentSelectionContract.Event.OnApplePay)
                    }
                } else if(platform == "ANDROID") {
                    PaymentCell(Res.drawable.google_pay, "Google Pay") {
                        onEventSent(PaymentSelectionContract.Event.OnGooglePay)
                    }
                }
                PaymentCell(Res.drawable.bizum, "Bizum") {
                    onEventSent(PaymentSelectionContract.Event.OnBizumPay)
                }
    //            HorizontalDivider()
                PaymentCell(Res.drawable.visa_mastercard, "Visa / MasterCard") {
                    onEventSent(PaymentSelectionContract.Event.OnCardPay)
                }
    //            HorizontalDivider()
                PaymentCell(Res.drawable.cashier, "Pago en caja") {
                    onEventSent(PaymentSelectionContract.Event.OnCashierPay)
                }
            }
        }
    }
    if (state.showExplanation) {
        OkCancelBottomSheet(
            message = stringResource(Res.string.payment_selection_message1),
            onOK = { onEventSent(PaymentSelectionContract.Event.OnSheetClose) },
            onDismiss = { onEventSent(PaymentSelectionContract.Event.OnSheetClose) }
        )
    }
    if (state.showAuditRequired) {
        OkCancelBottomSheet(
            title = stringResource(Res.string.payment_selection_audit_title),
            message = stringResource(Res.string.payment_selection_audit_message),
            okText = stringResource(Res.string.payment_selection_update_status_text),
            onOK = { onEventSent(PaymentSelectionContract.Event.OnAuditSheetClose) },
            onDismiss = { onEventSent(PaymentSelectionContract.Event.OnAuditSheetClose) }
        )
    }
    if (state.showAssitanceRequired) {
        OkCancelBottomSheet(
            title = stringResource(Res.string.payment_selection_assistance_title),
            message = stringResource(Res.string.payment_selection_assistance_message),
            okText = stringResource(Res.string.payment_selection_update_status_text),
            onOK = { onEventSent(PaymentSelectionContract.Event.OnAuditSheetClose) },
            onDismiss = { onEventSent(PaymentSelectionContract.Event.OnAuditSheetClose) }
        )
    }
}
