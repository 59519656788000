package com.mshopsas.enterprise.aiw.scan.screens.tutorial.intro

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.intro_message
import aiwscan.composeapp.generated.resources.intro_next
import aiwscan.composeapp.generated.resources.intro_welcome
import aiwscan.composeapp.generated.resources.welcome
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.ProgressIndicator
import com.mshopsas.enterprise.aiw.scan.theme.BlueGrey
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.resources.vectorResource

@Composable
fun IntroView(
    state: IntroContract.State,
    onEventSent: (IntroContract.Event) -> Unit
) {

    LaunchedEffect("Init") {
        onEventSent(IntroContract.Event.OnInit)
    }

    Column(modifier = Modifier.fillMaxSize()) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .background(RedDark)
                .weight(1f)
                .padding(32.dp),
            contentAlignment = Alignment.TopCenter
        ) {
            Image(
                imageVector = vectorResource(Res.drawable.welcome),
                contentDescription = null,
                modifier = Modifier.fillMaxHeight()
            )
        }
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .background(Color.White)
                .weight(1f)
                .padding(24.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Text(
                text = stringResource(Res.string.intro_welcome),
                fontSize = 28.sp,
                color = BlueGrey
            )

            Spacer(modifier = Modifier.height(16.dp))

            Text(
                text = stringResource(Res.string.intro_message),
                fontSize = 18.sp,
                modifier = Modifier.weight(1f)
            )

            Spacer(modifier = Modifier.height(16.dp))

            ButtonDef(
                onClick = {
                    onEventSent(IntroContract.Event.OnNextClick)
                },
                modifier = Modifier.fillMaxWidth()
            ) {
                Text(
                    text = stringResource(Res.string.intro_next),
                    fontWeight = FontWeight.Bold
                )
            }
        }
    }

    if (state.isLoading) {
        ProgressIndicator()
    }
}
