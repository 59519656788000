package com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission

import androidx.compose.runtime.Composable
import co.touchlab.kermit.Logger

external fun getCameraPermission(callback: (Boolean, String) -> Unit)
external fun isCameraPermissionGranted(callback: (Boolean) -> Unit)

@Composable
actual fun CameraPermission(callback: (Boolean, String) -> Unit) {
    getCameraPermission { granted, userAgent ->
        callback(granted, userAgent)
    }
}

@Composable
actual fun CameraPermissionIsGranted(callback: (Boolean) -> Unit) {
    try {
        isCameraPermissionGranted {
            callback(it)
        }
    } catch (e: Exception) {
        Logger.e { e.toString() }
    }
}
