package com.mshopsas.enterprise.aiw.scan.screens.brochure

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.mshopsas.enterprise.aiw.scan.screens.payment.mpay.hideWebSite
import com.mshopsas.enterprise.aiw.scan.screens.payment.mpay.loadHtml
import com.mshopsas.enterprise.aiw.scan.screens.payment.mpay.showWebSite


external fun showWebSite(url: String)
external fun loadHtml(html: String)
external fun hideWebSite()

@Composable
actual fun BrochureWebView(
    url: String,
    onBackPressed: Boolean,
    callback: (String?) -> Unit
) {

    LaunchedEffect("BrochureWebView") {
        if(url.startsWith("http")) {
            showWebSite(url)
        } else {
            loadHtml(url)
        }
    }

    if (onBackPressed) {
        hideWebSite()
        callback(null)
    }

}