package com.mshopsas.enterprise.aiw.scan.data.basket

import kotlinx.serialization.Serializable

@Serializable
data class HistoryOrder(
    val id: String,
    val store: FavouriteStore,
    val dateValidation: String,
    val total: String,
    val productsCount: String,
    val priceDivision: Double,
    val numDecimals: Int
)