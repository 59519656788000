package com.mshopsas.enterprise.aiw.scan.screens.tutorial.intro

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.audit.AuditScreen
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeScreen
import com.mshopsas.enterprise.aiw.scan.screens.payment.PaymentSelectionScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.guide.GuideScreen
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data object IntroScreen : Screen {

    @Composable
    override fun Content() {

        val screenModel = getScreenModel<IntroScreenModel>()
        val navigator = LocalNavigator.currentOrThrow

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    IntroContract.Effect.Navigation.ToGuide -> {
                        navigator.push(GuideScreen)
                    }

                    IntroContract.Effect.Navigation.ToHome -> {
                        navigator.push(HomeScreen)
                    }

                    IntroContract.Effect.Navigation.ToPayments -> {
                        navigator.push(PaymentSelectionScreen)
                    }

                    is IntroContract.Effect.Navigation.ToAudit -> {
                        navigator.push(AuditScreen(it.auditCode))
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (IntroContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        IntroView(state, onEventSent)

    }
}
