package com.mshopsas.enterprise.aiw.scan.screens.payment.ticket.composables

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.ticket.TicketItem

@Composable
fun TicketItemView(ticketItem: TicketItem) {
    Column(
        modifier = Modifier.padding(6.dp),
        verticalArrangement = Arrangement.Center
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Text(
                text = ticketItem.article.uppercase(),
                modifier = Modifier.weight(0.5f).align(Alignment.CenterVertically),
                fontSize = 12.sp,
                textAlign = TextAlign.Left
            )
            Text(
                text = ticketItem.price,
                modifier = Modifier.weight(0.2f).align(Alignment.CenterVertically),
                fontSize = 12.sp,
                textAlign = TextAlign.Right
            )
            Text(
                text = ticketItem.quantity.toString(),
                modifier = Modifier.weight(0.1f).align(Alignment.CenterVertically),
                fontSize = 12.sp,
                textAlign = TextAlign.Right
            )
            Text(
                text = ticketItem.total,
                modifier = Modifier.weight(0.2f).align(Alignment.CenterVertically),
                fontSize = 12.sp,
                textAlign = TextAlign.Right
            )
        }
        ticketItem.discount?.let {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 3.dp),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Text(
                    text = "Descuento",
                    modifier = Modifier.weight(0.5f),
                    fontSize = 12.sp,
                    fontStyle = FontStyle.Italic,
                    textAlign = TextAlign.Right
                )
                Text(
                    text = it.total,
                    modifier = Modifier.weight(0.3f),
                    fontSize = 12.sp,
                    fontStyle = FontStyle.Italic,
                    textAlign = TextAlign.Right
                )
            }
        }
    }
}