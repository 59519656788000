package com.mshopsas.enterprise.aiw.scan.screens.home.composables

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.home_aisle
import aiwscan.composeapp.generated.resources.home_aisle_order
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.LocationOn
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import org.jetbrains.compose.resources.stringResource

@Composable
fun ProductRelatedMiniItem(product: ProductDetail, onItemClick: (ProductDetail) -> Unit) {
    Row(
        modifier = Modifier.fillMaxWidth().clickable {
            onItemClick(product)
        },
        verticalAlignment = Alignment.CenterVertically
    ) {

        Box(modifier = Modifier.weight(1 / 4f).aspectRatio(4 / 4f)) {
            ImageLoader(product.imageMainName ?: "")
        }

        Column(
            modifier = Modifier.weight(3 / 4f).wrapContentHeight()
                .padding(start = 16.dp, end = 16.dp, top = 8.dp, bottom = 8.dp),
            verticalArrangement = Arrangement.spacedBy(3.dp)
        ) {
            Text(
                text = product.nameLine1,
                style = MaterialTheme.typography.titleMedium,
                fontSize = 16.sp,
                fontWeight = FontWeight.Bold,
                maxLines = 2
            )
            Text(
                text = product.nameLine2 ?: "",
                style = MaterialTheme.typography.titleMedium,
                fontSize = 14.sp,
                fontWeight = FontWeight.Normal,
                maxLines = 1
            )

            if (product.aisle != null) {
                Row {
                    Image(Icons.Default.LocationOn, null)
                    Spacer(modifier = Modifier.width(4.dp))
                    Text(stringResource(Res.string.home_aisle, product.aisle), fontSize = 14.sp)
                    if(product.aisleOrder != null) {
                        Text(stringResource(Res.string.home_aisle_order, product.aisleOrder), fontSize = 14.sp)
                    }
                }
            }

            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Bottom,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Price(product.finalUnitPrice ?: 0, false)
//                Row {
//                    Spacer(modifier = Modifier.width(4.dp))
//                    Text(
//                        text = product.labelMeasureUnitPrice ?: "",
//                        fontSize = 24.sp
//                    )
//                }
            }
        }
    }
}
