package com.mshopsas.enterprise.aiw.scan.screens.audit

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.payment.PaymentSelectionScreen
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data class AuditScreen(val auditCode: String): Screen {

    @Composable
    override fun Content() {

        val navigator = LocalNavigator.currentOrThrow
        val screenModel = getScreenModel<AuditScreenModel>()

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    AuditContract.Effect.Navigation.ToPayments -> {
                        navigator.push(PaymentSelectionScreen)
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (AuditContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        AuditView(state, onEventSent, auditCode)

    }
}
