package com.mshopsas.enterprise.aiw.scan.network.response

import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import com.mshopsas.enterprise.aiw.scan.data.basket.Cell
import kotlinx.serialization.Serializable

@Serializable
data class SetQuantityResponse(
    val basket: Basket,
    val errorProducts: List<Cell>
)
