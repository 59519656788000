package com.mshopsas.enterprise.aiw.scan.network.request

import com.mshopsas.enterprise.aiw.scan.data.basket.Item
import kotlinx.serialization.Serializable

@Serializable
data class SetQuantityItemRequest(
    val eanCode: String,
    val idBasketItem: Long,
    val quantity: Int
) {
    companion object {
        fun fromScan(ean: String) = SetQuantityItemRequest(ean, -1, 1)

        fun fromEdit(item: Item) =
            SetQuantityItemRequest(item.eanCode, item.idBasketItem, item.quantity)
    }
}
