package com.mshopsas.enterprise.aiw.scan.data

import aiwscan.composeapp.generated.resources.Res
import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import com.mshopsas.enterprise.aiw.scan.data.basket.CompareList
import com.mshopsas.enterprise.aiw.scan.data.basket.EndBasket
import com.mshopsas.enterprise.aiw.scan.network.ApiError
import com.mshopsas.enterprise.aiw.scan.network.ErrorCode
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.CheckInRequest
import com.mshopsas.enterprise.aiw.scan.network.request.ConfirmPaymentRequest
import com.mshopsas.enterprise.aiw.scan.network.request.EndBasketRequest
import com.mshopsas.enterprise.aiw.scan.network.request.LinkLoyaltyRequest
import com.mshopsas.enterprise.aiw.scan.network.request.LocateStoreRequest
import com.mshopsas.enterprise.aiw.scan.network.request.ProductDetailsRequest
import com.mshopsas.enterprise.aiw.scan.network.request.ProductRelatedRequest
import com.mshopsas.enterprise.aiw.scan.network.request.ProductSearchRequest
import com.mshopsas.enterprise.aiw.scan.network.request.SetQuantityItemRequest
import com.mshopsas.enterprise.aiw.scan.network.request.SetQuantityRequest
import com.mshopsas.enterprise.aiw.scan.network.response.BasketStatusResponse
import com.mshopsas.enterprise.aiw.scan.network.response.ConfirmPaymentResponse
import com.mshopsas.enterprise.aiw.scan.network.response.HistoricDetailResponse
import com.mshopsas.enterprise.aiw.scan.network.response.HistoricOrderResponse
import com.mshopsas.enterprise.aiw.scan.network.response.LinkLoyaltyResponse
import com.mshopsas.enterprise.aiw.scan.network.response.LoyaltyData
import com.mshopsas.enterprise.aiw.scan.network.response.OFFProductResponse
import com.mshopsas.enterprise.aiw.scan.network.response.ProductDetailsResponse
import com.mshopsas.enterprise.aiw.scan.network.response.ProductRelatedResponse
import com.mshopsas.enterprise.aiw.scan.network.response.ProductSearchResponse
import com.mshopsas.enterprise.aiw.scan.network.response.SetQuantityResponse
import com.mshopsas.enterprise.aiw.scan.utils.platform
import com.mshopsas.enterprise.aiw.scan.utils.player.MediaPlayerController
import com.mshopsas.enterprise.aiw.scan.utils.player.MediaPlayerListener
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.update
import kotlinx.serialization.json.Json
import org.jetbrains.compose.resources.ExperimentalResourceApi

class RepositoryImpl(
    private val httpClient: HttpClient,
    private val persistenceService: PersistenceService,
    private val mediaPlayerController: MediaPlayerController
) : Repository {

    private var store: Store? = null
    private var checkIn: CheckIn? = null

    private val basket: MutableStateFlow<Basket> =
        MutableStateFlow(Basket(0,0, "", 0, listOf(), 0, ""))

    private val compareList: MutableStateFlow<CompareList?> = MutableStateFlow(null)

    private val candidateList: MutableList<String> = mutableListOf()

    override suspend fun setStore(store: Store?) {
        this.store = store
        setPersistence { copy(store = store) }
    }

    override fun getStore(): Store? = this.store

    override fun callStoreByLocation(
        longitude: Double,
        latitude: Double
    ): Flow<Resource<Store?>> {
        return makeSafeApiCall {
            val request = LocateStoreRequest(
                typeLocation = LocateStoreRequest.COORDINATES,
                longitude = longitude,
                latitude = latitude
            )
            val response = httpClient.post(STORE_LOCATE) {
                setBody(request)
            }.body<Store?>()
            Resource.success(response)
        }
    }

    override fun callStoreByQR(qrValue: String): Flow<Resource<Store?>> {
        return makeSafeApiCall {
            val request = LocateStoreRequest(
                typeLocation = LocateStoreRequest.QR_CODE,
                qrCode = qrValue
            )
            val response = httpClient.post(STORE_LOCATE) {
                setBody(request)
            }.body<Store?>()
            Resource.success(response)
        }
    }

    override fun callCheckIn(
        identifier: String?, identifierType: String
    ): Flow<Resource<CheckIn?>> {
        return makeSafeApiCall {
            val request = CheckInRequest(
                identifier = identifier,
                identifierType = identifierType,
                lang = "es",
                storeUuid = store?.storeUuid ?: "",
                terminalIdentifier = getPersistence().deviceId,
                platform = platform
            )
            val response = httpClient.post(CHECK_IN) {
                setBody(request)
            }.body<CheckIn?>()
            Resource.success(response)
        }
    }

    override fun callSetQuantity(item: SetQuantityItemRequest?): Flow<Resource<SetQuantityResponse?>> {
        return makeSafeApiCall {
            val request = SetQuantityRequest(
                shopperEncodedId = checkIn?.encodeID ?: "",
                storeUuid = store?.storeUuid ?: "",
                basketEncodedId = basket.value.idBasketEncoded,
                itemList = item?.let { listOf(item) } ?: listOf()
            )
            val response = httpClient.post(BASKET_SET_QUANTITY) {
                setBody(request)
            }.body<SetQuantityResponse?>()
            Resource.success(response)
        }
    }

    override fun callProductDetails(eanCode: String): Flow<Resource<ProductDetailsResponse?>> {
        return makeSafeApiCall {
            val request = ProductDetailsRequest(
                shopperEncodedId = checkIn?.encodeID ?: "",
                storeUuid = store?.storeUuid ?: "",
                basketEncodedId = basket.value.idBasketEncoded,
                eanCode = eanCode
            )
            val response = httpClient.post(PRODUCT_DETAILS) {
                setBody(request)
            }.body<ProductDetailsResponse?>()
            Resource.success(response)
        }
    }

    override fun callOff(eanCode: String): Flow<Resource<OFFProductResponse?>> {
        return makeSafeApiCall {
            val response = httpClient.get(offUrl(eanCode)).body<OFFProductResponse?>()
            Resource.success(response)
        }
    }

    override fun callProductRelated(eanCode: String, quantity: Int, mocked: Boolean): Flow<Resource<ProductRelatedResponse?>> {
        if(!mocked) {
            return makeSafeApiCall {
                val request = ProductRelatedRequest(
                    uuidStore = store?.storeUuid ?: "",
                    eanCode = eanCode,
                    limit = quantity
                )
                val response = httpClient.post(PRODUCT_RELATED) {
                    setBody(request)
                }.body<ProductRelatedResponse?>()
                Resource.success(response)
            }
        } else {
            return makeSafeApiCall {
                val response = Json {ignoreUnknownKeys = true}.decodeFromString<ProductRelatedResponse>(MOCKED_PRODUCT_RELATED)
                Resource.success(
                    response
                )
            }
        }
    }

    override fun callLinkLoyalty(shopperLoyaltyCard: String): Flow<Resource<LinkLoyaltyResponse?>> {
        return makeSafeApiCall {
            val request = LinkLoyaltyRequest(
                encodedShopperId = checkIn?.encodeID ?: "",
                storeIdentifier = store?.storeUuid ?: "",
                shopperLoyaltyCard = shopperLoyaltyCard
            )
            val response = httpClient.post(LINK_LOYALTY) {
                setBody(request)
            }.body<LinkLoyaltyResponse?>()
            Resource.success(response)
        }
    }

    override fun callConfirmPayment(): Flow<Resource<ConfirmPaymentResponse?>> {
        return makeSafeApiCall {
            val request = ConfirmPaymentRequest(
                basketEncodedId = basket.value.idBasketEncoded,
            )
            val response = httpClient.post(BASKET_CONFIRM_PAYMENT) {
                setBody(request)
            }.body<ConfirmPaymentResponse?>()
            Resource.success(response)
        }
    }

    override fun callUpdateBasketStatus(): Flow<Resource<BasketStatusResponse?>> {
        return makeSafeApiCall {
            val basketEncodedId = getPersistence().basketEncodedId
            val response = httpClient.get(BASKET_STATUS) {
                url {
                    parameters.append("basketEncodedId", basketEncodedId)
                }
            }.body<BasketStatusResponse?>()
            Resource.success(response)
        }
    }

    override fun callProductSearch(mainSearch: String): Flow<Resource<ProductSearchResponse?>> {
        return makeSafeApiCall {
            val request = ProductSearchRequest(
                shopperEncodedId = checkIn?.encodeID ?: "",
                storeUuid = store?.storeUuid ?: "",
                basketEncodedId = basket.value.idBasketEncoded,
                mainSearch = mainSearch
            )
            val response = httpClient.post(PRODUCT_SEARCH) {
                setBody(request)
            }.body<ProductSearchResponse?>()
            Resource.success(response)
        }
    }

    override fun callEndBasket(): Flow<Resource<EndBasket?>> {
        return makeSafeApiCall {
            val request = EndBasketRequest(
                shopperEncodedId = checkIn?.encodeID ?: "",
                storeUuid = store?.storeUuid ?: "",
                basketEncodedId = basket.value.idBasketEncoded
            )
            val response = httpClient.post(BASKET_END_TRIP) {
                setBody(request)
            }.body<EndBasket?>()
            Resource.success(response)
        }
    }

    override fun callListOfHistoric(limit: Int): Flow<Resource<HistoricOrderResponse?>> {
        return makeSafeApiCall {
            val response = httpClient.get(HISTORIC_ORDER)  {
                url {
                    parameters.append("shopperEncodedId", checkIn?.encodeID ?: "")
                    parameters.append("limit", limit.toString())
                }
            }.body<HistoricOrderResponse?>()
            Resource.success(response)
        }
    }

    override fun callGetHistoricDetail(basketId: String): Flow<Resource<HistoricDetailResponse?>> {
        return makeSafeApiCall {
            val response = httpClient.get(HISTORIC_DETAIL) {
                url {
                    parameters.append("basketId", basketId)
                }
            }.body<HistoricDetailResponse?>()
            Resource.success(response)
        }
    }

    override suspend fun getPersistence(): Persistence {
        return persistenceService.get()
    }

    override suspend fun setPersistence(reducer: Persistence.() -> Persistence) {
        persistenceService.set(reducer)
    }

    override fun setBasket(basket: Basket) {
        this.basket.update { basket }
    }

    override fun getBasket() = basket

    override fun setCompareList(compareList: CompareList?) {
        this.compareList.update { compareList }
    }

    override fun getCompareList() = compareList

    override suspend fun setCheckIn(checkIn: CheckIn, identifier: String?) {
        this.checkIn = checkIn
        setPersistence {
            copy(
                basketEncodedId = checkIn.currentBasket.idBasketEncoded,
                identifier = identifier
            )
        }
    }

    override fun getCheckIn() = checkIn

    override fun saveLoyaltyData(data: LoyaltyData) {
        checkIn?.let { c ->
            checkIn = c.copy(
                encodeID = data.encodedId,
                username = data.name,
                langConfig = c.langConfig.copy(userLang = data.lang)
            )
        }
    }

    override fun getCandidateList(): List<String> {
        return candidateList
    }

    override fun addCandidate(code: String) {
        candidateList.add(code)
    }

    override fun clearCandidateList() {
        candidateList.clear()
    }

    @OptIn(ExperimentalResourceApi::class)
    override fun playAlert() {
        val sound = Res.getUri("files/alert.mp3")
        playUri(sound)
    }

    @OptIn(ExperimentalResourceApi::class)
    override fun playError() {
        val sound = Res.getUri("files/error.mp3")
        playUri(sound)
    }

    private fun playUri(uri: String) {
        mediaPlayerController.prepare(uri, listener = object : MediaPlayerListener {
            override fun onReady() {
                mediaPlayerController.start()
            }

            override fun onAudioCompleted() {
                // Do nothing
            }

            override fun onError() {
                // Do nothing
            }
        })
    }

    private inline fun <reified T> makeSafeApiCall(
        crossinline api: suspend () -> Resource<T?>
    ) = flow {

        emit(Resource.loading())
        try {
            val response = api.invoke()
            val data = response.data

            emit(Resource.success(data))

        } catch (ex: Exception) {
            emit(
                Resource.error(
                    error = ApiError(
                        code = ErrorCode.NETWORK_CONNECTION_FAILED,
                        message = ex.message
                    )
                )
            )
        }
    }

    companion object {
        const val CHECK_IN = "mecom/customerMobile/checkIn"
        const val LINK_LOYALTY = "mecom/customerMobile/link/loyalty"
        const val BASKET_SET_QUANTITY = "mecom/customerMobile/basket/setQuantity"
        const val BASKET_END_TRIP = "mecom/customerMobile/basket/end"
        const val STORE_LOCATE = "mecom/customerMobile/store/locate"
        const val BASKET_CONFIRM_PAYMENT = "mecom/customerMobile/basket/confirmPayment"
        const val PRODUCT_DETAILS = "mecom/customerMobile/product/detail"
        const val BASKET_STATUS = "mecom/customerMobile/basket/status"
        const val PRODUCT_SEARCH = "mecom/customerMobile/product/search"
        const val PRODUCT_RELATED = "mecom/customerMobile/product/getProductsTopSalesByFamily"
        const val HISTORIC_ORDER = "mecom/customerMobile/basket/getListOfHistorics"
        const val HISTORIC_DETAIL = "mecom/customerMobile/basket/historicDetails"
        fun offUrl(barcode: String) =
            "https://world.openfoodfacts.org/api/v0/product/${barcode}.json"

        const val MOCKED_PRODUCT_RELATED = """
            {
    "productList": [
        {
            "productId": 1779113,
            "eanCode": "8414452021087",
            "gtinCode": "00529569",
            "nameLine1": "Pan escamas \"Crumbs\" Panko 200 g.",
            "nameLine2": "SANTA RITA Pan escamas \"Crumbs\" Panko 200 g.",
            "origin": null,
            "finalUnitPrice": 389,
            "originalUnitPrice": 389,
            "originalPriceDate": null,
            "showOriginalPrice": false,
            "measureUnitPrice": 389,
            "measure": null,
            "imageMainName": "https://www.alcampo.es/media/hc6/h9e/10853424562206.jpg",
            "imageMainNameZoom": null,
            "stock": "100",
            "labelMeasureUnitPrice": null,
            "cornerCode": "NEW",
            "freshFrozenCode": "NONE",
            "tagImageCodeList": [],
            "promoInfo": null,
            "discountImageCodeList": [],
            "specialImageCode": "NONE",
            "specialImageText": null,
            "basketItemQuantity": 0,
            "idBasket": null,
            "idBasketItem": null,
            "relatedText": null,
            "idCat": 0,
            "idFamily": 230490,
            "idMarket": 0,
            "descCat": null,
            "descFamily": null,
            "descMarket": null,
            "relatedProduct": null,
            "online": true,
            "recipeId": 0,
            "recipeImageSmall": null,
            "aisle": null,
            "aisleOrder": null,
            "inRecipe": false,
            "related": false,
            "finalUnitPriceDec": 3.89,
            "finalUnitPriceDecPart1": "3",
            "finalUnitPriceDecPart2": "89",
            "originalUnitPriceDec": 3.89,
            "originalUnitPriceDecPart1": "3",
            "originalUnitPriceDecPart2": "89",
            "measureUnitPriceDec": 3.89,
            "measureUnitPriceDecPart1": "3",
            "measureUnitPriceDecPart2": "89"
        },
        {
            "productId": 1624794,
            "eanCode": "8414509200021",
            "gtinCode": "00050997",
            "nameLine1": "Pan rallado 500 g",
            "nameLine2": "MOLDIPAN Pan rallado bolsa de 500 g.",
            "origin": null,
            "finalUnitPrice": 69,
            "originalUnitPrice": 69,
            "originalPriceDate": null,
            "showOriginalPrice": false,
            "measureUnitPrice": 69,
            "measure": null,
            "imageMainName": "https://www.alcampo.es/media/h8e/h03/11621752635422.jpg",
            "imageMainNameZoom": null,
            "stock": "100",
            "labelMeasureUnitPrice": null,
            "cornerCode": "NEW",
            "freshFrozenCode": "NONE",
            "tagImageCodeList": [],
            "promoInfo": null,
            "discountImageCodeList": [],
            "specialImageCode": "NONE",
            "specialImageText": null,
            "basketItemQuantity": 0,
            "idBasket": null,
            "idBasketItem": null,
            "relatedText": null,
            "idCat": 0,
            "idFamily": 230490,
            "idMarket": 0,
            "descCat": null,
            "descFamily": null,
            "descMarket": null,
            "relatedProduct": null,
            "online": true,
            "recipeId": 0,
            "recipeImageSmall": null,
            "aisle": null,
            "aisleOrder": null,
            "inRecipe": false,
            "related": false,
            "finalUnitPriceDec": 0.69,
            "finalUnitPriceDecPart1": "0",
            "finalUnitPriceDecPart2": "69",
            "originalUnitPriceDec": 0.69,
            "originalUnitPriceDecPart1": "0",
            "originalUnitPriceDecPart2": "69",
            "measureUnitPriceDec": 0.69,
            "measureUnitPriceDecPart1": "0",
            "measureUnitPriceDecPart2": "69"
        },
        {
            "productId": 1665277,
            "eanCode": "8411145201726",
            "gtinCode": "00029440",
            "nameLine1": "Picada (pan) para rebozar 225 g.",
            "nameLine2": "QUELY Picada para rebozar (Galletas de Inca molidas) 225 g.",
            "origin": null,
            "finalUnitPrice": 117,
            "originalUnitPrice": 117,
            "originalPriceDate": null,
            "showOriginalPrice": false,
            "measureUnitPrice": 117,
            "measure": null,
            "imageMainName": "https://www.alcampo.es/media/h77/he7/9350366756894.jpg",
            "imageMainNameZoom": null,
            "stock": "100",
            "labelMeasureUnitPrice": null,
            "cornerCode": "NEW",
            "freshFrozenCode": "NONE",
            "tagImageCodeList": [],
            "promoInfo": null,
            "discountImageCodeList": [],
            "specialImageCode": "NONE",
            "specialImageText": null,
            "basketItemQuantity": 0,
            "idBasket": null,
            "idBasketItem": null,
            "relatedText": null,
            "idCat": 0,
            "idFamily": 230490,
            "idMarket": 0,
            "descCat": null,
            "descFamily": null,
            "descMarket": null,
            "relatedProduct": null,
            "online": true,
            "recipeId": 0,
            "recipeImageSmall": null,
            "aisle": null,
            "aisleOrder": null,
            "inRecipe": false,
            "related": false,
            "finalUnitPriceDec": 1.17,
            "finalUnitPriceDecPart1": "1",
            "finalUnitPriceDecPart2": "17",
            "originalUnitPriceDec": 1.17,
            "originalUnitPriceDecPart1": "1",
            "originalUnitPriceDecPart2": "17",
            "measureUnitPriceDec": 1.17,
            "measureUnitPriceDecPart1": "1",
            "measureUnitPriceDecPart2": "17"
        },
        {
            "productId": 1940650,
            "eanCode": "8410294000174",
            "gtinCode": "00804840",
            "nameLine1": "Pan rallado 750 g.",
            "nameLine2": "PRODUCTO ALCAMPO Pan rallado 750 g.",
            "origin": null,
            "finalUnitPrice": 89,
            "originalUnitPrice": 89,
            "originalPriceDate": null,
            "showOriginalPrice": false,
            "measureUnitPrice": 89,
            "measure": null,
            "imageMainName": "https://www.alcampo.es/media/h25/haa/12324430643230.jpg",
            "imageMainNameZoom": null,
            "stock": "100",
            "labelMeasureUnitPrice": null,
            "cornerCode": "NEW",
            "freshFrozenCode": "NONE",
            "tagImageCodeList": [],
            "promoInfo": null,
            "discountImageCodeList": [],
            "specialImageCode": "NONE",
            "specialImageText": null,
            "basketItemQuantity": 0,
            "idBasket": null,
            "idBasketItem": null,
            "relatedText": null,
            "idCat": 0,
            "idFamily": 230490,
            "idMarket": 0,
            "descCat": null,
            "descFamily": null,
            "descMarket": null,
            "relatedProduct": null,
            "online": true,
            "recipeId": 0,
            "recipeImageSmall": null,
            "aisle": null,
            "aisleOrder": null,
            "inRecipe": false,
            "related": false,
            "finalUnitPriceDec": 0.89,
            "finalUnitPriceDecPart1": "0",
            "finalUnitPriceDecPart2": "89",
            "originalUnitPriceDec": 0.89,
            "originalUnitPriceDecPart1": "0",
            "originalUnitPriceDecPart2": "89",
            "measureUnitPriceDec": 0.89,
            "measureUnitPriceDecPart1": "0",
            "measureUnitPriceDecPart2": "89"
        },
        {
            "productId": 2005376,
            "eanCode": "8410294201052",
            "gtinCode": "00019600",
            "nameLine1": "Pan rallado con ajo y perejil 500 g",
            "nameLine2": "PRODUCTO ALCAMPO Pan rallado con ajo y perejil 500 g.",
            "origin": null,
            "finalUnitPrice": 98,
            "originalUnitPrice": 98,
            "originalPriceDate": null,
            "showOriginalPrice": false,
            "measureUnitPrice": 98,
            "measure": null,
            "imageMainName": "https://www.alcampo.es/media/h13/h82/12324345610270.jpg",
            "imageMainNameZoom": null,
            "stock": "100",
            "labelMeasureUnitPrice": null,
            "cornerCode": "NEW",
            "freshFrozenCode": "NONE",
            "tagImageCodeList": [],
            "promoInfo": null,
            "discountImageCodeList": [],
            "specialImageCode": "NONE",
            "specialImageText": null,
            "basketItemQuantity": 0,
            "idBasket": null,
            "idBasketItem": null,
            "relatedText": null,
            "idCat": 0,
            "idFamily": 230490,
            "idMarket": 0,
            "descCat": null,
            "descFamily": null,
            "descMarket": null,
            "relatedProduct": null,
            "online": true,
            "recipeId": 0,
            "recipeImageSmall": null,
            "aisle": null,
            "aisleOrder": null,
            "inRecipe": false,
            "related": false,
            "finalUnitPriceDec": 0.98,
            "finalUnitPriceDecPart1": "0",
            "finalUnitPriceDecPart2": "98",
            "originalUnitPriceDec": 0.98,
            "originalUnitPriceDecPart1": "0",
            "originalUnitPriceDecPart2": "98",
            "measureUnitPriceDec": 0.98,
            "measureUnitPriceDecPart1": "0",
            "measureUnitPriceDecPart2": "98"
        },
        {
            "productId": 2504,
            "eanCode": "8410069010568",
            "gtinCode": "00019638",
            "nameLine1": "Pan rallado 500g",
            "nameLine2": "GALLO Pan rallado 500 gr,",
            "origin": null,
            "finalUnitPrice": 128,
            "originalUnitPrice": 128,
            "originalPriceDate": null,
            "showOriginalPrice": false,
            "measureUnitPrice": 128,
            "measure": null,
            "imageMainName": "https://www.alcampo.es/media/h76/h0b/8927233310750.jpg",
            "imageMainNameZoom": null,
            "stock": "100",
            "labelMeasureUnitPrice": null,
            "cornerCode": "NEW",
            "freshFrozenCode": "NONE",
            "tagImageCodeList": [],
            "promoInfo": null,
            "discountImageCodeList": [],
            "specialImageCode": "NONE",
            "specialImageText": null,
            "basketItemQuantity": 0,
            "idBasket": null,
            "idBasketItem": null,
            "relatedText": null,
            "idCat": 0,
            "idFamily": 230490,
            "idMarket": 0,
            "descCat": null,
            "descFamily": null,
            "descMarket": null,
            "relatedProduct": null,
            "online": true,
            "recipeId": 0,
            "recipeImageSmall": null,
            "aisle": null,
            "aisleOrder": null,
            "inRecipe": false,
            "related": false,
            "finalUnitPriceDec": 1.28,
            "finalUnitPriceDecPart1": "1",
            "finalUnitPriceDecPart2": "28",
            "originalUnitPriceDec": 1.28,
            "originalUnitPriceDecPart1": "1",
            "originalUnitPriceDecPart2": "28",
            "measureUnitPriceDec": 1.28,
            "measureUnitPriceDecPart1": "1",
            "measureUnitPriceDecPart2": "28"
        },
        {
            "productId": 1576173,
            "eanCode": "8410069010582",
            "gtinCode": "00625755",
            "nameLine1": "PAN RALLADO AJO Y PEREJIL GALL",
            "nameLine2": null,
            "origin": null,
            "finalUnitPrice": 94,
            "originalUnitPrice": 94,
            "originalPriceDate": null,
            "showOriginalPrice": false,
            "measureUnitPrice": 94,
            "measure": null,
            "imageMainName": "image_placeholder",
            "imageMainNameZoom": null,
            "stock": "100",
            "labelMeasureUnitPrice": null,
            "cornerCode": "NEW",
            "freshFrozenCode": "NONE",
            "tagImageCodeList": [],
            "promoInfo": null,
            "discountImageCodeList": [],
            "specialImageCode": "NONE",
            "specialImageText": null,
            "basketItemQuantity": 0,
            "idBasket": null,
            "idBasketItem": null,
            "relatedText": null,
            "idCat": 0,
            "idFamily": 230490,
            "idMarket": 0,
            "descCat": null,
            "descFamily": null,
            "descMarket": null,
            "relatedProduct": null,
            "online": true,
            "recipeId": 0,
            "recipeImageSmall": null,
            "aisle": null,
            "aisleOrder": null,
            "inRecipe": false,
            "related": false,
            "finalUnitPriceDec": 0.94,
            "finalUnitPriceDecPart1": "0",
            "finalUnitPriceDecPart2": "94",
            "originalUnitPriceDec": 0.94,
            "originalUnitPriceDecPart1": "0",
            "originalUnitPriceDecPart2": "94",
            "measureUnitPriceDec": 0.94,
            "measureUnitPriceDecPart1": "0",
            "measureUnitPriceDecPart2": "94"
        },
        {
            "productId": 2507,
            "eanCode": "8410069013538",
            "gtinCode": "00013450",
            "nameLine1": "Pan rallado 500 gramos",
            "nameLine2": "GALLO Pan rallado crujiente 500 gr,",
            "origin": null,
            "finalUnitPrice": 139,
            "originalUnitPrice": 139,
            "originalPriceDate": null,
            "showOriginalPrice": false,
            "measureUnitPrice": 139,
            "measure": null,
            "imageMainName": "https://www.alcampo.es/media/h78/hcc/9304240128030.jpg",
            "imageMainNameZoom": null,
            "stock": "100",
            "labelMeasureUnitPrice": null,
            "cornerCode": "NEW",
            "freshFrozenCode": "NONE",
            "tagImageCodeList": [],
            "promoInfo": null,
            "discountImageCodeList": [],
            "specialImageCode": "NONE",
            "specialImageText": null,
            "basketItemQuantity": 0,
            "idBasket": null,
            "idBasketItem": null,
            "relatedText": null,
            "idCat": 0,
            "idFamily": 230490,
            "idMarket": 0,
            "descCat": null,
            "descFamily": null,
            "descMarket": null,
            "relatedProduct": null,
            "online": true,
            "recipeId": 0,
            "recipeImageSmall": null,
            "aisle": null,
            "aisleOrder": null,
            "inRecipe": false,
            "related": false,
            "finalUnitPriceDec": 1.39,
            "finalUnitPriceDecPart1": "1",
            "finalUnitPriceDecPart2": "39",
            "originalUnitPriceDec": 1.39,
            "originalUnitPriceDecPart1": "1",
            "originalUnitPriceDecPart2": "39",
            "measureUnitPriceDec": 1.39,
            "measureUnitPriceDecPart1": "1",
            "measureUnitPriceDecPart2": "39"
        }
    ]
}
        """

    }

}
