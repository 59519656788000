package com.mshopsas.enterprise.aiw.scan.network.request

import kotlinx.serialization.Serializable

@Serializable
data class SetQuantityRequest(
    val shopperEncodedId: String,
    val storeUuid: String,
    val basketEncodedId: String,
    val itemList: List<SetQuantityItemRequest>
)
