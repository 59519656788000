package com.mshopsas.enterprise.aiw.scan.screens.tutorial.guide

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty.LoyaltyScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.CameraPermissionScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.location.LocationScreen
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data object GuideScreen : Screen {

    @Composable
    override fun Content() {

        val screenModel = getScreenModel<GuideScreenModel>()
        val navigator = LocalNavigator.currentOrThrow

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {

                    GuideContract.Effect.Navigation.ToBack -> {
                        navigator.pop()
                    }

                    GuideContract.Effect.Navigation.ToLocation -> {
                        navigator.push(LocationScreen)
                    }

                    GuideContract.Effect.Navigation.ToHome -> {
                        navigator.push(HomeScreen)
                    }

                    GuideContract.Effect.Navigation.ToLoyalty -> {
                        navigator.push(LoyaltyScreen)
                    }

                    GuideContract.Effect.Navigation.ToCameraPermission -> {
                        navigator.push(CameraPermissionScreen(false))
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (GuideContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        GuideView(state, onEventSent)
    }

}
