package com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.location

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty.LoyaltyScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.CameraPermissionScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.camera.CameraScreen
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data object LocationScreen : Screen {

    @Composable
    override fun Content() {

        val navigator = LocalNavigator.currentOrThrow
        val screenModel = getScreenModel<LocationScreenModel>()

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (LocationContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    LocationContract.Effect.Navigation.ToBack -> {
                        navigator.pop()
                    }

                    LocationContract.Effect.Navigation.ToCamera -> {
                        navigator.push(CameraScreen)
                    }

                    LocationContract.Effect.Navigation.ToLoyalty -> {
                        navigator.push(LoyaltyScreen)
                    }

                    is LocationContract.Effect.Navigation.ToCameraPermission -> {
                        navigator.push(CameraPermissionScreen(it.navigateToScanScreen))
                    }

                    LocationContract.Effect.Navigation.ToHome -> {
                        navigator.push(HomeScreen)
                    }
                }
            }.collect()
        }

        LocationView(state, onEventSent)
    }
}
