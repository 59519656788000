package com.mshopsas.enterprise.aiw.scan.data

import kotlinx.serialization.Serializable

@Serializable
data class Persistence(
    val deviceId: String = "",
    val basketEncodedId: String = "",
    val store: Store? = null,
    val identifier: String? = null
)
