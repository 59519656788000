package com.mshopsas.enterprise.aiw.scan.screens.historic

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.screens.commons.ProgressIndicator
import com.mshopsas.enterprise.aiw.scan.screens.historic.composables.EmptyList
import com.mshopsas.enterprise.aiw.scan.screens.historic.composables.HistoryOrderItem

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun SearchProductView(
    state: HistoryOrderContract.State,
    onEventSent: (HistoryOrderContract.Event) -> Unit
) {

    MScanScaffold(
        navigationIcon = {
            BackButton( onClick= {onEventSent(HistoryOrderContract.Event.OnBack)})
        }
    ) { padding ->
        Column(
            modifier = Modifier.background(Color.White)
                .fillMaxSize()
                .padding(padding)
        ) {
            AnimatedContent(state.orders.isNotEmpty()) { objectAvailable ->
                if (objectAvailable) {
                    LazyColumn(modifier = Modifier.fillMaxSize()) {
                        items(state.orders) { order ->
                            HistoryOrderItem(
                                order = order,
                                onItemClick = {
                                    onEventSent(HistoryOrderContract.Event.OnDetailClick(it))
                                },
                                onUseClick = {
                                    onEventSent(HistoryOrderContract.Event.OnUseClick(it))
                                },
                                modifier = Modifier.animateItemPlacement()
                            )
                        }
                    }
                } else {
                    EmptyList(padding)
                }
            }
        }
    }

    if (state.isLoading) {
        ProgressIndicator()
    }
}
