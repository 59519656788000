package com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.denied

import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class CameraPermissionDeniedContract {

    sealed interface Event : ViewEvent {
        data object OnContinueClick : Event
        data object OnPermissionDenied : Event
        data object OnSheetClose : Event
        data class OnPermissionGranted(val navigateToScanScreen: Boolean) : Event
    }

    data class State(
        val checkPermission: Boolean ,
        val showPermissionExplanation: Boolean,
        val platform: String,
        val isLoading: Boolean,
        val message: String,
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToLoyalty : Navigation
            data object ToCamera : Navigation
            data object ToHome : Navigation
        }
    }

}
