package com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.denied

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.home.HomeScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.loyalty.LoyaltyScreen
import com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.camera.CameraScreen
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data class CameraPermissionDeniedScreen(private val platform: String, private val navigateToScanScreen: Boolean) : Screen {

    @Composable
    override fun Content() {

        val screenModel = getScreenModel<CameraPermissionDeniedScreenModel>()
        val navigator = LocalNavigator.currentOrThrow

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    CameraPermissionDeniedContract.Effect.Navigation.ToLoyalty -> {
                        navigator.push(LoyaltyScreen)
                    }

                    CameraPermissionDeniedContract.Effect.Navigation.ToCamera -> {
                        navigator.push(CameraScreen)
                    }

                    CameraPermissionDeniedContract.Effect.Navigation.ToHome -> {
                        navigator.push(HomeScreen)
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (CameraPermissionDeniedContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        CameraPermissionDeniedView(state, onEventSent, platform, navigateToScanScreen)

    }
}
