package com.mshopsas.enterprise.aiw.scan.screens.home.composables

import androidx.compose.animation.core.Spring
import androidx.compose.animation.core.animateIntOffsetAsState
import androidx.compose.animation.core.spring
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.offset
import androidx.compose.material.ripple.LocalRippleTheme
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.FloatingActionButtonDefaults
import androidx.compose.material3.FloatingActionButtonElevation
import androidx.compose.material3.contentColorFor
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableFloatStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Shape
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInParent
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.toSize
import com.mshopsas.enterprise.aiw.scan.screens.commons.LightRippleTheme
import kotlin.math.roundToInt

@Composable
fun MovableFloatingActionButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    shape: Shape = FloatingActionButtonDefaults.shape,
    containerColor: Color = FloatingActionButtonDefaults.containerColor,
    contentColor: Color = contentColorFor(containerColor),
    elevation: FloatingActionButtonElevation = FloatingActionButtonDefaults.elevation(),
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    content: @Composable () -> Unit,
) {

    val margin = 17

    var mySize = Size.Zero
    var parentSize = Size.Zero
    var positionInParent = Offset.Zero

    var offsetX by rememberSaveable { mutableFloatStateOf(0f) }
    var offsetY by rememberSaveable { mutableFloatStateOf(0f) }

    val offset by animateIntOffsetAsState(
        targetValue = IntOffset(offsetX.roundToInt(), offsetY.roundToInt()),
        label = "offset",
        animationSpec = spring(
            dampingRatio = Spring.DampingRatioLowBouncy,
            stiffness = Spring.StiffnessMediumLow
        )
    )

    fun validateMove(dragAmount: Offset) {
        val x = offsetX + dragAmount.x + positionInParent.x
        if (x - margin > 0 && x + margin < parentSize.width - mySize.width) {
            offsetX += dragAmount.x
        }

        val y = offsetY + dragAmount.y + positionInParent.y
        if (y - margin > 0 && y + margin < parentSize.height - mySize.height) {
            offsetY += dragAmount.y
        }
    }

    CompositionLocalProvider(LocalRippleTheme provides LightRippleTheme) {
        FloatingActionButton(
            onClick = { onClick() },
            modifier = modifier.onGloballyPositioned {
                mySize = it.size.toSize()
                parentSize = it.parentLayoutCoordinates?.size?.toSize() ?: Size.Zero
                positionInParent = it.positionInParent()
            }
                .offset { offset }
                .pointerInput(Unit) {
                    detectDragGestures(
                        onDrag = { change, dragAmount ->
                            change.consume()
                            validateMove(dragAmount)
                        }
                    )
                },
            shape = shape,
            containerColor = containerColor,
            contentColor = contentColor,
            elevation = elevation,
            interactionSource = interactionSource
        ) {
            content()
        }
    }
}
