package com.mshopsas.enterprise.aiw.scan.screens.historic.detail

import com.mshopsas.enterprise.aiw.scan.data.basket.HistoryOrder
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductInfo
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class HistoryOrderDetailContract {
    sealed interface Event : ViewEvent {
        data class OnInit(val order: HistoryOrder) : Event
        data object OnBack : Event
    }

    data class State(
        val items: List<ProductInfo>,
        val isAnonymous: Boolean
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
        }
    }
}