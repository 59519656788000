package com.mshopsas.enterprise.aiw.scan.screens.commons

import androidx.compose.material.ripple.RippleAlpha
import androidx.compose.material.ripple.RippleTheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color

object NoRippleTheme : RippleTheme {

    @Composable
    override fun defaultColor() = RippleTheme.defaultRippleColor(
        contentColor = Color.Unspecified,
        lightTheme = true
    )

    @Composable
    override fun rippleAlpha() = RippleAlpha(
        pressedAlpha = 0f,
        focusedAlpha = 0f,
        draggedAlpha = 0f,
        hoveredAlpha = 0f
    )
}