package com.mshopsas.enterprise.aiw.scan.screens.historic.detail

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Modifier
import com.mshopsas.enterprise.aiw.scan.data.basket.HistoryOrder
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButton
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.screens.search.composables.ProductSearchCellView

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun HistoryOrderDetailView(
    state: HistoryOrderDetailContract.State,
    onEventSent: (HistoryOrderDetailContract.Event) -> Unit,
    order: HistoryOrder
) {

    LaunchedEffect("Init") {
        onEventSent(HistoryOrderDetailContract.Event.OnInit(order))
    }

    MScanScaffold(
        navigationIcon = {
            BackButton( onClick= {onEventSent(HistoryOrderDetailContract.Event.OnBack)})
        }
    ) { padding ->
        Column(
            modifier = Modifier.fillMaxSize().padding(padding),
        ) {
            LazyColumn(modifier = Modifier.fillMaxSize()) {
                items(state.items) { product ->
                    ProductSearchCellView(
                        product = product.productInfo,
                        isAnonymous = state.isAnonymous,
                        onItemClick = { },
                        modifier = Modifier.animateItemPlacement()
                    )}
            }
        }
    }

}
