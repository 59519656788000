package com.mshopsas.enterprise.aiw.scan.screens.commons

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.unfound_image
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import co.touchlab.kermit.Logger
import com.mshopsas.enterprise.aiw.scan.utils.NullDataInterceptor
import com.seiko.imageloader.model.ImageAction
import com.seiko.imageloader.model.ImageRequest
import com.seiko.imageloader.model.ImageRequestBuilder
import com.seiko.imageloader.rememberImageSuccessPainter
import com.seiko.imageloader.ui.AutoSizeBox
import org.jetbrains.compose.resources.painterResource

@Composable
fun ImageLoader(
    data: Any,
    modifier: Modifier = Modifier.aspectRatio(1f),
    contentScale: ContentScale = ContentScale.Crop,
    block: (ImageRequestBuilder.() -> Unit)? = null,
) {
    Box(modifier, Alignment.Center) {
        val dataState by rememberUpdatedState(data)
        val blockState by rememberUpdatedState(block)
        val request by remember {
            derivedStateOf {
                ImageRequest {
                    data(dataState)
                    addInterceptor(NullDataInterceptor)
                    // components {
                    //     add(customKtorUrlFetcher)
                    // }
                    options {
                        maxImageSize = 512
                    }
                    blockState?.invoke(this)
                }
            }
        }

        AutoSizeBox(
            request,
            Modifier.matchParentSize(),
        ) { action ->
            when (action) {
                is ImageAction.Loading -> {
                    CircularProgressIndicator()
                }

                is ImageAction.Success -> {
                    Image(
                        rememberImageSuccessPainter(action),
                        contentDescription = "image",
                        contentScale = contentScale,
                        modifier = Modifier.matchParentSize(),
                    )
                }

                is ImageAction.Failure -> {
                    Logger.e { action.error.message ?: "Error loading image" }
                    Image(
                        painter = painterResource(Res.drawable.unfound_image),
                        contentDescription = null,
                        modifier = Modifier.fillMaxSize(),
                        contentScale = ContentScale.Crop
                    )
                }
            }
        }
    }
}
