package com.mshopsas.enterprise.aiw.scan.screens.tutorial.guide

import cafe.adriel.voyager.core.model.screenModelScope
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.Store
import com.mshopsas.enterprise.aiw.scan.data.getParameters
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.CheckInRequest
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class GuideScreenModel(
    private val repository: Repository
) : BaseScreenModel<GuideContract.Event, GuideContract.State, GuideContract.Effect>() {

    override fun setInitialState() = GuideContract.State(
        isLoading = false,
        message = "",
        checkCameraPermission = false
    )

    override fun handleEvents(event: GuideContract.Event) {
        when (event) {
            GuideContract.Event.OnNextClick -> onNextClick()
            GuideContract.Event.OnBackClick -> onBackClick()
            is GuideContract.Event.OnCameraPermissionIsGranted -> onCameraPermissionIsGranted(event.isGranted)
        }
    }

    private fun onNextClick() {
        screenModelScope.launch {
            val params = getParameters()
            if (params.storeUuid != null) {
                repository.setStore(Store(params.storeUuid, ""))
                setState {
                    copy(checkCameraPermission = true)
                }
            } else {
                setEffect { GuideContract.Effect.Navigation.ToLocation }
            }
        }
    }

    private fun onBackClick() {
        setEffect { GuideContract.Effect.Navigation.ToBack }
    }

    private fun onCameraPermissionIsGranted(isGranted: Boolean) {
        setState { copy(checkCameraPermission = false) }
        val params = getParameters()
        if (isGranted) {
            if (params.identifier != null) {
                callCheckIn(params.identifier)
            } else {
                setEffect { GuideContract.Effect.Navigation.ToLoyalty }
            }
        } else {
            setEffect { GuideContract.Effect.Navigation.ToCameraPermission }
        }
    }

    private fun callCheckIn(identifier: String) {
        screenModelScope.launch {
            repository.callCheckIn(identifier, CheckInRequest.LOYALTY).collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let {
                            repository.setCheckIn(it, identifier)
                            repository.setBasket(it.currentBasket)
                            setState { copy(isLoading = false) }
                            setEffect { GuideContract.Effect.Navigation.ToHome }
                        } ?: run {
                            setState { copy(isLoading = false, message = "CheckIn error") }
                        }
                    }

                    Resource.Status.ERROR -> {
                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false, message = errorMsg) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

}
