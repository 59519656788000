package com.mshopsas.enterprise.aiw.scan.screens.historic.composables

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.history_articles
import aiwscan.composeapp.generated.resources.history_use_list
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.ripple.LocalRippleTheme
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.data.basket.HistoryOrder
import com.mshopsas.enterprise.aiw.scan.screens.commons.ButtonDef
import com.mshopsas.enterprise.aiw.scan.screens.commons.LightRippleTheme
import com.mshopsas.enterprise.aiw.scan.theme.BlueGrey
import org.jetbrains.compose.resources.stringResource

@Composable
fun HistoryOrderItem(
    order: HistoryOrder,
    modifier: Modifier = Modifier,
    onItemClick: (HistoryOrder) -> Unit,
    onUseClick: (HistoryOrder) -> Unit
) {
    Card(
        shape = RoundedCornerShape(4.dp),
        modifier = modifier.padding(horizontal = 16.dp, vertical = 8.dp),
        colors = CardDefaults.cardColors(
            containerColor = Color.White,
        ),
        elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
    ) {
        Row(
            modifier = Modifier.fillMaxWidth().clickable {
                onItemClick(order)
            },
            verticalAlignment = Alignment.CenterVertically
        ) {
            Column(
                modifier = Modifier.weight(2 / 3f)
                    .aspectRatio(3 / 1f)
                    .padding(start = 16.dp, end = 16.dp, top = 8.dp, bottom = 8.dp),
                verticalArrangement = Arrangement.spacedBy(3.dp)
            ) {
                Text(
                    text = order.store.label,
                    style = MaterialTheme.typography.titleMedium,
                    fontSize = 18.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.weight(1f),
                    maxLines = 1
                )
                Text(
                    text = order.dateValidation,
                    style = MaterialTheme.typography.titleMedium,
                    fontSize = 14.sp,
                    fontWeight = FontWeight.Normal,
                    modifier = Modifier.weight(1f),
                    maxLines = 1
                )
                Text(
                    text = stringResource(Res.string.history_articles, order.productsCount),
                    style = MaterialTheme.typography.titleMedium,
                    fontSize = 14.sp,
                    fontWeight = FontWeight.Normal,
                    modifier = Modifier.weight(1f),
                    maxLines = 1
                )
            }

//        Box(modifier = Modifier.weight(2 / 3f).aspectRatio(4 / 4f)) {
            Box(modifier = Modifier.weight(1 / 3f)) {
                CompositionLocalProvider(LocalRippleTheme provides LightRippleTheme) {
                    ButtonDef(
                        onClick = { onUseClick(order) },
                        colors = ButtonDefaults.buttonColors(containerColor = BlueGrey),
//                    modifier = Modifier.weight(1f / 3f),
                    ) {
                        Text(
                            text = stringResource(Res.string.history_use_list),
                            color = Color.White,
                            fontWeight = FontWeight.Bold
                        )
                    }
                }
            }
        }
    }
}
