package com.mshopsas.enterprise.aiw.scan.screens.tutorial.store.location

import cafe.adriel.voyager.core.model.screenModelScope
import com.mshopsas.enterprise.aiw.scan.data.Location
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.Store
import com.mshopsas.enterprise.aiw.scan.data.getParameters
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.CheckInRequest
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class LocationScreenModel(
    private val repository: Repository
) : BaseScreenModel<LocationContract.Event, LocationContract.State, LocationContract.Effect>() {

    override fun setInitialState() = LocationContract.State(
        askPermission = false,
        location = null,
        store = null,
        storeList = listOf(Store("1", "Store1"), Store("2", "Store2"), Store("3", "Store3")),
        isOkCancelOpen = false,
        checkCameraPermission = false,
        navigateToScanScreen = false,
        message = "",
        isLoading = false
    )

    override fun handleEvents(event: LocationContract.Event) {
        when (event) {
            LocationContract.Event.OnRequestLocationClick -> {
                setState { copy(isLoading = true, askPermission = true) }
            }

            is LocationContract.Event.OnLocationResult -> {
                val location = event.result
                if (location != null) {
                    setState { copy(isLoading = false, askPermission = false, location = location) }
                    getNearbyStore(location)
                } else {
                    setState { copy(isLoading = false) }
                    //ShouldDo: show error and navigate to camera view
                }
            }

            is LocationContract.Event.OnStoreSelected -> {
                setState { copy(store = event.store) }
            }

            LocationContract.Event.OnBackClick -> {
                setEffect { LocationContract.Effect.Navigation.ToBack }
            }

            LocationContract.Event.OnCancelClick -> {
                setState {
                    copy(
                        isOkCancelOpen = false,
                        checkCameraPermission = true,
                        navigateToScanScreen = true
                    )
                }
            }

            is LocationContract.Event.OnOkClick -> {
                screenModelScope.launch {
                    repository.setStore(event.store)
                    setState {
                        copy(
                            isOkCancelOpen = false,
                            checkCameraPermission = true,
                            navigateToScanScreen = false
                        )
                    }
                }
            }

            is LocationContract.Event.OnCameraPermissionIsGranted -> {
                setState { copy(checkCameraPermission = false) }
                if (event.isGranted) {
                    if (viewState.value.navigateToScanScreen) {
                        setEffect { LocationContract.Effect.Navigation.ToCamera }
                    } else {
                        val params = getParameters()
                        if (params.identifier != null) {
                            callCheckIn(params.identifier)
                        } else {
                            setEffect { LocationContract.Effect.Navigation.ToLoyalty }
                        }
                    }
                } else {
                    setEffect { LocationContract.Effect.Navigation.ToCameraPermission(viewState.value.navigateToScanScreen) }
                }
            }
        }
    }

    private fun getNearbyStore(location: Location) {
        screenModelScope.launch {
            repository.callStoreByLocation(location.longitude, location.latitude)
                .collect { result ->
                    when (result.status) {
                        Resource.Status.SUCCESS -> {
                            setState {
                                copy(
                                    isLoading = false,
                                    store = result.data,
                                    isOkCancelOpen = true,
                                    message = ""
                                )
                            }
                        }

                        Resource.Status.ERROR -> {
                            val errorMsg = result.error?.message ?: "unknown error"
                            setState {
                                copy(
                                    isLoading = false,
                                    askPermission = false,
                                    message = errorMsg
                                )
                            }
                        }

                        Resource.Status.LOADING -> {
                            setState { copy(isLoading = true) }
                        }
                    }
                }
        }
    }

    private fun callCheckIn(identifier: String) {
        screenModelScope.launch {
            repository.callCheckIn(identifier, CheckInRequest.LOYALTY).collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let {
                            repository.setCheckIn(it, identifier)
                            repository.setBasket(it.currentBasket)
                            setState { copy(isLoading = false) }
                            setEffect { LocationContract.Effect.Navigation.ToHome }
                        } ?: run {
                            setState { copy(isLoading = false, message = "CheckIn error") }
                        }
                    }

                    Resource.Status.ERROR -> {
                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false, message = errorMsg) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

}
