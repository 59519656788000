package com.mshopsas.enterprise.aiw.scan.data

import com.mshopsas.enterprise.aiw.scan.data.basket.Basket
import kotlinx.serialization.Serializable

@Serializable
data class CheckIn(
    val encodeID: String,
    val shopperIdentifier: String,
    val username: String,
    val langConfig: Language,
    val cashMode: String,
    val cashIP: String,
    val turnMode: String,
    val turnIP: String,
    val urlCatalog: String?,
    val urlHelp: String?,
    val status: String?,
    val currentBasket: Basket
)