package com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission.denied

import cafe.adriel.voyager.core.model.screenModelScope
import com.mshopsas.enterprise.aiw.scan.data.Repository
import com.mshopsas.enterprise.aiw.scan.data.getParameters
import com.mshopsas.enterprise.aiw.scan.network.Resource
import com.mshopsas.enterprise.aiw.scan.network.request.CheckInRequest
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.BaseScreenModel
import kotlinx.coroutines.launch

class CameraPermissionDeniedScreenModel(
    private val repository: Repository
) : BaseScreenModel<CameraPermissionDeniedContract.Event, CameraPermissionDeniedContract.State, CameraPermissionDeniedContract.Effect>() {

    override fun setInitialState() = CameraPermissionDeniedContract.State(
        checkPermission = false,
        showPermissionExplanation = false,
        platform = "unknown",
        isLoading = false,
        message = ""
    )

    override fun handleEvents(event: CameraPermissionDeniedContract.Event) {
        when (event) {
            CameraPermissionDeniedContract.Event.OnContinueClick -> onContinueClick()
            CameraPermissionDeniedContract.Event.OnPermissionDenied -> onShowPermissionExplanation()
            CameraPermissionDeniedContract.Event.OnSheetClose -> {
                setState { copy(checkPermission = false, showPermissionExplanation = false) }
            }

            is CameraPermissionDeniedContract.Event.OnPermissionGranted -> {
                if (event.navigateToScanScreen) {
                    setEffect { CameraPermissionDeniedContract.Effect.Navigation.ToCamera }
                } else {
                    val params = getParameters()
                    if (params.identifier != null) {
                        callCheckIn(params.identifier)
                    } else {
                        setEffect { CameraPermissionDeniedContract.Effect.Navigation.ToLoyalty }
                    }
                }
            }
        }
    }

    private fun onContinueClick() {
        setState { copy(checkPermission = true) }
    }

    private fun onShowPermissionExplanation() {
        setState { copy(showPermissionExplanation = true) }
    }

    private fun callCheckIn(identifier: String) {
        screenModelScope.launch {
            repository.callCheckIn(identifier, CheckInRequest.LOYALTY).collect { result ->
                when (result.status) {
                    Resource.Status.SUCCESS -> {
                        result.data?.let {
                            repository.setCheckIn(it, identifier)
                            repository.setBasket(it.currentBasket)
                            setState { copy(isLoading = false) }
                            setEffect { CameraPermissionDeniedContract.Effect.Navigation.ToHome }
                        } ?: run {
                            setState { copy(isLoading = false, message = "CheckIn error") }
                        }
                    }

                    Resource.Status.ERROR -> {
                        val errorMsg = result.error?.message ?: "unknown error"
                        setState { copy(isLoading = false, message = errorMsg) }
                    }

                    Resource.Status.LOADING -> {
                        setState { copy(isLoading = true) }
                    }
                }
            }
        }
    }

}
