package com.mshopsas.enterprise.aiw.scan.network.request

import kotlinx.serialization.Serializable

@Serializable
class CheckInRequest(
    val identifier: String?,
    val identifierType: String,
    val lang: String,
    val storeUuid: String,
    val terminalIdentifier: String,
    val platform: String,
    val pushToken: String? = null
) {
    companion object {
        const val LOYALTY = "LOYALTY"
        const val ANONYMOUS = "ANONYMOUS"
    }

}