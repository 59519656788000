package com.mshopsas.enterprise.aiw.scan.network.response

import com.mshopsas.enterprise.aiw.scan.data.basket.details.OFFProduct
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OFFProductResponse(
    @SerialName("status_verbose")
    val statusVerbose: String?,
    val product: OFFProduct?,
    val code: String?,
    val status: Int?
)
