package com.mshopsas.enterprise.aiw.scan.screens.scan

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerType
import com.mshopsas.enterprise.aiw.scan.screens.commons.BackButtonLight
import com.mshopsas.enterprise.aiw.scan.utils.popWithResult
import kotlin.math.min

data class ScanScreen(val types: List<ScannerType> = listOf()) : Screen {

    companion object {
        const val SCAN_RESULT = "SCAN_RESULT"
    }

    @Composable
    override fun Content() {

        val navigator = LocalNavigator.currentOrThrow

        var onBack by mutableStateOf(false)

        BoxWithConstraints(
            modifier = Modifier.fillMaxSize().background(Color.Black),
            contentAlignment = Alignment.Center
        ) {

            val width = calculateWidth(maxWidth.value, maxHeight.value)

            ScannerView(
                types = types,
                width = width,
                modifier = Modifier.fillMaxSize(),
                onBackPressed = onBack,
                callback = {
                    onBack = false
                    navigator.popWithResult(
                        key = SCAN_RESULT,
                        result = it
                    )
                }
            )

            Box(
                modifier = Modifier.fillMaxSize().padding(8.dp)
            ) {
                BackButtonLight(onClick = { onBack = true })
            }
        }
    }
}

private fun calculateWidth(maxWidth: Float, maxHeight: Float): Float {
    return min(maxHeight * 1.2f, maxWidth)
}
