package com.mshopsas.enterprise.aiw.scan.data.basket.details

import com.mshopsas.enterprise.aiw.scan.data.basket.CompareItem
import kotlinx.serialization.Serializable

@Serializable
data class ProductInfo(
    val productInfo: ProductDetail
) {
    fun toCompareItem() = CompareItem(
        eanCode = productInfo.eanCode,
        productLabel1 = productInfo.nameLine1,
        productLabel2 = productInfo.nameLine2,
        image = productInfo.imageMainName,
        aisle = productInfo.aisle,
        aisleOrder = productInfo.aisleOrder,
        quantity = 1
    )
}