package com.mshopsas.enterprise.aiw.scan.utils

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.remember
import cafe.adriel.voyager.navigator.Navigator

/**
 * Navigator
 */
private val results = mutableStateMapOf<String, Any?>()


fun Navigator.popWithResult(key: String, result: Any?) {
    results[key] = result
    pop()
}

@Suppress("UNCHECKED_CAST")
@Composable
fun <T> Navigator.getResult(screenKey: String): State<T?>? {
    val exists = results.containsKey(screenKey)
    val resultState = if (exists) {
        val result = results[screenKey] as? T
        remember(screenKey, result) {
            derivedStateOf {
                results.remove(screenKey)
                result
            }
        }
    } else null
    return resultState
}
