package com.mshopsas.enterprise.aiw.scan.data

import kotlinx.serialization.Serializable

@Serializable
data class Language(
    val storeLangs: List<String>,
    val defaultLang: String,
    val userLang: String
)
