package com.mshopsas.enterprise.aiw.scan.data.basket.details

import com.mshopsas.enterprise.aiw.scan.data.basket.PromoInfo
import kotlinx.serialization.Serializable

@Serializable
data class ProductDetail(
    val productId: Long,
    val eanCode: String,
    val gtinCode: String?,
    val nameLine1: String,
    val nameLine2: String?,
    val finalUnitPrice: Int?,
    val imageMainName: String?,
    val labelMeasureUnitPrice: String?,
    val promoInfo: PromoInfo?,
    val discountImageCodeList: List<ProductDetailsPromo>,
    val idFamily: Int?,
    val aisle: String?,
    val aisleOrder: String?
)
