package com.mshopsas.enterprise.aiw.scan.screens.home.composables

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.nova1
import aiwscan.composeapp.generated.resources.nova2
import aiwscan.composeapp.generated.resources.nova3
import aiwscan.composeapp.generated.resources.nova4
import aiwscan.composeapp.generated.resources.nutriscore_a
import aiwscan.composeapp.generated.resources.nutriscore_b
import aiwscan.composeapp.generated.resources.nutriscore_c
import aiwscan.composeapp.generated.resources.nutriscore_d
import aiwscan.composeapp.generated.resources.nutriscore_e
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.data.basket.details.OFFProduct
import org.jetbrains.compose.resources.painterResource

@Composable
fun OffInfo(offProduct: OFFProduct?) {
    Row(modifier = Modifier.fillMaxWidth().padding(8.dp),
        horizontalArrangement = Arrangement.spacedBy(16.dp)) {
        if (offProduct?.novaGroups != null) {
            val nova = when (offProduct.novaGroups) {
                "1" -> Res.drawable.nova1
                "2" -> Res.drawable.nova2
                "3" -> Res.drawable.nova3
                "4" -> Res.drawable.nova4
                else -> null
            }
            nova?.let { Image(painterResource(it), null) }
        }

        if (offProduct?.nutritionGrades != null) {
            val score = when (offProduct.nutritionGrades) {
                "a" -> Res.drawable.nutriscore_a
                "b" -> Res.drawable.nutriscore_b
                "c" -> Res.drawable.nutriscore_c
                "d" -> Res.drawable.nutriscore_d
                "e" -> Res.drawable.nutriscore_e
                else -> null
            }
            score?.let { Image(painterResource(it), null) }
        }
    }
}
