package com.mshopsas.enterprise.aiw.scan.screens.historic

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.currentOrThrow
import com.mshopsas.enterprise.aiw.scan.di.getScreenModel
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.SIDE_EFFECTS_KEY
import com.mshopsas.enterprise.aiw.scan.screens.historic.detail.HistoryOrderDetailScreen
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach

data object HistoryOrderScreen : Screen {

    @Composable
    override fun Content() {

        val screenModel: HistoryOrderScreenModel = getScreenModel()
        val navigator = LocalNavigator.currentOrThrow

        LaunchedEffect(SIDE_EFFECTS_KEY) {
            screenModel.effect.onEach {
                when (it) {
                    HistoryOrderContract.Effect.Navigation.ToBack -> {
                        navigator.pop()
                    }
                    is HistoryOrderContract.Effect.Navigation.ToHistoryOrderDetail -> {
                        navigator.push(HistoryOrderDetailScreen(it.order))
                    }
                }
            }.collect()
        }

        val state by screenModel.viewState.collectAsState()
        val onEventSent: (HistoryOrderContract.Event) -> Unit = { event -> screenModel.setEvent(event) }

        SearchProductView(state, onEventSent)

    }
}
