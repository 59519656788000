package com.mshopsas.enterprise.aiw.scan.screens.home.composables

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.screens.commons.AutoResizeText
import com.mshopsas.enterprise.aiw.scan.screens.commons.FontSizeRange
import com.mshopsas.enterprise.aiw.scan.theme.RedDark

@Composable
fun Price(price: Int, crossPrice: Boolean) {
    Box(
        modifier = Modifier.size(64.dp, 24.dp),
        contentAlignment = Alignment.Center
    ) {
        AutoResizeText(
            text = price.toMoney(),
            fontSizeRange = FontSizeRange(14.sp, 24.sp),
            fontWeight = FontWeight.Bold
        )

        if (crossPrice)
            DrawDiagonallyRight(64.dp, 8.dp)
    }
}

@Composable
private fun DrawDiagonallyRight(width: Dp, height: Dp) {
    Canvas(Modifier.size(width, height)) {
        drawLine(
            start = Offset(x = size.width, y = size.height),
            end = Offset(x = 0.dp.toPx(), y = 0f),
            color = RedDark,
            strokeWidth = 2.dp.toPx()
        )
    }
}

fun Int.toMoney(): String {
    val toString = toString()

    val temp = when (toString.length) {
        1 -> "0,0$toString"
        2 -> "0,$toString"
        else -> "${toString.dropLast(2)},${toString.takeLast(2)}"
    }

    return "$temp€"
}
