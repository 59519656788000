package com.mshopsas.enterprise.aiw.scan.data.basket.details

import kotlinx.serialization.Serializable

@Serializable
data class ProductDetailsPromo(
    val type: String,
    val onlyLoyalty: Boolean,
    val codeAvg: String,
    val amount: String,
    val typeAL: String,
    val idCriteria: Long,
    val amount2: String?,
    val amount3: String?,
    val textLine1: String?,
    val textLine2: String?,
    val frequency: Int,
    val lotDescription: String?,
    val lotDescriptionTitle: String?,
    val currentNbItemsInBasket: Int
)
