package com.mshopsas.enterprise.aiw.scan.network.request

import kotlinx.serialization.Serializable

@Serializable
data class LocateStoreRequest(
    val typeLocation: String,
    val qrCode: String? = null,
    val latitude: Double = 0.0,
    val longitude: Double = 0.0
) {
    companion object {
        const val QR_CODE = "QR_CODE"
        const val COORDINATES = "COORDINATES"
    }
}