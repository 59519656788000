package com.mshopsas.enterprise.aiw.scan.screens.home.composables

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.empty_basket_animation
import aiwscan.composeapp.generated.resources.home_tap_the_button
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.screens.commons.ImageLoader
import org.jetbrains.compose.resources.stringResource

@Composable
fun EmptyDB(padding: PaddingValues) {
    Column(
        modifier = Modifier
            .background(Color.White)
            .padding(padding)
            .fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        ImageLoader(
            Res.drawable.empty_basket_animation,
            modifier = Modifier
                .height(200.dp)
                .aspectRatio(1 / 1f)
                .padding(12.dp),
            contentScale = ContentScale.Fit
        )
        Text(
            text = stringResource(Res.string.home_tap_the_button)
        )
    }
}
