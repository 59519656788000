package com.mshopsas.enterprise.aiw.scan.screens.tutorial.permission

import com.mshopsas.enterprise.aiw.scan.data.scanner.ScannerData
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewEvent
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewSideEffect
import com.mshopsas.enterprise.aiw.scan.screens.commons.base.ViewState

class CameraPermissionContract {

    sealed interface Event : ViewEvent {
        data object OnRequestPermissionClick : Event
        data object OnBackClick : Event
        data object OnScanClick : Event
        data object OnSheetClose : Event
        data class OnPermissionResult(
            val result: Boolean,
            val platform: String,
            val navigateToScanScreen: Boolean
        ) : Event
    }

    data class State(
        val askPermission: Boolean,
        val result: ScannerData?,
        val isLoading: Boolean,
        val message: String,
    ) : ViewState

    sealed interface Effect : ViewSideEffect {

        sealed interface Navigation : Effect {
            data object ToBack : Navigation
            data object ToLoyalty : Navigation
            data object ToHome : Navigation
            data object ToCamera : Navigation
            data class ToPermissionDenied(
                val platform: String,
                val navigateToScanScreen: Boolean
            ) : Navigation
        }
    }

}
