@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package aiwscan.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val audit_pending_message: StringResource by 
      lazy { init_audit_pending_message() }

  public val audit_pending_title: StringResource by 
      lazy { init_audit_pending_title() }

  public val audit_required: StringResource by 
      lazy { init_audit_required() }

  public val audit_update_state: StringResource by 
      lazy { init_audit_update_state() }

  public val camera_message1: StringResource by 
      lazy { init_camera_message1() }

  public val camera_next: StringResource by 
      lazy { init_camera_next() }

  public val camera_permission_android_title1: StringResource by 
      lazy { init_camera_permission_android_title1() }

  public val camera_permission_chrome_message1: StringResource by 
      lazy { init_camera_permission_chrome_message1() }

  public val camera_permission_chrome_title1: StringResource by 
      lazy { init_camera_permission_chrome_title1() }

  public val camera_permission_denied_continue: StringResource by 
      lazy { init_camera_permission_denied_continue() }

  public val camera_permission_edge_title1: StringResource by 
      lazy { init_camera_permission_edge_title1() }

  public val camera_permission_firefox_title1: StringResource by 
      lazy { init_camera_permission_firefox_title1() }

  public val camera_permission_ios_title1: StringResource by 
      lazy { init_camera_permission_ios_title1() }

  public val camera_permission_message1: StringResource by 
      lazy { init_camera_permission_message1() }

  public val camera_permission_ms_ie_title1: StringResource by 
      lazy { init_camera_permission_ms_ie_title1() }

  public val camera_permission_next: StringResource by 
      lazy { init_camera_permission_next() }

  public val camera_permission_opera_message1: StringResource by 
      lazy { init_camera_permission_opera_message1() }

  public val camera_permission_opera_title1: StringResource by 
      lazy { init_camera_permission_opera_title1() }

  public val camera_permission_safari_message1: StringResource by 
      lazy { init_camera_permission_safari_message1() }

  public val camera_permission_safari_title1: StringResource by 
      lazy { init_camera_permission_safari_title1() }

  public val camera_permission_sheet_message1: StringResource by 
      lazy { init_camera_permission_sheet_message1() }

  public val camera_permission_sheet_title1: StringResource by 
      lazy { init_camera_permission_sheet_title1() }

  public val camera_permission_title1: StringResource by 
      lazy { init_camera_permission_title1() }

  public val camera_permission_unknown_message1: StringResource by 
      lazy { init_camera_permission_unknown_message1() }

  public val camera_permission_unknown_title1: StringResource by 
      lazy { init_camera_permission_unknown_title1() }

  public val camera_title1: StringResource by 
      lazy { init_camera_title1() }

  public val guide_message1: StringResource by 
      lazy { init_guide_message1() }

  public val guide_message2: StringResource by 
      lazy { init_guide_message2() }

  public val guide_message3: StringResource by 
      lazy { init_guide_message3() }

  public val guide_next: StringResource by 
      lazy { init_guide_next() }

  public val guide_title1: StringResource by 
      lazy { init_guide_title1() }

  public val guide_title2: StringResource by 
      lazy { init_guide_title2() }

  public val guide_title3: StringResource by 
      lazy { init_guide_title3() }

  public val history_articles: StringResource by 
      lazy { init_history_articles() }

  public val history_empty_list: StringResource by 
      lazy { init_history_empty_list() }

  public val history_use_list: StringResource by 
      lazy { init_history_use_list() }

  public val home_aisle: StringResource by 
      lazy { init_home_aisle() }

  public val home_aisle_order: StringResource by 
      lazy { init_home_aisle_order() }

  public val home_cell_accumulate_x: StringResource by 
      lazy { init_home_cell_accumulate_x() }

  public val home_cell_members_only: StringResource by 
      lazy { init_home_cell_members_only() }

  public val home_cell_save: StringResource by 
      lazy { init_home_cell_save() }

  public val home_cell_save_x: StringResource by 
      lazy { init_home_cell_save_x() }

  public val home_comparing_with: StringResource by 
      lazy { init_home_comparing_with() }

  public val home_items: StringResource by 
      lazy { init_home_items() }

  public val home_menu_basket: StringResource by 
      lazy { init_home_menu_basket() }

  public val home_menu_brochures: StringResource by 
      lazy { init_home_menu_brochures() }

  public val home_menu_historic: StringResource by 
      lazy { init_home_menu_historic() }

  public val home_menu_power_by_1: StringResource by 
      lazy { init_home_menu_power_by_1() }

  public val home_menu_power_by_2: StringResource by 
      lazy { init_home_menu_power_by_2() }

  public val home_menu_search: StringResource by 
      lazy { init_home_menu_search() }

  public val home_menu_turns: StringResource by 
      lazy { init_home_menu_turns() }

  public val home_pay: StringResource by 
      lazy { init_home_pay() }

  public val home_payment_message: StringResource by 
      lazy { init_home_payment_message() }

  public val home_payment_ok: StringResource by 
      lazy { init_home_payment_ok() }

  public val home_payment_title: StringResource by 
      lazy { init_home_payment_title() }

  public val home_scan_loyalty: StringResource by 
      lazy { init_home_scan_loyalty() }

  public val home_subtotal: StringResource by 
      lazy { init_home_subtotal() }

  public val home_tap_the_button: StringResource by 
      lazy { init_home_tap_the_button() }

  public val home_unknown_product_message: StringResource by 
      lazy { init_home_unknown_product_message() }

  public val home_unknown_product_title: StringResource by 
      lazy { init_home_unknown_product_title() }

  public val intro_message: StringResource by 
      lazy { init_intro_message() }

  public val intro_next: StringResource by 
      lazy { init_intro_next() }

  public val intro_welcome: StringResource by 
      lazy { init_intro_welcome() }

  public val location_message1: StringResource by 
      lazy { init_location_message1() }

  public val location_next: StringResource by 
      lazy { init_location_next() }

  public val location_title1: StringResource by 
      lazy { init_location_title1() }

  public val loyalty_anonymous: StringResource by 
      lazy { init_loyalty_anonymous() }

  public val loyalty_identify: StringResource by 
      lazy { init_loyalty_identify() }

  public val loyalty_message1: StringResource by 
      lazy { init_loyalty_message1() }

  public val loyalty_title1: StringResource by 
      lazy { init_loyalty_title1() }

  public val payment_selection_assistance_message: StringResource by 
      lazy { init_payment_selection_assistance_message() }

  public val payment_selection_assistance_title: StringResource by 
      lazy { init_payment_selection_assistance_title() }

  public val payment_selection_audit_message: StringResource by 
      lazy { init_payment_selection_audit_message() }

  public val payment_selection_audit_title: StringResource by 
      lazy { init_payment_selection_audit_title() }

  public val payment_selection_message1: StringResource by 
      lazy { init_payment_selection_message1() }

  public val payment_selection_update_status_text: StringResource by 
      lazy { init_payment_selection_update_status_text() }

  public val promo_product_in_promo: StringResource by 
      lazy { init_promo_product_in_promo() }

  public val promo_rem_01: StringResource by 
      lazy { init_promo_rem_01() }

  public val promo_rem_01_short: StringResource by 
      lazy { init_promo_rem_01_short() }

  public val promo_rem_10: StringResource by 
      lazy { init_promo_rem_10() }

  public val promo_rem_10_short: StringResource by 
      lazy { init_promo_rem_10_short() }

  public val promo_rem_20: StringResource by 
      lazy { init_promo_rem_20() }

  public val promo_rem_ra: StringResource by 
      lazy { init_promo_rem_ra() }

  public val promo_tel_04: StringResource by 
      lazy { init_promo_tel_04() }

  public val promo_tel_05: StringResource by 
      lazy { init_promo_tel_05() }

  public val promo_tel_09: StringResource by 
      lazy { init_promo_tel_09() }

  public val search_related_products: StringResource by 
      lazy { init_search_related_products() }
}

internal val Res.string.audit_pending_message: StringResource
  get() = CommonMainString0.audit_pending_message

private fun init_audit_pending_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:audit_pending_message", "audit_pending_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    10, 137),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    10, 121),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    10, 149),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    10, 129),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 10,
    129),
    )
)

internal val Res.string.audit_pending_title: StringResource
  get() = CommonMainString0.audit_pending_title

private fun init_audit_pending_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:audit_pending_title", "audit_pending_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    148, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    132, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    160, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    140, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 140,
    47),
    )
)

internal val Res.string.audit_required: StringResource
  get() = CommonMainString0.audit_required

private fun init_audit_required(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:audit_required", "audit_required",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    204, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    184, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    212, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    188, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 188,
    42),
    )
)

internal val Res.string.audit_update_state: StringResource
  get() = CommonMainString0.audit_update_state

private fun init_audit_update_state(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:audit_update_state", "audit_update_state",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    255, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    239, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    255, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    231, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 231,
    46),
    )
)

internal val Res.string.camera_message1: StringResource
  get() = CommonMainString0.camera_message1

private fun init_camera_message1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:camera_message1", "camera_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    306, 207),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    290, 175),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    314, 207),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    278, 195),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 278,
    195),
    )
)

internal val Res.string.camera_next: StringResource
  get() = CommonMainString0.camera_next

private fun init_camera_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:camera_next", "camera_next",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    514, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    466, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    522, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    474, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 474,
    35),
    )
)

internal val Res.string.camera_permission_android_title1: StringResource
  get() = CommonMainString0.camera_permission_android_title1

private fun init_camera_permission_android_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_android_title1", "camera_permission_android_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    554, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    510, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    570, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    510, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 510,
    52),
    )
)

internal val Res.string.camera_permission_chrome_message1: StringResource
  get() = CommonMainString0.camera_permission_chrome_message1

private fun init_camera_permission_chrome_message1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_chrome_message1", "camera_permission_chrome_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    607, 697),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    563, 681),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    623, 725),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    563, 625),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 563,
    625),
    )
)

internal val Res.string.camera_permission_chrome_title1: StringResource
  get() = CommonMainString0.camera_permission_chrome_title1

private fun init_camera_permission_chrome_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_chrome_title1", "camera_permission_chrome_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1305, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    1245, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    1349, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    1189, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 1189,
    59),
    )
)

internal val Res.string.camera_permission_denied_continue: StringResource
  get() = CommonMainString0.camera_permission_denied_continue

private fun init_camera_permission_denied_continue(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_denied_continue", "camera_permission_denied_continue",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1365, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    1305, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    1409, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    1249, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 1249,
    57),
    )
)

internal val Res.string.camera_permission_edge_title1: StringResource
  get() = CommonMainString0.camera_permission_edge_title1

private fun init_camera_permission_edge_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_edge_title1", "camera_permission_edge_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1427, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    1367, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    1471, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    1307, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 1307,
    57),
    )
)

internal val Res.string.camera_permission_firefox_title1: StringResource
  get() = CommonMainString0.camera_permission_firefox_title1

private fun init_camera_permission_firefox_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_firefox_title1", "camera_permission_firefox_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1485, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    1425, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    1529, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    1365, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 1365,
    52),
    )
)

internal val Res.string.camera_permission_ios_title1: StringResource
  get() = CommonMainString0.camera_permission_ios_title1

private fun init_camera_permission_ios_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_ios_title1", "camera_permission_ios_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1538, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    1478, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    1582, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    1418, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 1418,
    40),
    )
)

internal val Res.string.camera_permission_message1: StringResource
  get() = CommonMainString0.camera_permission_message1

private fun init_camera_permission_message1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_message1", "camera_permission_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1579, 182),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    1519, 138),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    1623, 186),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    1459, 126),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 1459,
    126),
    )
)

internal val Res.string.camera_permission_ms_ie_title1: StringResource
  get() = CommonMainString0.camera_permission_ms_ie_title1

private fun init_camera_permission_ms_ie_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_ms_ie_title1", "camera_permission_ms_ie_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1762, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    1658, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    1810, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    1586, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 1586,
    54),
    )
)

internal val Res.string.camera_permission_next: StringResource
  get() = CommonMainString0.camera_permission_next

private fun init_camera_permission_next(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_next", "camera_permission_next",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1817, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    1713, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    1865, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    1641, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 1641,
    46),
    )
)

internal val Res.string.camera_permission_opera_message1: StringResource
  get() = CommonMainString0.camera_permission_opera_message1

private fun init_camera_permission_opera_message1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_opera_message1", "camera_permission_opera_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1864, 388),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    1764, 416),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    1916, 400),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    1688, 340),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 1688,
    340),
    )
)

internal val Res.string.camera_permission_opera_title1: StringResource
  get() = CommonMainString0.camera_permission_opera_title1

private fun init_camera_permission_opera_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_opera_title1", "camera_permission_opera_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2253, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    2181, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    2317, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    2029, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 2029,
    46),
    )
)

internal val Res.string.camera_permission_safari_message1: StringResource
  get() = CommonMainString0.camera_permission_safari_message1

private fun init_camera_permission_safari_message1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_safari_message1", "camera_permission_safari_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2300, 285),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    2228, 273),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    2364, 285),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    2076, 257),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 2076,
    257),
    )
)

internal val Res.string.camera_permission_safari_title1: StringResource
  get() = CommonMainString0.camera_permission_safari_title1

private fun init_camera_permission_safari_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_safari_title1", "camera_permission_safari_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2586, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    2502, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    2650, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    2334, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 2334,
    47),
    )
)

internal val Res.string.camera_permission_sheet_message1: StringResource
  get() = CommonMainString0.camera_permission_sheet_message1

private fun init_camera_permission_sheet_message1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_sheet_message1", "camera_permission_sheet_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2634, 376),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    2550, 288),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    2698, 336),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    2382, 272),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 2382,
    272),
    )
)

internal val Res.string.camera_permission_sheet_title1: StringResource
  get() = CommonMainString0.camera_permission_sheet_title1

private fun init_camera_permission_sheet_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_sheet_title1", "camera_permission_sheet_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3011, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    2839, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3035, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    2655, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 2655,
    70),
    )
)

internal val Res.string.camera_permission_title1: StringResource
  get() = CommonMainString0.camera_permission_title1

private fun init_camera_permission_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_title1", "camera_permission_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3086, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    2910, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3118, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    2726, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 2726,
    52),
    )
)

internal val Res.string.camera_permission_unknown_message1: StringResource
  get() = CommonMainString0.camera_permission_unknown_message1

private fun init_camera_permission_unknown_message1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_unknown_message1", "camera_permission_unknown_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3139, 266),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    2971, 230),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3183, 258),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    2779, 222),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 2779,
    222),
    )
)

internal val Res.string.camera_permission_unknown_title1: StringResource
  get() = CommonMainString0.camera_permission_unknown_title1

private fun init_camera_permission_unknown_title1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:camera_permission_unknown_title1", "camera_permission_unknown_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3406, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3202, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3442, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3002, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3002,
    60),
    )
)

internal val Res.string.camera_title1: StringResource
  get() = CommonMainString0.camera_title1

private fun init_camera_title1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:camera_title1", "camera_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3475, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3271, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3511, 69),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3063, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3063,
    57),
    )
)

internal val Res.string.guide_message1: StringResource
  get() = CommonMainString0.guide_message1

private fun init_guide_message1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guide_message1", "guide_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3541, 102),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3329, 78),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3581, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3121, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3121,
    86),
    )
)

internal val Res.string.guide_message2: StringResource
  get() = CommonMainString0.guide_message2

private fun init_guide_message2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guide_message2", "guide_message2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3644, 86),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3408, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3676, 86),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3208, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3208,
    86),
    )
)

internal val Res.string.guide_message3: StringResource
  get() = CommonMainString0.guide_message3

private fun init_guide_message3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guide_message3", "guide_message3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3731, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3491, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3763, 86),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3295, 82),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3295,
    82),
    )
)

internal val Res.string.guide_next: StringResource
  get() = CommonMainString0.guide_next

private fun init_guide_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guide_next", "guide_next",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3826, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3586, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3850, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3378, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3378,
    30),
    )
)

internal val Res.string.guide_title1: StringResource
  get() = CommonMainString0.guide_title1

private fun init_guide_title1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guide_title1", "guide_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3857, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3617, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3881, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3409, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3409,
    44),
    )
)

internal val Res.string.guide_title2: StringResource
  get() = CommonMainString0.guide_title2

private fun init_guide_title2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guide_title2", "guide_title2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3906, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3674, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    3938, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3454, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3454,
    44),
    )
)

internal val Res.string.guide_title3: StringResource
  get() = CommonMainString0.guide_title3

private fun init_guide_title3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guide_title3", "guide_title3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3967, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3715, 72),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4003, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3499, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3499,
    68),
    )
)

internal val Res.string.history_articles: StringResource
  get() = CommonMainString0.history_articles

private fun init_history_articles(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:history_articles", "history_articles",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4028, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3788, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4072, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3568, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3568,
    44),
    )
)

internal val Res.string.history_empty_list: StringResource
  get() = CommonMainString0.history_empty_list

private fun init_history_empty_list(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:history_empty_list", "history_empty_list",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4073, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3833, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4117, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3613, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3613,
    66),
    )
)

internal val Res.string.history_use_list: StringResource
  get() = CommonMainString0.history_use_list

private fun init_history_use_list(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:history_use_list", "history_use_list",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4136, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3900, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4188, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3704, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3680,
    36),
    )
)

internal val Res.string.home_aisle: StringResource
  get() = CommonMainString0.home_aisle

private fun init_home_aisle(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:home_aisle", "home_aisle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4222, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3990, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4294, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3790, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3762,
    34),
    )
)

internal val Res.string.home_aisle_order: StringResource
  get() = CommonMainString0.home_aisle_order

private fun init_home_aisle_order(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_aisle_order", "home_aisle_order",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4177, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    3945, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4245, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3741, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3717,
    44),
    )
)

internal val Res.string.home_cell_accumulate_x: StringResource
  get() = CommonMainString0.home_cell_accumulate_x

private fun init_home_cell_accumulate_x(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_cell_accumulate_x", "home_cell_accumulate_x",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4257, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4029, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4329, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3825, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3797,
    58),
    )
)

internal val Res.string.home_cell_members_only: StringResource
  get() = CommonMainString0.home_cell_members_only

private fun init_home_cell_members_only(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_cell_members_only", "home_cell_members_only",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4308, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4084, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4380, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3884, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3856,
    46),
    )
)

internal val Res.string.home_cell_save: StringResource
  get() = CommonMainString0.home_cell_save

private fun init_home_cell_save(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:home_cell_save", "home_cell_save",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4396, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4188, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4480, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3976, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3948,
    34),
    )
)

internal val Res.string.home_cell_save_x: StringResource
  get() = CommonMainString0.home_cell_save_x

private fun init_home_cell_save_x(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_cell_save_x", "home_cell_save_x",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4355, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4135, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4435, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    3931, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3903,
    44),
    )
)

internal val Res.string.home_comparing_with: StringResource
  get() = CommonMainString0.home_comparing_with

private fun init_home_comparing_with(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_comparing_with", "home_comparing_with",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4431, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4235, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4515, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4011, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 3983,
    55),
    )
)

internal val Res.string.home_items: StringResource
  get() = CommonMainString0.home_items

private fun init_home_items(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:home_items", "home_items",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4487, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4295, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4579, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4071, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4039,
    30),
    )
)

internal val Res.string.home_menu_basket: StringResource
  get() = CommonMainString0.home_menu_basket

private fun init_home_menu_basket(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_menu_basket", "home_menu_basket",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4522, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4330, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4614, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4102, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4070,
    32),
    )
)

internal val Res.string.home_menu_brochures: StringResource
  get() = CommonMainString0.home_menu_brochures

private fun init_home_menu_brochures(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_menu_brochures", "home_menu_brochures",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4555, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4363, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4651, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4135, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4103,
    39),
    )
)

internal val Res.string.home_menu_historic: StringResource
  get() = CommonMainString0.home_menu_historic

private fun init_home_menu_historic(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_menu_historic", "home_menu_historic",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4595, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4407, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4691, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4175, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4143,
    38),
    )
)

internal val Res.string.home_menu_power_by_1: StringResource
  get() = CommonMainString0.home_menu_power_by_1

private fun init_home_menu_power_by_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_menu_power_by_1", "home_menu_power_by_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4638, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4450, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4734, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4214, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4182,
    44),
    )
)

internal val Res.string.home_menu_power_by_2: StringResource
  get() = CommonMainString0.home_menu_power_by_2

private fun init_home_menu_power_by_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_menu_power_by_2", "home_menu_power_by_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4683, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4491, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4779, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4259, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4227,
    36),
    )
)

internal val Res.string.home_menu_search: StringResource
  get() = CommonMainString0.home_menu_search

private fun init_home_menu_search(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_menu_search", "home_menu_search",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4720, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4528, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4820, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4296, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4264,
    32),
    )
)

internal val Res.string.home_menu_turns: StringResource
  get() = CommonMainString0.home_menu_turns

private fun init_home_menu_turns(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:home_menu_turns", "home_menu_turns",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4753, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4561, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4853, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4329, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4297,
    31),
    )
)

internal val Res.string.home_pay: StringResource
  get() = CommonMainString0.home_pay

private fun init_home_pay(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:home_pay", "home_pay",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4961, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4765, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5065, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4537, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4505,
    20),
    )
)

internal val Res.string.home_payment_message: StringResource
  get() = CommonMainString0.home_payment_message

private fun init_home_payment_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_payment_message", "home_payment_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4785, 92),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4593, 88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4885, 92),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4361, 96),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4329,
    96),
    )
)

internal val Res.string.home_payment_ok: StringResource
  get() = CommonMainString0.home_payment_ok

private fun init_home_payment_ok(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:home_payment_ok", "home_payment_ok",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4878, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4682, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    4978, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4458, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4426,
    27),
    )
)

internal val Res.string.home_payment_title: StringResource
  get() = CommonMainString0.home_payment_title

private fun init_home_payment_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_payment_title", "home_payment_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4910, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4718, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5010, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4486, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4454,
    50),
    )
)

internal val Res.string.home_scan_loyalty: StringResource
  get() = CommonMainString0.home_scan_loyalty

private fun init_home_scan_loyalty(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_scan_loyalty", "home_scan_loyalty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4986, 69),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4794, 73),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5090, 69),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4558, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4526,
    57),
    )
)

internal val Res.string.home_subtotal: StringResource
  get() = CommonMainString0.home_subtotal

private fun init_home_subtotal(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:home_subtotal", "home_subtotal",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5056, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4868, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5160, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4628, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4584,
    33),
    )
)

internal val Res.string.home_tap_the_button: StringResource
  get() = CommonMainString0.home_tap_the_button

private fun init_home_tap_the_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_tap_the_button", "home_tap_the_button",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5090, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4906, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5194, 87),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4662, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4618,
    71),
    )
)

internal val Res.string.home_unknown_product_message: StringResource
  get() = CommonMainString0.home_unknown_product_message

private fun init_home_unknown_product_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_unknown_product_message", "home_unknown_product_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5170, 156),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    4986, 152),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5282, 172),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4734, 144),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4690,
    144),
    )
)

internal val Res.string.home_unknown_product_title: StringResource
  get() = CommonMainString0.home_unknown_product_title

private fun init_home_unknown_product_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:home_unknown_product_title", "home_unknown_product_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5327, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5139, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5455, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4879, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4835,
    54),
    )
)

internal val Res.string.intro_message: StringResource
  get() = CommonMainString0.intro_message

private fun init_intro_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:intro_message", "intro_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5390, 157),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5198, 185),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5518, 161),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    4934, 129),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 4890,
    129),
    )
)

internal val Res.string.intro_next: StringResource
  get() = CommonMainString0.intro_next

private fun init_intro_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:intro_next", "intro_next",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5548, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5384, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5680, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5064, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5020,
    26),
    )
)

internal val Res.string.intro_welcome: StringResource
  get() = CommonMainString0.intro_welcome

private fun init_intro_welcome(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:intro_welcome", "intro_welcome",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5579, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5411, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5707, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5091, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5047,
    45),
    )
)

internal val Res.string.location_message1: StringResource
  get() = CommonMainString0.location_message1

private fun init_location_message1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:location_message1", "location_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5625, 233),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5461, 245),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5753, 213),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5137, 193),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5093,
    193),
    )
)

internal val Res.string.location_next: StringResource
  get() = CommonMainString0.location_next

private fun init_location_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:location_next", "location_next",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5859, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5707, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    5967, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5331, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5287,
    37),
    )
)

internal val Res.string.location_title1: StringResource
  get() = CommonMainString0.location_title1

private fun init_location_title1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:location_title1", "location_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5897, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5749, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6009, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5369, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5325,
    51),
    )
)

internal val Res.string.loyalty_anonymous: StringResource
  get() = CommonMainString0.loyalty_anonymous

private fun init_loyalty_anonymous(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:loyalty_anonymous", "loyalty_anonymous",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5949, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5805, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6065, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5421, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5377,
    49),
    )
)

internal val Res.string.loyalty_identify: StringResource
  get() = CommonMainString0.loyalty_identify

private fun init_loyalty_identify(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:loyalty_identify", "loyalty_identify",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6003, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5855, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6119, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5471, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5427,
    40),
    )
)

internal val Res.string.loyalty_message1: StringResource
  get() = CommonMainString0.loyalty_message1

private fun init_loyalty_message1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:loyalty_message1", "loyalty_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6052, 124),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    5908, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6168, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5512, 96),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5468,
    96),
    )
)

internal val Res.string.loyalty_title1: StringResource
  get() = CommonMainString0.loyalty_title1

private fun init_loyalty_title1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:loyalty_title1", "loyalty_title1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6177, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6017, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6277, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5609, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5565,
    34),
    )
)

internal val Res.string.payment_selection_assistance_message: StringResource
  get() = CommonMainString0.payment_selection_assistance_message

private fun init_payment_selection_assistance_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:payment_selection_assistance_message", "payment_selection_assistance_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6220, 104),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6056, 96),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6308, 100),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5644, 100),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5600,
    100),
    )
)

internal val Res.string.payment_selection_assistance_title: StringResource
  get() = CommonMainString0.payment_selection_assistance_title

private fun init_payment_selection_assistance_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:payment_selection_assistance_title", "payment_selection_assistance_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6325, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6153, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6409, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5745, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5701,
    62),
    )
)

internal val Res.string.payment_selection_audit_message: StringResource
  get() = CommonMainString0.payment_selection_audit_message

private fun init_payment_selection_audit_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:payment_selection_audit_message", "payment_selection_audit_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6396, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6220, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6476, 95),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5808, 83),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5764,
    83),
    )
)

internal val Res.string.payment_selection_audit_title: StringResource
  get() = CommonMainString0.payment_selection_audit_title

private fun init_payment_selection_audit_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:payment_selection_audit_title", "payment_selection_audit_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6476, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6304, 69),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6572, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5892, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5848,
    57),
    )
)

internal val Res.string.payment_selection_message1: StringResource
  get() = CommonMainString0.payment_selection_message1

private fun init_payment_selection_message1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:payment_selection_message1", "payment_selection_message1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6542, 162),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6374, 138),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6630, 154),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    5950, 158),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 5906,
    158),
    )
)

internal val Res.string.payment_selection_update_status_text: StringResource
  get() = CommonMainString0.payment_selection_update_status_text

private fun init_payment_selection_update_status_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:payment_selection_update_status_text", "payment_selection_update_status_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6705, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6513, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6785, 76),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6109, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6065,
    64),
    )
)

internal val Res.string.promo_product_in_promo: StringResource
  get() = CommonMainString0.promo_product_in_promo

private fun init_promo_product_in_promo(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:promo_product_in_promo", "promo_product_in_promo",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6774, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6582, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6862, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6174, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6130,
    58),
    )
)

internal val Res.string.promo_rem_01: StringResource
  get() = CommonMainString0.promo_rem_01

private fun init_promo_rem_01(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:promo_rem_01", "promo_rem_01",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6896, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6704, 80),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6988, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6296, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6252,
    68),
    )
)

internal val Res.string.promo_rem_01_short: StringResource
  get() = CommonMainString0.promo_rem_01_short

private fun init_promo_rem_01_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:promo_rem_01_short", "promo_rem_01_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6837, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6641, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    6925, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6233, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6189,
    62),
    )
)

internal val Res.string.promo_rem_10: StringResource
  get() = CommonMainString0.promo_rem_10

private fun init_promo_rem_10(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:promo_rem_10", "promo_rem_10",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7016, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6848, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    7116, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6428, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6384,
    64),
    )
)

internal val Res.string.promo_rem_10_short: StringResource
  get() = CommonMainString0.promo_rem_10_short

private fun init_promo_rem_10_short(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:promo_rem_10_short", "promo_rem_10_short",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6957, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6785, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    7053, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6365, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6321,
    62),
    )
)

internal val Res.string.promo_rem_20: StringResource
  get() = CommonMainString0.promo_rem_20

private fun init_promo_rem_20(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:promo_rem_20", "promo_rem_20",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7077, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6913, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    7181, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6493, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6449,
    32),
    )
)

internal val Res.string.promo_rem_ra: StringResource
  get() = CommonMainString0.promo_rem_ra

private fun init_promo_rem_ra(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:promo_rem_ra", "promo_rem_ra",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7110, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6946, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    7214, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6526, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6482,
    40),
    )
)

internal val Res.string.promo_tel_04: StringResource
  get() = CommonMainString0.promo_tel_04

private fun init_promo_tel_04(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:promo_tel_04", "promo_tel_04",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7155, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    6995, 72),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    7263, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6567, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6523,
    68),
    )
)

internal val Res.string.promo_tel_05: StringResource
  get() = CommonMainString0.promo_tel_05

private fun init_promo_tel_05(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:promo_tel_05", "promo_tel_05",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7212, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    7068, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    7332, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6636, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6592,
    64),
    )
)

internal val Res.string.promo_tel_09: StringResource
  get() = CommonMainString0.promo_tel_09

private fun init_promo_tel_09(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:promo_tel_09", "promo_tel_09",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7269, 76),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    7137, 92),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    7393, 88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6701, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6657,
    92),
    )
)

internal val Res.string.search_related_products: StringResource
  get() = CommonMainString0.search_related_products

private fun init_search_related_products(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:search_related_products", "search_related_products",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7346, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("eu"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-eu/strings.commonMain.cvr",
    7230, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("fr"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-fr/strings.commonMain.cvr",
    7482, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("it"),
    ), "composeResources/aiwscan.composeapp.generated.resources/values-it/strings.commonMain.cvr",
    6794, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/aiwscan.composeapp.generated.resources/values/strings.commonMain.cvr", 6750,
    55),
    )
)
