package com.mshopsas.enterprise.aiw.scan.data

import io.github.xxfast.kstore.KStore

class PersistenceService(
    private val kstore: KStore<Persistence>
) {

    suspend fun get(): Persistence {
        return kstore.get()!!
    }

    suspend fun set(reducer: Persistence.() -> Persistence) {
        kstore.update { p -> p?.reducer() }
    }

}
