package com.mshopsas.enterprise.aiw.scan.screens.payment.ticket

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.mshopsas.enterprise.aiw.scan.screens.commons.MScanScaffold
import com.mshopsas.enterprise.aiw.scan.screens.commons.RatingBar
import com.mshopsas.enterprise.aiw.scan.screens.payment.ticket.composables.TicketItemView
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import io.github.alexzhirkevich.qrose.options.QrBallShape
import io.github.alexzhirkevich.qrose.options.QrFrameShape
import io.github.alexzhirkevich.qrose.options.QrPixelShape
import io.github.alexzhirkevich.qrose.options.circle
import io.github.alexzhirkevich.qrose.options.roundCorners
import io.github.alexzhirkevich.qrose.rememberQrCodePainter

@Composable
fun TicketView(
    state: TicketContract.State,
    onEventSent: (TicketContract.Event) -> Unit
) {
    MScanScaffold {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(it)
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .background(RedDark)
                    .padding(6.dp),
                contentAlignment = Alignment.TopCenter
            ) {
                Text(
                    modifier = Modifier
                        .padding(12.dp)
                        .fillMaxWidth()
                        .wrapContentHeight(align = Alignment.CenterVertically),
                    text = "Código pago en caja",
                    fontSize = 24.sp,
                    color = Color.White,
                    textAlign = TextAlign.Center
                )
            }
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .verticalScroll(rememberScrollState())
            ) {
                Image(
                    painter = rememberQrCodePainter(state.qrData) {
                        shapes {
                            ball = QrBallShape.circle()
                            darkPixel = QrPixelShape.roundCorners() //Every single dot
                            frame = QrFrameShape.roundCorners(.25f)
                        }
                    },
                    contentDescription = "",
                    modifier = Modifier.fillMaxWidth().padding(24.dp).aspectRatio(1f),
                    contentScale = ContentScale.Fit
                )

                RatingBar()

                state.items.forEach { item ->
                    TicketItemView(ticketItem = item)
                    HorizontalDivider()
                }
//                LazyColumn(modifier = Modifier.fillMaxSize()) {
//                    itemsIndexed(state.items) { index, ticketItem ->
//                        TicketItemView(ticketItem = ticketItem)
//                        if (index < state.items.lastIndex)
//                            HorizontalDivider()
//                    }
//                }
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(start = 6.dp, top = 6.dp, end = 6.dp, bottom = 12.dp),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    Text(
                        text = "Total",
                        modifier = Modifier.weight(0.7f),
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Left
                    )
                    Text(
                        text = state.totalItems,
                        modifier = Modifier.weight(0.1f),
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Right
                    )
                    Text(
                        text = state.totalAmount,
                        modifier = Modifier.weight(0.2f),
                        fontSize = 14.sp,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Right
                    )
                }
            }
        }
    }
}
