package com.mshopsas.enterprise.aiw.scan.screens.search.composables

import aiwscan.composeapp.generated.resources.Res
import aiwscan.composeapp.generated.resources.home_cell_members_only
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import com.mshopsas.enterprise.aiw.scan.data.basket.PromoInfo
import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductDetail
import com.mshopsas.enterprise.aiw.scan.theme.RedDark
import com.mshopsas.enterprise.aiw.scan.theme.YellowDark
import org.jetbrains.compose.resources.stringResource

@Composable
fun ProductSearchCellView(
    product: ProductDetail,
    isAnonymous: Boolean,
    onItemClick: (ProductDetail) -> Unit,
    modifier: Modifier = Modifier
) {

    if (product.promoInfo != null) {
        CellPromo(product, isAnonymous, onItemClick, product.promoInfo, modifier)
    } else {
        CellNormal(product, onItemClick, modifier)
    }
}

@Composable
fun CellNormal(product: ProductDetail, onItemClick: (ProductDetail) -> Unit, modifier: Modifier) {
    Card(
        shape = RoundedCornerShape(4.dp),
        modifier = modifier.padding(horizontal = 16.dp, vertical = 8.dp),
        colors = CardDefaults.cardColors(
            containerColor = Color.White,
        ),
        elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
    ) {
        ProductSearchItem(product, onItemClick)
    }
}

@Composable
fun CellPromo(
    product: ProductDetail,
    isAnonymous: Boolean,
    onItemClick: (ProductDetail) -> Unit,
    promoInfo: PromoInfo,
    modifier: Modifier
) {

    val type = promoInfo.type
    val sub = promoInfo.codeAvg

    // REM default
    var borderColor = YellowDark
    var textColor = Color.Black
    var onlyMember = promoInfo.onlyLoyalty && isAnonymous

    if (type == "TEL") {
        borderColor = RedDark
        textColor = Color.White
        onlyMember = isAnonymous
    }

    var price: Int? = null
    var crossPrice = false

    val title = ""
//    val title = when (type) {
//        "TEL" -> stringResource(Res.string.home_cell_accumulate_x, cell.groupAmount.toMoney())
//
//        "REM" -> {
//            if (cell.groupAmount > 0) {
//                crossPrice = true
//                price = cell.groupPrice
//            }
//            when (sub) {
//                "01", "10",
//                "RA", "RP" -> if (crossPrice) {
//                    stringResource(Res.string.home_cell_save_x, cell.groupAmount.toMoney())
//                } else {
//                    stringResource(Res.string.home_cell_save)
//                }
//
//                "18", "20" -> "${cell.amountDesc}"
//                else -> "$type $sub"
//            }
//        }
//
//        else -> "$type $sub"
//    }

    Card(
        shape = RoundedCornerShape(4.dp),
        modifier = modifier.padding(horizontal = 16.dp, vertical = 8.dp),
        colors = CardDefaults.cardColors(
            containerColor = Color.White,
        ),
        elevation = CardDefaults.cardElevation(defaultElevation = 2.dp),
        border = BorderStroke(2.dp, borderColor)
    ) {

        Row(modifier = Modifier.fillMaxWidth().background(borderColor)) {
//            if (price != null) {
//                Spacer(modifier = Modifier.width(8.dp))
//
//                Text(text = price.toMoney(), color = textColor, fontWeight = FontWeight.Bold)
//            }
//
//            Spacer(modifier = Modifier.width(8.dp))

            Text(text = title, color = textColor, fontWeight = FontWeight.Bold)

            if (onlyMember) {
                Spacer(modifier = Modifier.weight(1f))
                Text(text = stringResource(Res.string.home_cell_members_only), color = textColor, fontWeight = FontWeight.Bold)
                Spacer(modifier = Modifier.width(8.dp))
            }
        }

        ProductSearchItem(product, onItemClick)
    }

}
