package com.mshopsas.enterprise.aiw.scan.data.basket

import com.mshopsas.enterprise.aiw.scan.data.basket.details.ProductInfo
import kotlinx.serialization.Serializable

@Serializable
data class ShoppingList(
    val id: Int,
    val idShopper: Int,
    val idEncoded: String,
    val modificationDate: String,
    val storeLabel: String,
    val nbItems: Int,
    val itemList: List<ProductInfo>
) {
    fun toCompareList() = CompareList(
        name = "$storeLabel ($modificationDate)",
        date = modificationDate,
        totalItems = itemList.count(),
        items = itemList.map { item -> item.toCompareItem() }
    )
}